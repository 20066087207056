import {
  Box,
  chakra,
  Container,
  Link,
  Stack,
  Text,
  useColorModeValue,
  VisuallyHidden,
} from '@chakra-ui/react'
import { FaFacebook, FaInstagram, FaTwitter } from 'react-icons/fa'
import React, { ReactNode, useEffect, useState } from 'react'

const SocialButton = ({
  children,
  label,
  href,
}: {
  children: ReactNode,
  label: string,
  href: string,
}) => {
  return (
    <chakra.button
      bg={useColorModeValue('blackAlpha.100', 'whiteAlpha.100')}
      rounded={'full'}
      w={8}
      h={8}
      cursor={'pointer'}
      as={'a'}
      href={href}
      target={'_blank'}
      display={'inline-flex'}
      alignItems={'center'}
      justifyContent={'center'}
      transition={'background 0.3s ease'}
      _hover={{
        bg: useColorModeValue('blackAlpha.200', 'whiteAlpha.200'),
      }}
    >
      <VisuallyHidden>{label}</VisuallyHidden>
      {children}
    </chakra.button>
  )
}

export default function Footer() {
  const [links, setLinks] = useState({ privacy: '', terms: '' })
  useEffect(() => {
    const privacy = process.env.REACT_APP_PRIVACY_LINK
    const terms = process.env.REACT_APP_TERMS_LINK
    const fb = process.env.REACT_APP_FB_LINK
    const ig = process.env.REACT_APP_IG_LINK
    const tw = process.env.REACT_APP_TWITER_LINK
    setLinks({
      privacy,
      terms,
      fb,
      ig,
      tw,
    })
  }, [])
  return (
    <Box
      bg={useColorModeValue('gray.50', 'gray.900')}
      color={useColorModeValue('gray.700', 'gray.200')}
      id="footer"
    >
      <Container
        as={Stack}
        maxW={'6xl'}
        py={4}
        direction={{ base: 'column', md: 'row' }}
        spacing={4}
        justify={{ base: 'center', md: 'space-between' }}
        align={{ base: 'center', md: 'center' }}
      >
        <Text>© 2022 My Career Story. All rights reserved</Text>{' '}
        <Link href={links.privacy} isExternal>
          Privacy
        </Link>
        <Link href={links.terms} isExternal>
          Terms and Conditions
        </Link>
        <Stack direction={'row'} spacing={6}>
          <SocialButton label={'Twitter'} href={links.tw}>
            <FaTwitter />
          </SocialButton>
          <SocialButton label={'Facebook'} href={links.fb}>
            <FaFacebook />
          </SocialButton>
          <SocialButton label={'Instagram'} href={links.ig}>
            <FaInstagram />
          </SocialButton>
        </Stack>
      </Container>
    </Box>
  )
}
