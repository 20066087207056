import { Box } from '@chakra-ui/react'

const Container = ({ children, ...props }) => {
  const containerWidths = [
    '100%',
    '640px',
    '768px',
    '1024px',
    '1280px',
    // "1536px",
  ]

  return (
    <Box maxWidth={[...containerWidths]} mx="auto" px="6" {...props}>
      {children}
    </Box>
  )
}

export default Container
