import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Redirect, Prompt } from 'react-router'
import { Link as ReactRouterLink } from 'react-router-dom'
import {
  Grid,
  GridItem,
  Box,
  Button,
  Flex,
  Text,
  Heading,
  FormLabel,
  Input,
  FormControl,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  Modal,
  ModalOverlay,
  ModalContent,
  AspectRatio,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  TabPanel,
  Tag,
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  Icon,
  Stack,
  Skeleton,
  useToken,
  ButtonGroup,
  Select,
  useBreakpointValue,
} from '@chakra-ui/react'
import ContextsDetails from '../components/careerMoments/ContextsDetails'
import ResultsDetails from '../components/careerMoments/ResultsDetails'
import ActionsDetails from '../components/careerMoments/ActionsDetails'
import LearningsDetails from '../components/careerMoments/LearningsDetails'
import Container from '../components/common/Container'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchCareerMoments,
  createCareerMoment,
  updateCareerMoment,
} from '../api/careerMoments'
import { formatDate, isFreeUser, monthNames, years } from '../lib/utils'
import { cmStatusType } from '../lib/types'
import { fetchMomentAttributes } from '../api/admin/momentAttributes'
import { useHistory, useParams } from 'react-router'

import { UilBars } from '@iconscout/react-unicons'
import { useForm, FormProvider, Controller } from 'react-hook-form'
import DefaultLayout from '../layouts/default'
import { orderBy, groupBy } from 'lodash'

const CareerMoments = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { careerMomentId } = useParams()
  const [newMomentId, setNewMomentId] = useState(null)
  const user = useSelector((state) => state.user.data)
  const viewingUser = useSelector((state) => state.user.data)
  const careerMoments = useSelector((state) =>
    state.careerMoments.data ? Object.values(state.careerMoments.data) : []
  )
  const careerMoment = useSelector((state) =>
    careerMomentId
      ? state.careerMoments.data && state.careerMoments.data[careerMomentId]
      : null
  )
  const [loadingCareerMoments, setLoadingCareerMoments] = useState(false)
  const [loadingMomentAttributes, setLoadingMomentAttributes] = useState(false)
  const [loadingSave, setLoadingSave] = useState(false)
  const [unsavedField, setUnsavedField] = useState(false)
  const [tabIndex, setTabIndex] = useState(0)

  const isLargerThanLg = useBreakpointValue({
    base: false,
    lg: true,
  })

  const defaultValues = useMemo(() => {
    return {
      title: (careerMoment && careerMoment.title) || '',
      role: (careerMoment && careerMoment.role) || '',
      company: (careerMoment && careerMoment.company) || '',
      industry: (careerMoment && careerMoment.industry) || '',
      month: (careerMoment && careerMoment.month) || '',
      year: (careerMoment && careerMoment.year) || '',
      challenges:
        careerMoment &&
        careerMoment.challenges &&
        careerMoment.challenges.length
          ? careerMoment.challenges
          : [
              {
                name: '',
                type: null,
                statement: '',
              },
            ],
      actions:
        careerMoment && careerMoment.actions && careerMoment.actions.length
          ? careerMoment.actions
          : [
              {
                name: '',
                type: null,
                statement: '',
              },
            ],
      results:
        careerMoment && careerMoment.results && careerMoment.results.length
          ? careerMoment.results
          : [
              {
                name: null,
                type: null,
                statement: '',
              },
            ],
      learnings:
        careerMoment && careerMoment.learnings && careerMoment.learnings.length
          ? careerMoment.learnings
          : [
              {
                name: '',
                type: '',
                statement: '',
              },
            ],
    }
  }, [careerMoment])

  const { reset, ...useFormMethods } = useForm({ ...defaultValues })

  const getCareerMoments = useCallback(async () => {
    try {
      setLoadingCareerMoments(true)
      await dispatch(fetchCareerMoments({ userId: viewingUser.id }))
      setLoadingCareerMoments(false)
    } catch (error) {
      setLoadingCareerMoments(false)
      alert(error)
    }
  }, [dispatch, viewingUser])

  const getMomentAttributes = useCallback(async () => {
    try {
      setLoadingMomentAttributes(true)
      await dispatch(fetchMomentAttributes())
      setLoadingMomentAttributes(false)
    } catch (error) {
      setLoadingMomentAttributes(false)
      alert(error)
    }
  }, [dispatch])

  useEffect(() => {
    getCareerMoments()
  }, [getCareerMoments])

  useEffect(() => {
    getMomentAttributes()
  }, [getMomentAttributes])

  useEffect(() => {
    reset({ ...defaultValues })
  }, [careerMoment, defaultValues, reset])

  const newHandler = () => {
    history.push('/career-moments')
  }

  const createHandler = async (form, status) => {
    await dispatch(
      createCareerMoment({
        userId: user.id,
        title: form.title.trim(),
        company: form.company.trim(),
        role: form.role.trim(),
        industry: form.industry,
        year: form.year,
        month: form.month,
        actions: form.actions,
        results: form.results,
        learnings: form.learnings,
        challenges: form.challenges,
        status,
      })
    ).then((results) => setNewMomentId(results.careerMoment.id))
  }

  const updateHandler = async (form, status) => {
    await dispatch(
      updateCareerMoment(careerMoment.id, {
        ...careerMoment,
        title: form.title.trim(),
        company: form.company.trim(),
        role: form.role.trim(),
        industry: form.industry,
        year: form.year,
        month: form.month,
        actions: form.actions,
        results: form.results,
        learnings: form.learnings,
        challenges: form.challenges,
        status,
      })
    )
  }

  const saveHandler = async (status) => {
    try {
      setLoadingSave(status)
      careerMoment
        ? await useFormMethods.handleSubmit(updateHandler)(status)
        : await useFormMethods.handleSubmit(createHandler)(status)
      setLoadingSave(null)
      alert('Moment Saved Successfully')
    } catch (error) {
      setLoadingSave(null)
      alert(error)
    }
  }

  const isUnsaved = () => {
    return unsavedField
  }
  return (
    <DefaultLayout>
      <Prompt
        when={isUnsaved()}
        message={() =>
          'This moment contains unsaved changes. Click Cancel to return to the moment to save or click OK to leave without saving.'
        }
      />
      <FormProvider {...useFormMethods}>
        <Box
          d={{ lg: 'none' }}
          borderBottomWidth="1px"
          position="sticky"
          top="0"
          bg="amberGray.100"
          py="2"
          zIndex="1"
        >
          <Container>
            <Flex>
              <Button
                colorScheme="orange"
                variant="link"
                leftIcon={<Icon boxSize="5" as={UilBars} />}
                size="sm"
                onClick={onOpen}
              >
                View All Moments
              </Button>
              <Box ml="auto">
                <SaveButtonGroup
                  size="sm"
                  saveHandler={saveHandler}
                  loadingSave={loadingSave}
                  viewingUser={viewingUser}
                  setUnsavedField={setUnsavedField}
                />
                {newMomentId && (
                  <Redirect push to={'/career-moments/' + newMomentId} />
                )}
              </Box>
            </Flex>
          </Container>
        </Box>
        <Container py="12">
          <Grid templateColumns={{ lg: 'repeat(12, 1fr)' }} gap="6">
            <GridItem d={{ base: 'none', lg: 'block' }} colSpan={{ lg: '3' }}>
              <Box>
                <Box mb="8">
                  <Heading mb="2" fontSize="3xl">
                    Career Moments
                  </Heading>
                  <Button
                    colorScheme="orange"
                    variant="ghost"
                    onClick={newHandler}
                  >
                    + Create a New Moment
                  </Button>
                </Box>
                <Box>
                  {loadingCareerMoments ? (
                    <StackLoader />
                  ) : (
                    <MomentsNavAccordion moments={careerMoments} />
                  )}
                </Box>
                <Box display={{ base: 'block', lg: 'none' }}>
                  <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
                    <DrawerOverlay />
                    <DrawerContent>
                      <DrawerCloseButton />
                      <DrawerHeader p="4">Career Moments</DrawerHeader>
                      <DrawerBody pt="0" px="2">
                        <Box mb="4" px="4" pb="4" borderBottomWidth="1px">
                          <Button
                            colorScheme="orange"
                            variant="link"
                            onClick={newHandler}
                          >
                            + Create a New Moment
                          </Button>
                        </Box>
                        {loadingCareerMoments ? (
                          <Stack>
                            <Skeleton height="20px" />
                            <Skeleton height="20px" />
                            <Skeleton height="20px" />
                          </Stack>
                        ) : (
                          <MomentsNavAccordion moments={careerMoments} />
                        )}
                      </DrawerBody>
                    </DrawerContent>
                  </Drawer>
                </Box>
              </Box>
            </GridItem>
            <GridItem colSpan={{ lg: '9' }}>
              <Box shadow="base" p="6" mb="6" bg="white" rounded="md">
                <Box d={{ base: 'none', lg: 'block' }}>
                  <Flex ml="auto">
                    <SaveButtonGroup
                      saveHandler={saveHandler}
                      loadingSave={loadingSave}
                      viewingUser={viewingUser}
                      newMomentId={newMomentId}
                      careerMoment={careerMoment}
                      setUnsavedField={setUnsavedField}
                    />
                  </Flex>
                </Box>
                <Box
                  borderBottomWidth={{ lg: '1px' }}
                  pb="12"
                  mb={{ lg: '12' }}
                >
                  <Box mb="8">
                    <Flex>
                      <Box flexGrow="1">
                        <Box>
                          <FormControl id="momentTitle">
                            <FormLabel>Moment Title</FormLabel>
                            <Input
                              type="text"
                              autoComplete="off"
                              {...useFormMethods.register('title')}
                              onChange={(e) =>
                                e.target.value
                                  ? setUnsavedField(true)
                                  : setUnsavedField(false)
                              }
                            />
                          </FormControl>
                        </Box>
                        {careerMoment && careerMoment.updatedAt && (
                          <Box>
                            <Text
                              fontSize="sm"
                              color="warmGray.600"
                              fontWeight="medium"
                            >
                              Last Saved:{' '}
                              {formatDate(
                                careerMoment.updatedAt,
                                'MMM. DD, h:mma'
                              )}
                            </Text>
                          </Box>
                        )}
                      </Box>
                    </Flex>
                  </Box>
                  <Box>
                    <Grid templateColumns={{ lg: 'repeat(12, 1fr)' }} gap="6">
                      <GridItem colSpan="4">
                        <Box>
                          <FormControl id="momentCompany">
                            <FormLabel>Company/Organization</FormLabel>
                            <Input
                              type="text"
                              autoComplete="off"
                              {...useFormMethods.register('company')}
                              onChange={(e) =>
                                e.target.value
                                  ? setUnsavedField(true)
                                  : setUnsavedField(false)
                              }
                            />
                          </FormControl>
                        </Box>
                      </GridItem>
                      <GridItem colSpan="4">
                        <Box>
                          <FormControl id="momentRole">
                            <FormLabel>Role</FormLabel>
                            <Input
                              type="text"
                              autoComplete="off"
                              {...useFormMethods.register('role')}
                              onChange={(e) =>
                                e.target.value
                                  ? setUnsavedField(true)
                                  : setUnsavedField(false)
                              }
                            />
                          </FormControl>
                        </Box>
                      </GridItem>
                      <GridItem colStart="1" colSpan="4">
                        <Box>
                          <FormControl id="momentIndustry">
                            <FormLabel>Industry</FormLabel>
                            <Input
                              type="text"
                              autoComplete="off"
                              {...useFormMethods.register('industry')}
                              onChange={(e) =>
                                e.target.value
                                  ? setUnsavedField(true)
                                  : setUnsavedField(false)
                              }
                            />
                          </FormControl>
                        </Box>
                      </GridItem>
                      <GridItem colStart="1" colSpan="4">
                        <Box>
                          <FormControl id="momentMonth">
                            <FormLabel>Month</FormLabel>
                            <Controller
                              control={useFormMethods.control}
                              name="month"
                              defaultValue={careerMoment && careerMoment.month}
                              render={({ field }) => (
                                <>
                                  <Select
                                    onChange={(e) =>
                                      e.target.value
                                        ? setUnsavedField(true)
                                        : setUnsavedField(false)
                                    }
                                    {...field}
                                    placeholder="Select Month"
                                  >
                                    <option value="1">January</option>
                                    <option value="2">February</option>
                                    <option value="3">March</option>
                                    <option value="4">April</option>
                                    <option value="5">May</option>
                                    <option value="6">June</option>
                                    <option value="7">July</option>
                                    <option value="8">August</option>
                                    <option value="9">September</option>
                                    <option value="10">October</option>
                                    <option value="11">November</option>
                                    <option value="12">December</option>
                                  </Select>
                                </>
                              )}
                            />
                          </FormControl>
                        </Box>
                      </GridItem>
                      <GridItem colSpan="4">
                        <Box>
                          <FormControl id="momentYear">
                            <FormLabel>Year</FormLabel>
                            <Controller
                              control={useFormMethods.control}
                              name="year"
                              defaultValue={careerMoment && careerMoment.month}
                              render={({ field }) => (
                                <>
                                  <Select
                                    onChange={(e) =>
                                      e.target.value
                                        ? setUnsavedField(true)
                                        : setUnsavedField(false)
                                    }
                                    {...field}
                                    placeholder="Select Year"
                                  >
                                    {years.map((year) => (
                                      <option value={`${year}`}>{year}</option>
                                    ))}
                                  </Select>
                                </>
                              )}
                            />
                          </FormControl>
                        </Box>
                      </GridItem>
                    </Grid>
                  </Box>
                </Box>
                <Box>
                  {isLargerThanLg ? (
                    <CreateMomentTabs
                      loadingMomentAttributes={loadingMomentAttributes}
                      tabIndex={tabIndex}
                      setTabIndex={setTabIndex}
                      saveHandler={saveHandler}
                      loadingSave={loadingSave}
                      viewingUser={viewingUser}
                      setUnsavedField={setUnsavedField}
                    />
                  ) : (
                    <CreateMomentAccordion
                      loadingMomentAttributes={loadingMomentAttributes}
                      tabIndex={tabIndex}
                      setTabIndex={setTabIndex}
                      saveHandler={saveHandler}
                      loadingSave={loadingSave}
                      viewingUser={viewingUser}
                      setUnsavedField={setUnsavedField}
                    />
                  )}
                </Box>
              </Box>
            </GridItem>
          </Grid>
        </Container>
      </FormProvider>
    </DefaultLayout>
  )
}

const SaveButtonGroup = ({
  saveHandler,
  loadingSave,
  size,
  viewingUser,
  newMomentId,
  careerMoment,
  setUnsavedField,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { careerMomentId } = useParams()

  return (
    <ButtonGroup size={size} spacing="3" ml="auto">
      <Button
        onClick={() => {
          setUnsavedField(false)
          saveHandler(cmStatusType.PUBLISHED, newMomentId)
        }}
        colorScheme="orange"
        loadingText="Saving..."
        isDisabled={loadingSave && loadingSave !== cmStatusType.PUBLISHED}
        isLoading={loadingSave === cmStatusType.PUBLISHED}
      >
        Save
      </Button>
      {!isFreeUser(viewingUser) && (
        <>
          <Button
            variant="link"
            colorScheme="orange"
            onClick={onOpen}
            disabled={!careerMomentId}
          >
            Request A Career Story
          </Button>
          <Modal isOpen={isOpen} onClose={onClose} size="xl">
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Request a Career Story</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <AspectRatio ratio={1}>
                  <iframe
                    title="request_career_story "
                    src={
                      'https://share.hsforms.com/19w75SLfCT0CpNHnKcgyUJQc15wu' +
                      `?email=${viewingUser.email}` +
                      `&subject= ${careerMoment ? careerMoment.title : null}` +
                      `&momentid= ${careerMomentId}`
                    }
                    allowFullScreen
                  />
                </AspectRatio>
              </ModalBody>
            </ModalContent>
          </Modal>
        </>
      )}
    </ButtonGroup>
  )
}

const StackLoader = () => {
  const skeletonStyle = {
    startColor: 'amberGray.300',
    endColor: 'amberGray.700',
    height: '20px',
  }

  return (
    <Stack spacing="8">
      {[...Array(3)].map((el, i) => (
        <Stack key={i}>
          <Skeleton {...skeletonStyle} w="75%" />
          <Skeleton {...skeletonStyle} w="50%" />
          <Skeleton {...skeletonStyle} w="25%" />
        </Stack>
      ))}
    </Stack>
  )
}

const MomentsNavAccordion = ({ moments }) => {
  const { careerMomentId } = useParams()

  const editedMoments = useMemo(() => {
    return moments.map((m) => ({
      ...m,
      company: m.company.toLowerCase(),
      role: m.role.toLowerCase(),
    }))
  }, [moments])

  const momentsByYear = useMemo(() => {
    let sorted = orderBy(editedMoments, ['year', 'month'], ['desc', 'desc'])
    let groups = groupBy(sorted, 'year')
    return groups
  }, [editedMoments])

  const defaultIndex = useMemo(() => {
    return Object.values(momentsByYear).findIndex((moments) => {
      return moments.some((m) => m.id === parseInt(careerMomentId))
    })
  }, [careerMomentId, momentsByYear])

  return (
    <Accordion defaultIndex={[defaultIndex]} allowMultiple>
      {Object.entries(momentsByYear)
        .reverse()
        .map(([company, roles], index) => (
          <AccordionItem
            key={index}
            borderWidth="0"
            _notFirst={{ pt: '2' }}
            _last={{ borderWidth: '0' }}
          >
            {({ isExpanded }) => (
              <Box
                borderLeftWidth="2px"
                borderLeftColor={isExpanded ? 'orange.600' : 'amberGray.300'}
                ml="-1"
                pl="1"
              >
                <AccordionButton
                  py="2"
                  px="3"
                  rounded="md"
                  alignItems="center"
                  _hover={{ bg: 'amberGray.200' }}
                >
                  <Text
                    fontWeight="medium"
                    textAlign="left"
                    textTransform="capitalize"
                    fontSize="lg"
                  >
                    {company}
                  </Text>
                  <AccordionIcon ml="3" />
                </AccordionButton>
                <AccordionPanel ml="1" p="1">
                  <InnerAccordion values={roles} />
                </AccordionPanel>
              </Box>
            )}
          </AccordionItem>
        ))}
    </Accordion>
  )
}

const InnerAccordion = ({ values }) => {
  const { careerMomentId } = useParams()
  const momentsByMonth = useMemo(() => {
    return groupBy(values, 'month')
  }, [values])

  const defaultIndex = useMemo(() => {
    return Object.values(momentsByMonth).findIndex((moments) => {
      return moments.some((m) => m.id === parseInt(careerMomentId))
    })
  }, [careerMomentId, momentsByMonth])

  const MomentsButtons = ({ moments }) => (
    <Stack spacing="1">
      {moments.map((moment, index) => (
        <Button
          as={ReactRouterLink}
          key={index}
          py="2"
          px="3"
          fontWeight="medium"
          justifyContent="start"
          color={
            parseInt(careerMomentId) === moment.id
              ? 'orange.600'
              : 'warmGray.600'
          }
          variant="ghost"
          _hover={{ bg: 'amberGray.200' }}
          to={`/career-moments/${moment.id}`}
          isFullWidth
        >
          {moment.title}
        </Button>
      ))}
    </Stack>
  )

  return (
    <Accordion defaultIndex={[defaultIndex]} allowMultiple>
      {Object.entries(momentsByMonth)
        .reverse()
        .map(([month, moments], index) => {
          if (month === 'null') return <MomentsButtons moments={moments} />
          return (
            <AccordionItem
              key={index}
              borderWidth="0"
              _last={{ borderWidth: '0' }}
            >
              <AccordionButton
                py="2"
                px="3"
                rounded="md"
                _hover={{ bg: 'amberGray.200' }}
              >
                <Text
                  fontWeight="medium"
                  textAlign="left"
                  textTransform="capitalize"
                  color="warmGray.600"
                >
                  {monthNames[month - 1]}
                </Text>
                <AccordionIcon ml="3" />
              </AccordionButton>
              <AccordionPanel ml="1" p="1">
                <MomentsButtons moments={moments} />
              </AccordionPanel>
            </AccordionItem>
          )
        })}
    </Accordion>
  )
}

const CreateMomentAccordion = ({
  loadingMomentAttributes,
  tabIndex,
  setTabIndex,
  saveHandler,
  loadingSave,
  viewingUser,
  setUnsavedField,
}) => {
  const { careerMomentId } = useParams()
  const careerMoment = useSelector((state) =>
    careerMomentId
      ? state.careerMoments.data && state.careerMoments.data[careerMomentId]
      : null
  )

  const handleSetAttributeCount = useCallback(
    () => ({
      challenges:
        careerMoment && careerMoment.challenges
          ? careerMoment.challenges.length || 1
          : 1,
      actions:
        careerMoment && careerMoment.actions
          ? careerMoment.actions.length || 1
          : 1,
      results:
        careerMoment && careerMoment.results
          ? careerMoment.results.length || 1
          : 1,
      learnings:
        careerMoment && careerMoment.learnings
          ? careerMoment.learnings.length || 1
          : 1,
    }),
    [careerMoment]
  )

  const [attributeCount, setAttributeCount] = useState(handleSetAttributeCount)

  useEffect(() => {
    setAttributeCount(handleSetAttributeCount)
  }, [careerMoment, handleSetAttributeCount])

  const handleUpdateCount = (key, diff) => {
    setAttributeCount((prev) => {
      return {
        ...attributeCount,
        [key]: prev[key] + diff,
      }
    })
  }

  return (
    <Accordion index={tabIndex} defaultIndex={[0]} allowMultiple allowToggle>
      <AccordionItem>
        <AccordionButton
          fontWeight="semibold"
          _expanded={{
            color: 'orange.600',
          }}
          onPointerDown={() =>
            tabIndex === 0 ? setTabIndex(null) : setTabIndex(0)
          }
        >
          <Flex align="center" grow="1">
            <Text fontSize="lg">Challenge</Text>
            {attributeCount.challenges > 1 && (
              <Tag
                ml="3"
                size="sm"
                borderRadius="full"
                colorScheme="orange"
                variant="subtle"
              >
                {attributeCount.challenges}
              </Tag>
            )}
          </Flex>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel px="0">
          <ContextsDetails
            onAppendOrRemove={(diff) => handleUpdateCount('challenges', diff)}
            loadingMomentAttributes={loadingMomentAttributes}
            setTabIndex={setTabIndex}
            tabIndex={tabIndex}
            setUnsavedField={setUnsavedField}
          />
        </AccordionPanel>
      </AccordionItem>
      <AccordionItem>
        <AccordionButton
          fontWeight="semibold"
          _expanded={{
            color: 'orange.600',
          }}
          onPointerDown={() =>
            tabIndex === 1 ? setTabIndex(null) : setTabIndex(1)
          }
        >
          <Flex align="center" grow="1">
            <Text fontSize="lg">Actions</Text>
            {attributeCount.actions > 1 && (
              <Tag
                d="flex"
                alignItems="center"
                ml="3"
                size="sm"
                borderRadius="full"
                colorScheme="orange"
                variant="subtle"
              >
                {attributeCount.actions}
              </Tag>
            )}
          </Flex>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel px="0">
          <ActionsDetails
            onAppendOrRemove={(diff) => handleUpdateCount('actions', diff)}
            loadingMomentAttributes={loadingMomentAttributes}
            setTabIndex={setTabIndex}
            tabIndex={tabIndex}
            setUnsavedField={setUnsavedField}
          />
        </AccordionPanel>
      </AccordionItem>
      <AccordionItem>
        <AccordionButton
          fontWeight="semibold"
          _expanded={{
            color: 'orange.600',
          }}
          onPointerDown={() =>
            tabIndex === 2 ? setTabIndex(null) : setTabIndex(2)
          }
        >
          <Flex align="center" grow="1">
            <Text fontSize="lg">Results</Text>
            {attributeCount.results > 1 && (
              <Tag
                d="flex"
                alignItems="center"
                ml="3"
                size="sm"
                borderRadius="full"
                colorScheme="orange"
                variant="subtle"
              >
                {attributeCount.results}
              </Tag>
            )}
          </Flex>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel px="0" isExpanded={tabIndex === 2 ? true : false}>
          <ResultsDetails
            onAppendOrRemove={(diff) => handleUpdateCount('results', diff)}
            loadingMomentAttributes={loadingMomentAttributes}
            setTabIndex={setTabIndex}
            tabIndex={tabIndex}
            setUnsavedField={setUnsavedField}
          />
        </AccordionPanel>
      </AccordionItem>
      <AccordionItem isExpanded>
        <AccordionButton
          fontWeight="semibold"
          _expanded={{
            color: 'orange.600',
          }}
          onPointerDown={() =>
            tabIndex === 3 ? setTabIndex(null) : setTabIndex(3)
          }
        >
          <Flex align="center" grow="1">
            <Text fontSize="lg">Learnings</Text>
            {attributeCount.learnings > 1 && (
              <Tag
                d="flex"
                alignItems="center"
                ml="3"
                size="sm"
                borderRadius="full"
                colorScheme="orange"
                variant="subtle"
              >
                {attributeCount.learnings}
              </Tag>
            )}
          </Flex>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel px="0" isExpanded>
          <LearningsDetails
            onAppendOrRemove={(diff) => handleUpdateCount('learnings', diff)}
            tabIndex={tabIndex}
            setTabIndex={setTabIndex}
            saveHandler={saveHandler}
            loadingSave={loadingSave}
            viewingUser={viewingUser}
            setUnsavedField={setUnsavedField}
          />
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  )
}

const CreateMomentTabs = ({
  loadingMomentAttributes,
  tabIndex,
  setTabIndex,
  saveHandler,
  loadingSave,
  viewingUser,
  setUnsavedField,
}) => {
  const [orange600] = useToken('colors', ['orange.600'])
  const { careerMomentId } = useParams()

  const careerMoment = useSelector((state) =>
    careerMomentId
      ? state.careerMoments.data && state.careerMoments.data[careerMomentId]
      : null
  )

  const handleSetAttributeCount = useCallback(
    () => ({
      challenges:
        careerMoment && careerMoment.challenges
          ? careerMoment.challenges.length || 1
          : 1,
      actions:
        careerMoment && careerMoment.actions
          ? careerMoment.actions.length || 1
          : 1,
      results:
        careerMoment && careerMoment.results
          ? careerMoment.results.length || 1
          : 1,
      learnings:
        careerMoment && careerMoment.learnings
          ? careerMoment.learnings.length || 1
          : 1,
    }),
    [careerMoment]
  )

  const [attributeCount, setAttributeCount] = useState(handleSetAttributeCount)

  useEffect(() => {
    setAttributeCount(handleSetAttributeCount)
  }, [careerMoment, handleSetAttributeCount])

  const handleUpdateCount = (key, diff) => {
    setAttributeCount((prev) => {
      return {
        ...attributeCount,
        [key]: prev[key] + diff,
      }
    })
  }
  const handleTabsChange = (index) => {
    setTabIndex(index)
  }

  const tabs = [
    {
      title: 'Challenge',
      count: attributeCount.challenges,
    },
    {
      title: 'Actions',
      count: attributeCount.actions,
    },
    {
      title: 'Results',
      count: attributeCount.results,
    },
    {
      title: 'Learnings',
      count: attributeCount.learnings,
    },
  ]

  return (
    <Tabs
      colorScheme="orange"
      isFitted
      index={tabIndex}
      onChange={handleTabsChange}
    >
      <TabList borderBottomWidth="1px">
        {tabs.map((attribute, idx) => (
          <Tab
            key={idx}
            fontWeight="semibold"
            color="amberGray.700"
            _selected={{
              color: 'black',
              boxShadow: `inset 0 -3px ${orange600}`,
            }}
          >
            {attribute.title}
            {attribute.count > 1 && (
              <Tag
                d="flex"
                alignItems="center"
                ml="3"
                size="sm"
                borderRadius="full"
                colorScheme="orange"
                variant="subtle"
              >
                {attribute.count}
              </Tag>
            )}
          </Tab>
        ))}
      </TabList>
      <TabPanels>
        <TabPanel>
          <ContextsDetails
            onAppendOrRemove={(diff) => handleUpdateCount('challenges', diff)}
            loadingMomentAttributes={loadingMomentAttributes}
            setTabIndex={setTabIndex}
            tabIndex={tabIndex}
            setUnsavedField={setUnsavedField}
          />
        </TabPanel>
        <TabPanel>
          <ActionsDetails
            onAppendOrRemove={(diff) => handleUpdateCount('actions', diff)}
            loadingMomentAttributes={loadingMomentAttributes}
            setTabIndex={setTabIndex}
            tabIndex={tabIndex}
            setUnsavedField={setUnsavedField}
          />
        </TabPanel>
        <TabPanel>
          <ResultsDetails
            onAppendOrRemove={(diff) => handleUpdateCount('results', diff)}
            loadingMomentAttributes={loadingMomentAttributes}
            setTabIndex={setTabIndex}
            tabIndex={tabIndex}
            setUnsavedField={setUnsavedField}
          />
        </TabPanel>
        <TabPanel>
          <LearningsDetails
            onAppendOrRemove={(diff) => handleUpdateCount('learnings', diff)}
            setTabIndex={setTabIndex}
            tabIndex={tabIndex}
            saveHandler={saveHandler}
            loadingSave={loadingSave}
            viewingUser={viewingUser}
            setUnsavedField={setUnsavedField}
          />
        </TabPanel>
      </TabPanels>
    </Tabs>
  )
}

export default CareerMoments
