// Actions
const UPDATE = 'registration/UPDATE'
const REMOVE = 'registration/REMOVE'

// Reducer
const initialState = {
  data: null,
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case UPDATE: {
      return {
        ...state,
        data: { ...state.data, ...action.registration },
      }
    }
    case REMOVE:
      return initialState
    default:
      return state
  }
}

// Action Creators
export function putRegistration(registration) {
  return { type: UPDATE, registration }
}

export function removeRegistration() {
  return { type: REMOVE }
}
