import jwtDecode from 'jwt-decode'
import dayjs from 'dayjs'
import * as Sentry from '@sentry/react'
import { useLocation } from 'react-router-dom'
import { subscriptionType } from './types'

const setupRequest = (path, method, state) => {
  var token = null
  if (state) {
    const { accessToken } = state.token
    if (!accessToken) throw Error('NO_TOKEN')
    token = accessToken
  }
  let url = buildUrl(path)
  const options = { method, headers: {} }
  addHeader(options.headers, token)
  return { url, options }
}

const buildUrl = (path) => {
  var baseUrl = process.env.REACT_APP_BASE_URL
  return baseUrl + path
}

const addHeader = (headers = {}, token = null) => {
  headers['Content-Type'] = 'application/json'
  if (token) headers['Authorization'] = `Bearer ${token}`
  return headers
}

const validateEmail = (email) => {
  const regex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return regex.test(String(email).toLowerCase())
}

const decodeToken = (token) => {
  const id = jwtDecode(token).id
  return id
}

const formatDate = (val, format = 'MM/DD/YYYY') => {
  return dayjs(val).format(format)
}

const sortFormatDate = (date) => (date ? dayjs(date).unix() : '')

// Used for dev purposes only
const delay = (milliseconds) =>
  new Promise((resolve) => setTimeout(resolve, milliseconds))

const countMonths = (date) => {
  const today = dayjs()
  return today.diff(date, 'month')
}

const getURLWithQueryParams = (base, params) => {
  const query = Object.entries(params)
    .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
    .join('&')

  return `${base}?${query}`
}

const trunc = (str, num = 8) => {
  if (str.length <= num) return str
  return str.slice(0, num) + '...'
}

const fetchJSON = (...args) =>
  fetch(...args).then(async (r) => {
    if (r.status === 403) throw Error('FORBIDDEN')
    const contentType = r.headers.get('content-type')
    if (!contentType || contentType.indexOf('application/json') === -1) {
      const text = await r.text()
      throw Error(text)
    }
    return r.json()
  })

const appendParamsToUrl = (params) =>
  Object.keys(params)
    .map(
      (key) => encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
    )
    .join('&')

const errorHandler = (error) => {
  Sentry.captureException(error)
}

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

function validURL(str) {
  var pattern = new RegExp(
    '^(https?:\\/\\/)?' +
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
      '((\\d{1,3}\\.){3}\\d{1,3}))' +
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
      '(\\?[;&a-z\\d%_.~+=-]*)?' +
      '(\\#[-a-z\\d_]*)?$',
    'i'
  )
  return !!pattern.test(str)
}

function formatBytes(bytes) {
  var i = Math.floor(Math.log(bytes) / Math.log(1024))
  return (
    (bytes / Math.pow(1024, i)).toFixed(2) * 1 +
    ' ' +
    ['B', 'kB', 'MB', 'GB', 'TB'][i]
  )
}

const formatPrice = (cents, hideCents = true) => {
  const value = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(cents / 100)

  // Hide cents if .00
  return hideCents ? value.replace(/\D00(?=\D*$)/, '') : value
}

const isFreeUser = (user) => {
  return (
    user.subscriptionTier === subscriptionType.FREE.value ||
    (user.subscriptionTier !== subscriptionType.FREE.value &&
      (!user.paidUntil || dayjs().isAfter(user.paidUntil)) &&
      (!user.trialUntil || dayjs().isAfter(user.trialUntil)))
  )
}

export const years = Array.from(
  { length: 131 },
  (_, i) => `${new Date().getFullYear() + i * -1}`
)

export const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

export {
  decodeToken,
  validateEmail,
  setupRequest,
  addHeader,
  formatDate,
  delay,
  countMonths,
  getURLWithQueryParams,
  trunc,
  fetchJSON,
  appendParamsToUrl,
  errorHandler,
  useQuery,
  validURL,
  formatBytes,
  formatPrice,
  sortFormatDate,
  buildUrl,
  isFreeUser,
}
