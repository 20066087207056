import React, { useCallback, useState, useEffect, useRef } from 'react'
import {
  Box,
  Grid,
  GridItem,
  Heading,
  Tag,
  Text,
  Stack,
  AccordionButton,
  Accordion,
  AccordionPanel,
  AccordionItem,
  AccordionIcon,
  Button,
  Collapse,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Textarea,
  useDisclosure,
  Icon,
  Select,
  StackDivider,
  ButtonGroup,
  Center,
} from '@chakra-ui/react'
import {
  UilCommentAltLines,
  UilEditAlt,
  UilEye,
  UilEyeSlash,
} from '@iconscout/react-unicons'
import { Redirect, useParams, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { fetchCareerMoments, fetchCareerMoment } from '../../api/careerMoments'
import {
  createCareerStory,
  fetchCareerStories,
  updateCareerStory,
} from '../../api/careerStories'
import { createComment } from '../../api/comments'
import {
  commentObjectType,
  csStatusType,
  settingsType,
  userType,
} from '../../lib/types'

import CommentThread from '../common/CommentThread'
import StoryStatusIndicator from './StoryStatusIndicator'
import { fetchMomentAttributes } from '../../api/admin/momentAttributes'

const StoryEditor = () => {
  const { userId, careerMomentId } = useParams()
  const dispatch = useDispatch()
  const { hash } = useLocation()

  const viewingUser = useSelector((state) => state.user.data)
  const isUser = viewingUser.type === userType.USER
  const [loadingMomentAttributes, setLoadingMomentAttributes] = useState(false)

  const careerMoment = useSelector(
    (state) =>
      state.careerMoments.data &&
      (state.careerMoments.data[careerMomentId] ||
        Object.values(state.careerMoments.data).find(
          (cm) => cm.userId === Number(userId)
        ))
  )

  const getMomentAttributes = useCallback(async () => {
    try {
      setLoadingMomentAttributes(true)
      await dispatch(fetchMomentAttributes())
      setLoadingMomentAttributes(false)
    } catch (error) {
      setLoadingMomentAttributes(false)
      alert(error)
    }
  }, [dispatch])

  const careerStories = useSelector((state) =>
    isUser
      ? state.careerStories.data &&
        Object.values(state.careerStories.data)
          .filter((cs) => cs.careerMomentId === Number(careerMomentId))
          .filter((s) => s.status !== 'draft')
      : state.careerStories.data &&
        Object.values(state.careerStories.data).filter(
          (cs) => cs.careerMomentId === Number(careerMomentId)
        )
  )

  const [loadingCareerMoment, setLoadingCareerMoment] = useState(true)
  const [loadingCareerStories, setLoadingCareerStories] = useState(true)
  const [careerStory, setCareerStory] = useState(null)

  const getCareerMoments = useCallback(async () => {
    try {
      setLoadingCareerMoment(true)
      await dispatch(fetchCareerMoments({ userId }))
      setLoadingCareerMoment(false)
    } catch (error) {
      setLoadingCareerMoment(false)
      alert(error)
    }
  }, [dispatch, userId])

  const getCareerMoment = useCallback(async () => {
    try {
      setLoadingCareerMoment(true)
      await dispatch(fetchCareerMoment(careerMomentId))
      setLoadingCareerMoment(false)
    } catch (error) {
      setLoadingCareerMoment(false)
      alert(error)
    }
  }, [dispatch, careerMomentId])

  const getCareerStories = useCallback(async () => {
    try {
      setLoadingCareerStories(true)
      await dispatch(fetchCareerStories({ careerMomentId }))
      setLoadingCareerStories(false)
    } catch (error) {
      setLoadingCareerStories(false)
      alert(error)
    }
  }, [dispatch, careerMomentId])

  useEffect(() => {
    !careerMomentId && getCareerMoments()
  }, [careerMomentId, getCareerMoments])

  useEffect(() => {
    careerMomentId && getCareerMoment()
  }, [careerMomentId, getCareerMoment])

  useEffect(() => {
    getCareerStories()
  }, [careerMomentId, getCareerStories])

  useEffect(() => {
    setLoadingCareerStories(true)
  }, [careerMomentId])

  useEffect(() => {
    getMomentAttributes()
  }, [getMomentAttributes])

  const addCareerStoryHandler = () => {
    setCareerStory({})
  }

  if (careerMoment && !careerMomentId)
    return <Redirect to={`/career-stories/${userId}/${careerMoment.id}`} />

  return (
    <Box boxShadow="base" bg="white" rounded="md" overflow="hidden">
      <Grid templateColumns={{ lg: 'repeat(12, 1fr)' }} gap="6">
        {!loadingCareerMoment && (
          <GridItem
            colSpan={{ lg: '5' }}
            borderRightWidth={{ lg: '1px' }}
            borderBottomWidth={{ base: '1px', lg: '0' }}
            px="6"
            pt="6"
            pb={{ base: '0', lg: '0' }}
          >
            <Box mb="4">
              <Heading fontSize="2xl" mb="1">
                {careerMoment.title}
              </Heading>
              <Text fontWeight="medium" color="warmGray.500">
                {careerMoment.role} • {careerMoment.company} •{' '}
                {careerMoment.year}
              </Text>
            </Box>
            <Box>
              <Accordion defaultIndex={[0]} allowMultiple>
                <AccordionItem borderColor="white" mb="4">
                  {/* Context */}
                  <MomentChallenges challenges={careerMoment.challenges} />
                </AccordionItem>
                <AccordionItem borderColor="white" mb="4">
                  {/* Action */}
                  <MomentActions actions={careerMoment.actions} />
                </AccordionItem>
                <AccordionItem borderColor="white" mb="4">
                  {/* Results */}
                  <MomentResults results={careerMoment.results} />
                </AccordionItem>
                <AccordionItem borderColor="white" mb="4">
                  {/* Learning */}
                  <MomentLearnings learnings={careerMoment.learnings} />
                </AccordionItem>
              </Accordion>
            </Box>
          </GridItem>
        )}
        {!loadingCareerStories && (
          <GridItem
            colSpan={{ lg: '7' }}
            pl={{ base: '6', lg: '0' }}
            pt={{ base: '0', lg: '6' }}
            pr="6"
            pb="6"
          >
            {!loadingMomentAttributes && (
              <Box px={{ xl: '8' }}>
                <Box mb="4">
                  <Heading fontSize="2xl">Career Story</Heading>
                </Box>
                <Stack spacing="6" align="stretch">
                  {careerStories && careerStories.length ? (
                    careerStories.map((story, idx) => (
                      <Story
                        careerStory={story}
                        key={idx}
                        id={idx}
                        userId={userId}
                        careerMomentId={careerMomentId}
                        setCareerStory={setCareerStory}
                        defaultIsOpen={hash === `#${story.id}` ? true : false}
                      />
                    ))
                  ) : (
                    <Flex
                      w="100%"
                      borderWidth="1px"
                      borderRadius="md"
                      p="4"
                      justify="center"
                      bg="amberGray.100"
                    >
                      <Text fontWeight="semibold">
                        Create this moment's first Career Story
                      </Text>
                    </Flex>
                  )}
                  {careerStory && (
                    <Story
                      careerStory={careerStory}
                      userId={userId}
                      careerMomentId={careerMomentId}
                      setCareerStory={setCareerStory}
                      defaultIsOpen={true}
                    />
                  )}
                </Stack>
                {!careerStory && !isUser && (
                  <Flex mt="8">
                    <Button
                      ml="auto"
                      colorScheme="orange"
                      onClick={addCareerStoryHandler}
                    >
                      Add Story
                    </Button>
                  </Flex>
                )}
              </Box>
            )}
          </GridItem>
        )}
      </Grid>
    </Box>
  )
}

const Story = ({
  careerStory,
  setCareerStory,
  id,
  defaultIsOpen,
  userId,
  careerMomentId,
}) => {
  const { isOpen, onToggle, onClose } = useDisclosure({ defaultIsOpen })
  const dispatch = useDispatch()

  const [title, setTitle] = useState(careerStory.title || '')
  const [type, setType] = useState(careerStory.type || '')
  const [skill, setSkill] = useState(careerStory.skill || '')
  const [content, setContent] = useState(careerStory.content || '')
  const [text, setText] = useState('')
  const [loadingSend, setLoadingSend] = useState(false)
  const [loadingSave, setLoadingSave] = useState(false)

  const viewingUser = useSelector((state) => state.user.data)

  const [skillAttributes, typeAttributes] = useSelector((state) => [
    (
      state.momentAttributes.data &&
      Object.values(state.momentAttributes.data).filter(
        (a) => a.type === settingsType.STORY.SKILL.value
      )
    )?.sort((a, b) => (a.value > b.value ? 1 : -1)) || [],
    (
      state.momentAttributes.data &&
      Object.values(state.momentAttributes.data).filter(
        (a) => a.type === settingsType.STORY.TYPE.value
      )
    )?.sort((a, b) => (a.value > b.value ? 1 : -1)) || [],
  ])

  const cancelHandler = () => {
    if (!careerStory.id) setCareerStory(null)
    onToggle()
  }

  const saveHandler = async (status) => {
    try {
      setLoadingSave(true)
      careerStory.id ? await updateHandler(status) : await createHandler(status)
      onClose()
      setLoadingSave(false)
      setCareerStory(null)
      if (!careerStory.careerMomentId) {
        alert('Saved. Hubspot ticket not found')
      } else {
        alert('Success')
      }
    } catch (error) {
      setLoadingSave(false)
      alert(error)
    }
  }

  const createHandler = async (status) => {
    await dispatch(
      createCareerStory({
        careerMomentId,
        userId,
        title,
        type,
        skill,
        content,
        status,
      })
    )
  }

  const updateHandler = async (status) => {
    await dispatch(
      updateCareerStory(careerStory.id, {
        ...careerStory,
        careerMomentId,
        userId,
        title,
        type,
        skill,
        content,
        status,
      })
    )
  }

  const submitCommentHandler = async (e) => {
    e.preventDefault()
    try {
      setLoadingSend(true)
      await dispatch(
        createComment({
          content: text,
          type: '',
          objectType: commentObjectType.CAREER_STORY,
          objectId: careerStory.id,
        })
      )
      setLoadingSend(false)
      setText('')
    } catch (error) {
      setLoadingSend(false)
      alert(error)
    }
  }

  const scrollRef = useRef(null)

  useEffect(() => {
    if (scrollRef.current !== null)
      scrollRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'start',
      })
  }, [])

  const isUser = viewingUser.type === userType.USER

  return (
    <Box w="100%" borderWidth="1px" borderRadius="md" overflow="hidden">
      {!isOpen && (
        <Box p="4">
          <Flex align="center" mb="4">
            <Box mr="2">
              <StoryStatusIndicator content={careerStory} />
            </Box>
            <Text
              fontSize="xl"
              fontWeight="medium"
              color={careerStory.title ? 'black' : 'warmGray.400'}
            >
              {careerStory.title || 'Untitled Story'}
            </Text>
          </Flex>
          <Flex>
            <HStack>
              <Tag
                size="sm"
                borderRadius="full"
                colorScheme={careerStory.type ? 'blue' : 'warmGray'}
              >
                {careerStory.type || 'No Type'}
              </Tag>
              <Tag
                size="sm"
                borderRadius="full"
                colorScheme={careerStory.type ? 'orange' : 'warmGray'}
              >
                {careerStory.skill || 'No Skill'}
              </Tag>
            </HStack>
            {careerStory.comments && careerStory.comments.length > 0 && (
              <Tag size="sm" ml="auto" colorScheme="orange" rounded="full">
                <Icon as={UilCommentAltLines} boxSize="4" mr="1" />
                <Text as="span">
                  {careerStory.comments && careerStory.comments.length}
                </Text>
              </Tag>
            )}
          </Flex>
        </Box>
      )}
      <Box>
        <Collapse in={isOpen} animateOpacity>
          <Box p="4">
            <Box mb="4">
              <Flex align="center" mb="2">
                <Box>
                  <StoryStatusIndicator content={careerStory} />
                </Box>
              </Flex>
              <Grid templateColumns={{ lg: 'repeat(2, 1fr)' }} gap="4">
                <GridItem colSpan={{ lg: '2' }}>
                  <FormControl id={`title-${id}`}>
                    <FormLabel>Title</FormLabel>
                    <Input
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                      type="text"
                      disabled={isUser}
                    />
                  </FormControl>
                </GridItem>
                <GridItem colSpan="1">
                  <FormControl id={`type-${id}`}>
                    <FormLabel>Type</FormLabel>
                    <Select
                      disabled={isUser}
                      placeholder="Select option"
                      value={type}
                      onChange={(e) => setType(e.target.value)}
                    >
                      {Object.values(typeAttributes).map((type, index) => (
                        <option key={index} value={type.value}>
                          {type.value}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem colSpan="1">
                  <FormControl id={`skill-${id}`}>
                    <FormLabel>Skill</FormLabel>
                    <Select
                      disabled={isUser}
                      placeholder="Select option"
                      value={skill}
                      onChange={(e) => setSkill(e.target.value)}
                    >
                      {Object.values(skillAttributes).map((skill, index) => (
                        <option key={index} value={skill.value}>
                          {skill.value}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem colSpan={{ lg: '2' }}>
                  <FormControl id={`content-${id}`}>
                    <FormLabel>Story</FormLabel>
                    <Textarea
                      disabled={isUser}
                      value={content}
                      onChange={(e) => setContent(e.target.value)}
                    />
                  </FormControl>
                </GridItem>
              </Grid>
            </Box>
            {careerStory.id && (
              <Box>
                <Flex mb="4" alignItems="center">
                  <Heading ref={scrollRef} fontSize="xl">
                    Comments
                  </Heading>
                  {careerStory.comments && careerStory.comments.length > 0 && (
                    <Tag size="sm" ml="4" colorScheme="orange" rounded="full">
                      <Icon as={UilCommentAltLines} boxSize="4" mr="1" />
                      {careerStory.comments && careerStory.comments.length}
                    </Tag>
                  )}
                </Flex>
                <Box borderBottomWidth="1px" mb="4">
                  {careerStory.comments && careerStory.comments.length > 0 && (
                    <Box mb="2">
                      <CommentThread comments={careerStory.comments} />
                    </Box>
                  )}
                </Box>
                {viewingUser.type === userType.USER &&
                  (!careerStory.comments || !careerStory.comments.length) && (
                    <Box mb="4">
                      <Center>
                        <Text as="i">No Comments</Text>
                      </Center>
                    </Box>
                  )}
                {viewingUser.type !== userType.USER && (
                  <form onSubmit={submitCommentHandler}>
                    <Box>
                      <Box>
                        <FormControl id={`comment-${careerStory.id}`}>
                          <FormLabel>Add a Comment</FormLabel>
                          <Textarea
                            value={text}
                            onChange={(e) => setText(e.target.value)}
                            bg="amberGray.50"
                          />
                        </FormControl>
                      </Box>
                      <Flex>
                        <Button
                          type="submit"
                          ml="auto"
                          colorScheme="orange"
                          variant="ghost"
                          isLoading={loadingSend}
                        >
                          Comment
                        </Button>
                      </Flex>
                    </Box>
                  </form>
                )}
              </Box>
            )}
          </Box>
        </Collapse>

        <Box borderTopWidth="1px" bg="amberGray.50" px="2" py="2">
          {isOpen && !isUser ? (
            <Flex wrap="wrap">
              <Button
                size="sm"
                colorScheme="orange"
                variant="ghost"
                onClick={cancelHandler}
              >
                Cancel
              </Button>
              <ButtonGroup ml="auto">
                <Button
                  size="sm"
                  colorScheme="orange"
                  variant="ghost"
                  isLoading={loadingSave}
                  onClick={() => saveHandler(csStatusType.DRAFT)}
                >
                  Save As Draft
                </Button>
                <Button
                  size="sm"
                  colorScheme="orange"
                  isLoading={loadingSave}
                  onClick={() => saveHandler(csStatusType.CREATED)}
                >
                  Publish
                </Button>
              </ButtonGroup>
            </Flex>
          ) : (
            <Button
              size="sm"
              leftIcon={
                <Icon
                  as={
                    viewingUser.type === userType.USER
                      ? isOpen
                        ? UilEyeSlash
                        : UilEye
                      : UilEditAlt
                  }
                  boxSize="5"
                />
              }
              colorScheme="orange"
              variant="ghost"
              onClick={onToggle}
              isLoading={loadingSave}
            >
              {viewingUser.type === userType.USER
                ? isOpen
                  ? 'Hide'
                  : 'View'
                : 'Edit'}
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  )
}

const MomentChallenges = ({ challenges }) => (
  <>
    <AccordionButton rounded="md" _hover={{ bg: 'amberGray.100' }}>
      <Text fontWeight="semibold" textAlign="left">
        Challenge
      </Text>
      <Tag colorScheme="orange" ml="2" rounded="full" size="sm">
        {challenges.length}
      </Tag>
      <AccordionIcon ml="auto" />
    </AccordionButton>
    <AccordionPanel>
      <Stack
        align="stretch"
        spacing="6"
        divider={<StackDivider borderColor="amberGray.200" />}
      >
        {challenges &&
          challenges.map((challenge, idx) => (
            <Box key={idx}>
              <Grid templateColumns={{ lg: 'repeat(2, 1fr)' }} gap="4">
                <GridItem colSpan="1">
                  <Text
                    as="span"
                    fontWeight="bold"
                    color="warmGray.500"
                    textTransform="uppercase"
                    fontSize="xs"
                    letterSpacing="0.0125rem"
                  >
                    Problem Type
                  </Text>
                  <Text>{challenge.type}</Text>
                </GridItem>
                <GridItem colSpan="1">
                  <Text
                    as="span"
                    fontWeight="bold"
                    color="warmGray.500"
                    textTransform="uppercase"
                    fontSize="xs"
                    letterSpacing="0.0125rem"
                  >
                    Nature of Challenge
                  </Text>
                  <Text>{challenge.name}</Text>
                </GridItem>
                <GridItem colSpan={{ lg: '2' }}>
                  <Text
                    as="span"
                    fontWeight="bold"
                    color="warmGray.500"
                    textTransform="uppercase"
                    fontSize="xs"
                    letterSpacing="0.0125rem"
                  >
                    Challenge Statement
                  </Text>
                  <Text>{challenge.statement}</Text>
                </GridItem>
              </Grid>
            </Box>
          ))}
      </Stack>
    </AccordionPanel>
  </>
)

const MomentActions = ({ actions }) => (
  <>
    <AccordionButton rounded="md" _hover={{ bg: 'amberGray.100' }}>
      <Text fontWeight="semibold" textAlign="left">
        Actions
      </Text>
      <Tag colorScheme="orange" ml="2" rounded="full" size="sm">
        {actions.length}
      </Tag>
      <AccordionIcon ml="auto" />
    </AccordionButton>
    <AccordionPanel>
      <Stack
        align="stretch"
        spacing="6"
        divider={<StackDivider borderColor="amberGray.200" />}
      >
        {actions &&
          actions.map((action, idx) => (
            <Box key={idx}>
              <Grid templateColumns={{ lg: 'repeat(2, 1fr)' }} gap="4">
                <GridItem colSpan="1">
                  <Text
                    as="span"
                    fontWeight="bold"
                    color="warmGray.500"
                    textTransform="uppercase"
                    fontSize="xs"
                    letterSpacing="0.0125rem"
                  >
                    Skill Demonstrated
                  </Text>
                  <Text>{action.name}</Text>
                </GridItem>
                <GridItem colSpan="1">
                  <Text
                    as="span"
                    fontWeight="bold"
                    color="warmGray.500"
                    textTransform="uppercase"
                    fontSize="xs"
                    letterSpacing="0.0125rem"
                  >
                    Action Verbs
                  </Text>
                  <Text>{action.type}</Text>
                </GridItem>
                <GridItem colSpan={{ lg: '2' }}>
                  <Text
                    as="span"
                    fontWeight="bold"
                    color="warmGray.500"
                    textTransform="uppercase"
                    fontSize="xs"
                    letterSpacing="0.0125rem"
                  >
                    Action Statement
                  </Text>
                  <Text>{action.statement}</Text>
                </GridItem>
              </Grid>
            </Box>
          ))}
      </Stack>
    </AccordionPanel>
  </>
)

const MomentResults = ({ results }) => (
  <>
    <AccordionButton rounded="md" _hover={{ bg: 'amberGray.100' }}>
      <Text fontWeight="semibold" textAlign="left">
        Results
      </Text>
      <Tag colorScheme="orange" ml="2" rounded="full" size="sm">
        {results.length}
      </Tag>
      <AccordionIcon ml="auto" />
    </AccordionButton>
    <AccordionPanel>
      <Stack
        align="stretch"
        spacing="6"
        divider={<StackDivider borderColor="amberGray.200" />}
      >
        {results &&
          results.map((result, idx) => (
            <Box key={idx}>
              <Grid templateColumns={{ lg: 'repeat(2, 1fr)' }} gap="4">
                <GridItem colSpan="1">
                  <Text
                    as="span"
                    fontWeight="bold"
                    color="warmGray.500"
                    textTransform="uppercase"
                    fontSize="xs"
                    letterSpacing="0.0125rem"
                  >
                    Accomplishment Verb
                  </Text>
                  <Text>{result.name}</Text>
                </GridItem>
                <GridItem colSpan="1">
                  <Text
                    as="span"
                    fontWeight="bold"
                    color="warmGray.500"
                    textTransform="uppercase"
                    fontSize="xs"
                    letterSpacing="0.0125rem"
                  >
                    Metric Type
                  </Text>
                  <Text>{result.type}</Text>
                </GridItem>
                <GridItem colSpan={{ lg: '2' }}>
                  <Text
                    as="span"
                    fontWeight="bold"
                    color="warmGray.500"
                    textTransform="uppercase"
                    fontSize="xs"
                    letterSpacing="0.0125rem"
                  >
                    Result Statement
                  </Text>
                  <Text>{result.statement}</Text>
                </GridItem>
              </Grid>
            </Box>
          ))}
      </Stack>
    </AccordionPanel>
  </>
)

const MomentLearnings = ({ learnings }) => (
  <>
    <AccordionButton rounded="md" _hover={{ bg: 'amberGray.100' }}>
      <Text fontWeight="semibold" textAlign="left">
        Learnings
      </Text>
      <Tag colorScheme="orange" ml="2" rounded="full" size="sm">
        {learnings.length}
      </Tag>
      <AccordionIcon ml="auto" />
    </AccordionButton>
    <AccordionPanel>
      <Stack
        align="stretch"
        spacing="6"
        divider={<StackDivider borderColor="amberGray.200" />}
      >
        {learnings &&
          learnings.map((learning, idx) => (
            <Box key={idx}>
              <Grid templateColumns={{ lg: 'repeat(2, 1fr)' }} gap="4">
                <GridItem colSpan="1">
                  <Text
                    as="span"
                    fontWeight="bold"
                    color="warmGray.500"
                    textTransform="uppercase"
                    fontSize="xs"
                    letterSpacing="0.0125rem"
                  >
                    Competency
                  </Text>
                  <Text>{learning.name}</Text>
                </GridItem>
                <GridItem colSpan="1">
                  <Text
                    as="span"
                    fontWeight="bold"
                    color="warmGray.500"
                    textTransform="uppercase"
                    fontSize="xs"
                    letterSpacing="0.0125rem"
                  >
                    Competency Type
                  </Text>
                  <Text>{learning.type}</Text>
                </GridItem>
                <GridItem colSpan={{ lg: '2' }}>
                  <Text
                    as="span"
                    fontWeight="bold"
                    color="warmGray.500"
                    textTransform="uppercase"
                    fontSize="xs"
                    letterSpacing="0.0125rem"
                  >
                    Learning Statement
                  </Text>
                  <Text>{learning.statement}</Text>
                </GridItem>
              </Grid>
            </Box>
          ))}
      </Stack>
    </AccordionPanel>
  </>
)

export default StoryEditor
