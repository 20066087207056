import React from 'react'
import {
  Grid,
  GridItem,
  Box,
  Button,
  Flex,
  Text,
  FormLabel,
  FormControl,
  Textarea,
  Select,
  useToken,
  Stack,
  IconButton,
  StackDivider,
  Icon,
} from '@chakra-ui/react'

import CreatableSelect from 'react-select/creatable'
import { useSelector } from 'react-redux'
import { settingsType } from '../../lib/types'
import groupBy from 'lodash/groupBy'
import { UilTrashAlt } from '@iconscout/react-unicons'
import {
  useFieldArray,
  useFormContext,
  Controller,
  useWatch,
} from 'react-hook-form'

const ContextsDetails = ({
  onAppendOrRemove,
  loadingMomentAttributes,
  setTabIndex,
  tabIndex,
  setUnsavedField,
}) => {
  const contextAttributes =
    useSelector(
      (state) =>
        state.momentAttributes.data &&
        Object.values(state.momentAttributes.data).filter(
          (a) => a.type === settingsType.CONTEXTS.value
        )
    ) || []

  const { register, control, setValue } = useFormContext()
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'challenges',
  })

  const handleAppend = () => {
    onAppendOrRemove(1)
    append({ name: '', type: '', statement: '' })
  }

  const handleRemove = (idx) => {
    onAppendOrRemove(-1)
    remove(idx)
  }
  return (
    <Grid templateColumns={{ lg: 'repeat(12, 1fr)' }} gap="6">
      <GridItem order={{ lg: '2' }} colSpan={{ lg: '4' }}>
        <Box
          bg="amberGray.75"
          p="4"
          rounded="md"
          fontSize="sm"
          position="sticky"
          top="6"
        >
          <Text fontWeight="semibold">Pro Tip:</Text>
          <Text>
            We often find ourselves too deep into solving a problem to see the
            big picture. When you stop and reflect, however, most every
            challenge can be viewed as a people challenge, a productivity
            challenge, or a revenue challenge. These challenge types are
            universal across experience levels and across industries.
          </Text>
        </Box>
      </GridItem>
      <GridItem order={{ lg: '1' }} colSpan={{ lg: '8' }}>
        <Box mt="2" mb="12">
          <Text fontSize="lg" fontWeight="medium">
            All important career moments center around overcoming an obstacle or
            rising to a challenge. Let's collect some details surrounding the
            challenge you faced in this moment.
            <br />
            <br />
            <b>Note:</b> Complex situations may require more than one challenge;
            you may enter as many as appropriate for this moment.
          </Text>
        </Box>
        <Stack
          spacing="6"
          divider={<StackDivider borderColor="amberGray.200" />}
        >
          {fields &&
            fields.map((challenge, idx) => (
              <Box key={challenge.id}>
                {fields.length > 1 && (
                  <Flex>
                    <IconButton
                      variant="ghost"
                      colorScheme="red"
                      ml="auto"
                      icon={<Icon h="6" w="6" as={UilTrashAlt} />}
                      onClick={() => handleRemove(idx)}
                    />
                  </Flex>
                )}
                <Grid
                  templateColumns={{
                    lg: 'repeat(2, 1fr)',
                  }}
                  gap="6"
                >
                  <GridItem colSpan="1">
                    <FormControl id={`problemType-${idx}`}>
                      <FormLabel>
                        What type of challenge did you face?
                      </FormLabel>
                      <Controller
                        control={control}
                        name={`challenges.${idx}.name`}
                        render={({ field: { onChange, ...inputProps } }) => (
                          <Select
                            placeholder={
                              loadingMomentAttributes
                                ? 'Loading...'
                                : 'Select option'
                            }
                            isDisabled={loadingMomentAttributes}
                            onChange={(e) => {
                              onChange(e.target.value)
                              setValue(`challenges.${idx}.type`, null)
                              e.target.value
                                ? setUnsavedField(true)
                                : setUnsavedField(false)
                            }}
                            {...inputProps}
                          >
                            {Object.entries(groupBy(contextAttributes, 'group'))
                              .sort()
                              .map(([group], index) => (
                                <option key={index} value={group}>
                                  {group}
                                </option>
                              ))}
                          </Select>
                        )}
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem colSpan="1">
                    <FormControl id={`natureChallenge-${idx}`}>
                      <FormLabel>
                        What was the nature of the challenge?
                      </FormLabel>
                      <CustomCreatableSelect
                        idx={idx}
                        challenge={challenge}
                        loadingMomentAttributes={loadingMomentAttributes}
                        onChange={(e) =>
                          e.target.value
                            ? setUnsavedField(true)
                            : setUnsavedField(false)
                        }
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem colSpan={{ lg: '2' }}>
                    <FormControl id={`contextStatement-${idx}`}>
                      <FormLabel>
                        Now that you have categorized the type and nature of the
                        challenge, describe the circumstances of this moment as
                        you would for an outsider to your organization or
                        someone unfamiliar with the situation.
                      </FormLabel>
                      <Textarea
                        rows="6"
                        {...register(`challenges.${idx}.statement`)}
                        defaultValue={challenge.statement}
                        onChange={(e) =>
                          e.target.value
                            ? setUnsavedField(true)
                            : setUnsavedField(false)
                        }
                      />
                    </FormControl>
                  </GridItem>
                </Grid>
              </Box>
            ))}
        </Stack>
        <Flex mt="4">
          <Button mx="auto" colorScheme="orange" onClick={handleAppend}>
            Add Another Challenge
          </Button>
          <Button
            variant="outline"
            colorScheme="orange"
            onClick={() => setTabIndex(1)}
          >
            Next
          </Button>
        </Flex>
      </GridItem>
    </Grid>
  )
}

const CustomCreatableSelect = ({ challenge, loadingMomentAttributes, idx }) => {
  const { control } = useFormContext()

  const contextAttributes =
    useSelector(
      (state) =>
        state.momentAttributes.data &&
        Object.values(state.momentAttributes.data).filter(
          (a) => a.type === settingsType.CONTEXTS.value
        )
    ) || []

  const [amberGray50, amberGray100, amberGray200] = useToken('colors', [
    'amberGray.50',
    'amberGray.100',
    'amberGray.200',
  ])

  const nameValue = useWatch({
    name: `challenges.${idx}.name`,
  })

  return (
    <Controller
      name={`challenges.${idx}.type`}
      control={control}
      defaultValue={challenge ? challenge.type : null}
      render={({ field: { value, onChange } }) => (
        <CreatableSelect
          components={{ DropdownIndicator: () => null }}
          formatCreateLabel={(input) => `Choose "${input}"`}
          styles={{
            control: (provided, state) => ({
              ...provided,
              backgroundColor: state.isDisabled ? amberGray100 : amberGray50,
              cursor: 'text',
            }),
            menu: (provided) => ({
              ...provided,
              borderColor: amberGray200,
              backgroundColor: amberGray50,
            }),
            menuList: (provided) => ({
              ...provided,
              borderColor: amberGray200,
            }),
            option: (provided) => ({
              ...provided,
              '&:hover': {
                backgroundColor: amberGray100,
              },
              color: 'black',
              backgroundColor: amberGray50,
            }),
            indicatorSeparator: () => ({
              display: 'none',
            }),
          }}
          isClearable
          isDisabled={loadingMomentAttributes || !nameValue}
          placeholder={
            loadingMomentAttributes ? 'Loading...' : 'Type or select option'
          }
          options={contextAttributes
            .filter((a) => a.group === nameValue)
            .sort((a, b) =>
              a.value > b.value ? 1 : b.value > a.value ? -1 : 0
            )
            .map((a) => ({
              value: a.value,
              label: a.value,
            }))}
          value={
            value
              ? {
                  value: value,
                  label: value,
                }
              : null
          }
          onChange={(e) => {
            onChange(e && e.value)
          }}
        />
      )}
    />
  )
}

export default ContextsDetails
