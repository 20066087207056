import React, { useEffect } from 'react'
import { Route, Switch, useLocation } from 'react-router-dom'

import { LinkedInPopUp } from 'react-linkedin-login-oauth2'

import Login from '../pages/Login'
import ForgotPassword from '../pages/ForgotPassword'
import ResetPassword from '../pages/ResetPassword'
import UserDashboard from '../pages/UserDashboard'
import SuperAdminDashboard from '../pages/SuperAdminDashboard'
import UserAccountSettings from '../pages/UserAccountSettings'
import AdminAccountSettings from '../pages/AdminAccountSettings'
import Register from '../pages/Register'
import CareerMoments from '../pages/CareerMoments'
import CareerStories from '../pages/CareerStories'
import CoachDashboard from '../pages/CoachDashboard'
import AppSettings from '../pages/AppSettings'

import GuardedRoute from './GuardedRoute'
import OnboardingStepOne from '../pages/OnboardingStepOne'
import OnboardingStepTwo from '../pages/OnboardingStepTwo'
import OnboardingFinal from '../pages/OnboardingFinal'
import Store from '../pages/Store'

const RootNavigation = () => {
  const location = useLocation()
  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      window.gtag('event', 'page_view', {
        page_path: location.pathname + location.search,
        // debug_mode: process.env.NODE_ENV === 'development',
      })
    }
  }, [location.pathname, location.search])

  return (
    <Switch>
      <GuardedRoute path="/login" exact component={Login} />
      <GuardedRoute path="/forgot-password" exact component={ForgotPassword} />
      <GuardedRoute path="/reset-password" exact component={ResetPassword} />

      <GuardedRoute path="/register" exact component={Register} />
      <GuardedRoute path="/" exact component={UserDashboard} />

      <GuardedRoute path="/dashboard" exact component={UserDashboard} />
      <GuardedRoute path="/users/:userId" exact component={UserDashboard} />
      <GuardedRoute
        path="/users/:userId/career-moments/:careerMomentId"
        exact
        component={CareerStories}
      />

      <GuardedRoute
        path="/career-moments/:careerMomentId"
        exact
        component={CareerMoments}
      />
      <GuardedRoute path="/career-moments" exact component={CareerMoments} />

      <GuardedRoute path="/account" exact component={UserAccountSettings} />
      <GuardedRoute
        path="/dashboard/admins"
        exact
        component={SuperAdminDashboard}
      />
      <GuardedRoute path="/coaches/:userId" exact component={CoachDashboard} />
      <GuardedRoute
        path="/coaches/:userId/account"
        exact
        component={AdminAccountSettings}
      />
      <GuardedRoute path="/app-settings" exact component={AppSettings} />
      <GuardedRoute exact path="/logout" component={Login} />
      <GuardedRoute exact path="/linkedin" component={LinkedInPopUp} />

      <GuardedRoute exact path="/onboarding/1" component={OnboardingStepOne} />
      <GuardedRoute exact path="/onboarding/2" component={OnboardingStepTwo} />
      <GuardedRoute
        exact
        path="/onboarding/confirm"
        component={OnboardingFinal}
      />

      <Route exact path="/store" component={Store} />
    </Switch>
  )
}

export default RootNavigation
