import React from 'react'
import ReactDOM from 'react-dom'
import dotenv from 'dotenv'
import App from './App'
import reportWebVitals from './reportWebVitals'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

dotenv.config()

const environment =
  window.location.hostname === 'localhost'
    ? 'local'
    : window.location.hostname === 'mycareerstory.helm.design'
    ? 'qa'
    : 'prod'
Sentry.init({
  dsn: 'https://3436aa29fa6449ac8adc764d082b7819@o427721.ingest.sentry.io/5621386',
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
  environment,
})

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(
  (environment === 'local' || environment === 'qa') && console.log
)
