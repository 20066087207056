import React, { useMemo } from 'react'
import { Circle, Tooltip, Icon } from '@chakra-ui/react'
import {
  UilCheckCircle,
  UilClock,
  UilExclamationCircle,
  UilPen,
} from '@iconscout/react-unicons'
import { csComputedStatusType } from '../../lib/types'

const StoryStatusIndicator = ({ content }) => {
  const getStatus = useMemo(() => {
    return content.computedStatus === csComputedStatusType.APPROVED
      ? {
          bg: 'green.100',
          fg: 'green.600',
          icon: UilCheckCircle,
          label: 'Approved',
        }
      : content.computedStatus === csComputedStatusType.NEEDS_REVISION
      ? {
          bg: 'yellow.200',
          fg: 'yellow.600',
          icon: UilExclamationCircle,
          label: 'Needs Revision',
        }
      : content.computedStatus === csComputedStatusType.DRAFT
      ? {
          bg: 'gray.100',
          fg: 'gray.600',
          icon: UilPen,
          label: 'Draft',
        }
      : {
          bg: 'blue.100',
          fg: 'blue.600',
          icon: UilClock,
          label: 'Waiting for Approval',
        }
  }, [content])

  return (
    <Tooltip label={getStatus.label} placement="top">
      <Circle size="7" bg={getStatus.bg}>
        <Icon as={getStatus.icon} color={getStatus.fg} boxSize="5" />
      </Circle>
    </Tooltip>
  )
}

export default StoryStatusIndicator
