/* eslint-disable no-sequences */

import { userType } from '../../lib/types'

// Actions
const LOAD_USERS = 'users/LOAD_USERS'
const LOAD_ADMINS = 'users/LOAD_ADMINS'
const UPDATE_ADMIN = 'users/UPDATE_ADMIN'
const INVITE = 'users/INVITE'
const REMOVE_USER = 'users/REMOVE_USER'
const REMOVE_ADMIN = 'users/REMOVE_ADMIN'
const REMOVE = 'users/REMOVE'
const CLEAR_CACHE = 'users/CLEAR_CACHE'

const ASSIGNMENT_CREATE = 'assignments/CREATE'
const ASSIGNMENT_UPDATE = 'assignments/UPDATE'
const ASSIGNMENT_REMOVE = 'assignments/REMOVE'

// Reducer
const initialState = {
  users: null,
  admins: null,
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_USERS: {
      return {
        ...state,
        users: action.users.reduce((a, b) => ((a[b.id] = b), a), {}),
      }
    }
    case LOAD_ADMINS: {
      return {
        ...state,
        admins: action.admins.reduce((a, b) => ((a[b.id] = b), a), {}),
      }
    }
    case UPDATE_ADMIN: {
      return {
        ...state,
        admins: {
          ...state.admins,
          [action.admin.id]: action.admin,
        },
      }
    }
    case REMOVE_USER: {
      return {
        ...state,
        users:
          state.users &&
          Object.values(state.users).filter((u) => u.id !== action.id),
      }
    }
    case REMOVE_ADMIN: {
      return {
        ...state,
        admins:
          state.admins &&
          Object.values(state.admins).filter((u) => u.id !== action.id),
      }
    }
    case INVITE: {
      return action.client.type === userType.ADMIN
        ? {
            ...state,
            admins: {
              ...state.admins,
              [action.client.id]: action.client,
            },
          }
        : {
            ...state,
            users: {
              ...state.users,
              [action.client.id]: action.client,
            },
          }
    }
    case ASSIGNMENT_CREATE:
    case ASSIGNMENT_REMOVE: {
      return {
        ...state,
        admins: {
          ...state.admins,
          [action.admin.id]: action.admin,
        },
        users: {
          ...state.users,
          [action.user.id]: action.user,
        },
      }
    }
    case ASSIGNMENT_UPDATE: {
      let admins = state.admins
      if (action.admins[0]) admins[action.admins[0].id] = action.admins[0]
      if (action.admins[1]) admins[action.admins[1].id] = action.admins[1]
      return {
        ...state,
        admins,
        users: {
          ...state.users,
          [action.user.id]: action.user,
        },
      }
    }
    case REMOVE:
      return initialState
    case CLEAR_CACHE:
      return initialState
    default:
      return state
  }
}

// Action Creators
export function loadUsers(users) {
  return { type: LOAD_USERS, users }
}

export function loadAdmins(admins) {
  return { type: LOAD_ADMINS, admins }
}

export function putAdmin(admin) {
  return { type: UPDATE_ADMIN, admin }
}

export function inviteClient(client) {
  return { type: INVITE, client }
}

export function removeUser(id) {
  return { type: REMOVE_USER, id }
}

export function removeAdmin(id) {
  return { type: REMOVE_ADMIN, id }
}

export function removeClients() {
  return { type: REMOVE }
}

export function postAssignments(admin, user) {
  return { type: ASSIGNMENT_CREATE, admin, user }
}

export function putAssignments(admins, user) {
  return { type: ASSIGNMENT_UPDATE, admins, user }
}

export function removeAssignments(admin, user) {
  return { type: ASSIGNMENT_REMOVE, admin, user }
}
