/* eslint-disable no-sequences */
// Actions
const LOAD = 'careerMoments/LOAD'
const LOAD_UNIQUE = 'careerMoments/LOAD_UNIQUE'
const CREATE = 'careerMoments/CREATE'
const UPDATE = 'careerMoments/UPDATE'
const REMOVE = 'careerMoments/REMOVE'
const CLEAR_CACHE = 'careerMoments/CLEAR_CACHE'

// Reducer
const initialState = {
  data: null,
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD: {
      return {
        ...state,
        data: action.careerMoments.reduce((a, b) => ((a[b.id] = b), a), {}),
      }
    }
    case LOAD_UNIQUE:
    case CREATE: {
      return {
        ...state,
        data: {
          ...state.data,
          [action.careerMoment.id]: { ...action.careerMoment },
        },
      }
    }
    case UPDATE: {
      return {
        ...state,
        data: {
          ...state.data,
          [action.careerMoment.id]: action.careerMoment,
        },
      }
    }
    case REMOVE:
      return initialState
    case CLEAR_CACHE:
      return initialState
    default:
      return state
  }
}

// Action Creators
export function loadCareerMoments(careerMoments) {
  return { type: LOAD, careerMoments }
}

export function loadCareerMoment(careerMoment) {
  return { type: LOAD_UNIQUE, careerMoment }
}

export function postCareerMoment(careerMoment) {
  return { type: CREATE, careerMoment }
}

export function putCareerMoment(careerMoment) {
  return { type: UPDATE, careerMoment }
}

export function removeCareerMoment() {
  return { type: REMOVE }
}
