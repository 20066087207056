import {
  appendParamsToUrl,
  errorHandler,
  fetchJSON,
  setupRequest,
} from '../lib/utils'
import { loadPayments, postPayments } from '../redux/payments'

function fetchPayments(params = null) {
  return async (dispatch, getState) => {
    let state = getState()
    try {
      const path = params
        ? '/payments?' + appendParamsToUrl(params)
        : '/payments'
      const { url, options } = setupRequest(path, 'GET', state)
      let json = await fetchJSON(url, options)
      if (json.description) throw Error(json.description)
      if (json.error) throw Error(json.error)
      if (!json.payments) throw Error('API_ERROR_FETCH_PAYMENTS')
      dispatch(loadPayments(json.payments))
    } catch (error) {
      errorHandler(error)
      throw error
    }
  }
}

function createPayments(data) {
  return async (dispatch, getState) => {
    let state = getState()
    try {
      validateCreatePayments(data)
      const { url, options } = setupRequest(`/payments`, 'POST', state)
      options.body = JSON.stringify(data)
      let json = await fetchJSON(url, options)
      if (json.description) throw Error(json.description)
      if (json.error) throw Error(json.error)
      if (!json.payments) throw Error('API_ERROR_CREATE_PAYMENTS')
      dispatch(postPayments(json.payments))
    } catch (error) {
      errorHandler(error)
      throw error
    }
  }
}

function validateCreatePayments({ userId, purchasedItemIds }) {
  if (!userId) throw Error('NO_USER_ID_PROVIDED')
  if (!Array.isArray(purchasedItemIds))
    throw Error('PURCHASED_ITEMS_MUST_BE_ARRAY')
  if (!purchasedItemIds.length) throw Error('NO_PURCHASED_ITEMS_PROVIDED')
}

export { fetchPayments, createPayments }
