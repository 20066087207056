import React, { useCallback, useEffect, useState } from 'react'
import {
  Box,
  Button,
  Flex,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Skeleton,
  Text,
  useToken,
  VStack,
} from '@chakra-ui/react'
import Container from '../components/common/Container'
import { useDispatch, useSelector } from 'react-redux'
import { fetchStoreItems } from '../api/storeItems'
import { onboardingQuestions, subscriptionType } from '../lib/types'
import { formatPrice } from '../lib/utils'
import { useHistory } from 'react-router'
import { createPayments } from '../api/payments'
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { postPaymentIntent } from '../api/stripe'
import { completeRegistration } from '../api/auth'
import { putUser } from '../redux/user'

export default function OnboardingFinal() {
  const dispatch = useDispatch()
  const registration = useSelector((state) => state.registration.data)
  const storeItems = useSelector((state) => state.storeItems.data)
  const [subscription] = useState(
    (registration && registration.subscription) || []
  )
  const [addons] = useState((registration && registration.addons) || [])
  const [isLoading, setIsLoading] = useState(false)

  const getStoreItems = useCallback(async () => {
    try {
      setIsLoading(true)
      await dispatch(fetchStoreItems())
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      alert(error)
    }
  }, [dispatch])

  const getSubscriptionType = () => {
    return Object.values(subscriptionType).find(
      (st) => st.value === parseInt(subscription)
    )
  }

  const getPaymentPrice = () => {
    const subscriptionPrice = getSubscriptionType().price
    const totalAddonsPrice =
      addons.length > 0
        ? addons.reduce((acc, value) => {
            return acc + storeItems[value].price
          }, 0)
        : 0
    return subscriptionPrice + totalAddonsPrice
  }

  useEffect(() => {
    getStoreItems()
  }, [getStoreItems])

  return (
    <Container py="20">
      <Grid templateColumns={{ lg: 'repeat(12, 1fr)' }}>
        <GridItem colStart={{ lg: '4' }} colSpan={{ lg: '6' }}>
          <Box boxShadow="base" p="6" mb="6" bg="white" w="100%" rounded="md">
            <Box mb="4">
              <Heading as="h1" fontSize="3xl" mb="8">
                Complete Registration
              </Heading>
            </Box>
            <Grid gap="8">
              <GridItem>
                <Box>
                  <Box mb="4">
                    <Text fontSize="2xl" fontWeight="semibold" as="span">
                      Subscription
                    </Text>
                  </Box>
                  {subscription && (
                    <Box>
                      <Flex justify="space-between">
                        <Text fontWeight="semibold" as="span">
                          {getSubscriptionType().title}
                        </Text>
                        <Text fontWeight="semibold" as="span">
                          {formatPrice(getSubscriptionType().price)}
                        </Text>
                      </Flex>
                    </Box>
                  )}
                </Box>
                {addons && addons.length > 0 && (
                  <Box mt="12">
                    <Box mb="4">
                      <Text fontSize="2xl" fontWeight="semibold" as="span">
                        Add-Ons
                      </Text>
                    </Box>
                    <Box>
                      <VStack spacing="4">
                        {!isLoading &&
                        addons &&
                        addons.length > 0 &&
                        storeItems &&
                        Object.keys(storeItems).length > 0 ? (
                          addons.map((a, idx) => (
                            <Box key={idx} w="100%">
                              <Flex justify="space-between">
                                <Text fontWeight="semibold" as="span">
                                  {storeItems[a].name}
                                </Text>
                                <Text fontWeight="semibold" as="span">
                                  {formatPrice(storeItems[a].price)}
                                </Text>
                              </Flex>
                            </Box>
                          ))
                        ) : (
                          <>
                            {[...Array(3)].map((_, idx) => (
                              <Skeleton key={idx} w="100%" h="3" />
                            ))}
                          </>
                        )}
                      </VStack>
                    </Box>
                  </Box>
                )}
              </GridItem>
              <GridItem borderTopWidth="1px" pt="4">
                <Flex justify="space-between" w="100%" py="2">
                  <Text fontWeight="semibold">Total</Text>
                  {!isLoading &&
                  storeItems &&
                  Object.keys(storeItems).length > 0 ? (
                    <Text fontWeight="semibold">
                      {formatPrice(getPaymentPrice())}
                    </Text>
                  ) : (
                    <Skeleton w="12" h="6" />
                  )}
                </Flex>
              </GridItem>
              <GridItem>
                <CheckoutContainer />
              </GridItem>
            </Grid>
          </Box>
        </GridItem>
      </Grid>
    </Container>
  )
}

const CheckoutContainer = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const stripe = useStripe()
  const elements = useElements()
  const user = useSelector((state) => state.user.data)
  const [isBusy, setIsBusy] = useState(false)
  const [stripeError, setStripeError] = useState(null)
  const [cardError, setCardError] = useState(null)
  const registration = useSelector((state) => state.registration.data)

  const [red500, amberGray600] = useToken('colors', [
    'red.500',
    'amberGray.600',
  ])

  const handleCreatePayments = async () => {
    const paymentIntent = await dispatch(
      postPaymentIntent({
        stripeCustomerId: user.stripeCustomerId,
        purchasedItemIds: registration.addons,
      })
    )

    const cardPayment = await stripe.confirmCardPayment(paymentIntent, {
      payment_method: {
        card: elements.getElement(CardElement),
      },
    })

    if (cardPayment.error) {
      setStripeError(cardPayment.error)
      setIsBusy(false)
      return
    }

    await dispatch(
      createPayments({
        userId: user.id,
        purchasedItemIds: registration.addons,
      })
    )
  }

  const handleSubmit = async (e) => {
    try {
      e.preventDefault()
      if (!stripe || !elements) {
        return
      }
      setIsBusy(true)

      const { paymentMethod, error: paymentError } =
        await stripe.createPaymentMethod({
          type: 'card',
          card: elements.getElement(CardElement),
          billing_details: {
            email: user.email,
          },
        })

      if (paymentError) {
        setStripeError(paymentError)
        setIsBusy(false)
      } else {
        const updatedUser = await dispatch(
          completeRegistration(
            user.id,
            {
              registrationAnswer1: registration.primaryReason
                ? onboardingQuestions.REASONS[registration.primaryReason]
                : '',
              registrationAnswer2: registration.secondaryReason
                ? onboardingQuestions.REASONS[registration.secondaryReason]
                : '',
              subscriptionTier: parseInt(registration.subscription),
              paymentMethodId: paymentMethod.id,
            },
            true
          )
        )
        if (registration && registration.addons && registration.addons.length) {
          await handleCreatePayments()
        }
        dispatch(putUser(updatedUser))
        history.push('/dashboard')
      }
    } catch (error) {
      setIsBusy(false)
      alert(error)
    }
  }

  return (
    <Grid gap="4">
      <GridItem>
        <FormLabel>Payment Information</FormLabel>
        <Box
          borderColor={cardError && 'red.500'}
          bg="amberGray.50"
          borderWidth="1px"
          rounded="md"
          p="3"
        >
          <CardElement
            onChange={(e) => {
              setCardError(e.error)
            }}
            options={{
              style: {
                base: {
                  fontSize: '16px',
                  '::placeholder': {
                    color: amberGray600,
                  },
                },
                invalid: {
                  color: red500,
                },
              },
            }}
          />
        </Box>
        {(stripeError || cardError) && (
          <Box mt="1">
            <Text color="red.500" fontWeight="semibold" fontSize="sm">
              {(stripeError && stripeError.message) ||
                (cardError && cardError.message)}
            </Text>
          </Box>
        )}
      </GridItem>
      <GridItem>
        <Button
          onClick={handleSubmit}
          colorScheme="orange"
          isFullWidth
          isLoading={isBusy}
          loadingText="Completing Registration..."
          isDisabled={!stripe || !elements}
        >
          Pay & Complete Registration
        </Button>
      </GridItem>
    </Grid>
  )
}
