import { settingsType } from '../../lib/types'
import {
  setupRequest,
  fetchJSON,
  errorHandler,
  appendParamsToUrl,
} from '../../lib/utils'
import {
  loadMomentAttributes,
  loadMomentAttribute,
  postMomentAttribute,
  putMomentAttribute,
  removeMomentAttribute,
} from '../../redux/admin/momentAttributes'

function fetchMomentAttributes(params = {}) {
  return async (dispatch, getState) => {
    let state = getState()
    try {
      const path = '/moment-attributes?' + appendParamsToUrl(params)
      const { url, options } = setupRequest(path, 'GET', state)
      let json = await fetchJSON(url, options)
      if (json.description) throw Error(json.description)
      if (json.error) throw Error(json.error)
      if (!json.momentAttributes)
        throw Error('API_ERROR_FETCH_MOMENT_ATTRIBUTES')
      dispatch(loadMomentAttributes(json.momentAttributes))
    } catch (error) {
      errorHandler(error)
      throw error
    }
  }
}

function fetchMomentAttribute(id) {
  return async (dispatch, getState) => {
    let state = getState()
    try {
      const { url, options } = setupRequest(
        `/moment-attributes/${id}`,
        'GET',
        state
      )
      let json = await fetchJSON(url, options)
      if (json.description) throw Error(json.description)
      if (json.error) throw Error(json.error)
      if (!json.momentAttribute) throw Error('API_ERROR_FETCH_MOMENT_ATTRIBUTE')
      dispatch(loadMomentAttribute(json.momentAttribute))
    } catch (error) {
      errorHandler(error)
      throw error
    }
  }
}

function createMomentAttribute(data) {
  return async (dispatch, getState) => {
    let state = getState()
    try {
      validateMomentAttribute(data)
      const { url, options } = setupRequest('/moment-attributes', 'POST', state)
      options.body = JSON.stringify(data)
      let json = await fetchJSON(url, options)
      if (json.description) throw Error(json.description)
      if (json.error) throw Error(json.error)
      if (!json.momentAttribute)
        throw Error('API_ERROR_CREATE_MOMENT_ATTRIBUTE')
      dispatch(postMomentAttribute(json.momentAttribute))
    } catch (error) {
      errorHandler(error)
      throw error
    }
  }
}

function updateMomentAttribute(id, data) {
  return async (dispatch, getState) => {
    let state = getState()
    try {
      validateMomentAttribute({ id, ...data }, false)
      const { url, options } = setupRequest(
        `/moment-attributes/${id}`,
        'PUT',
        state
      )
      options.body = JSON.stringify(data)
      let json = await fetchJSON(url, options)
      if (json.description) throw Error(json.description)
      if (json.error) throw Error(json.error)
      if (!json.momentAttribute)
        throw Error('API_ERROR_UPDATE_MOMENT_ATTRIBUTE')
      dispatch(putMomentAttribute(json.momentAttribute))
    } catch (error) {
      errorHandler(error)
      throw error
    }
  }
}

function deleteMomentAttribute(id) {
  return async (dispatch, getState) => {
    let state = getState()
    try {
      const { url, options } = setupRequest(
        `/moment-attributes/${id}`,
        'DELETE',
        state
      )
      let json = await fetchJSON(url, options)
      if (json.description) throw Error(json.description)
      if (json.error) throw Error(json.error)
      if (!json.success) throw Error('API_ERROR_DELETE_MOMENT_ATTRIBUTE')
      dispatch(removeMomentAttribute(id))
    } catch (error) {
      errorHandler(error)
      throw error
    }
  }
}

function validateMomentAttribute({ id, group, value, type }, created = true) {
  const errors = []
  if (!id && !created) errors.push('NO_ID_PROVIDED')
  if (type === settingsType.CONTEXTS.value && !group)
    errors.push('Please enter Problem Type')
  if (type === settingsType.CONTEXTS.value && !value)
    errors.push('Please enter Nature of Challenge')
  if (type === settingsType.ACTIONS.value && !group)
    errors.push('Please add a Skill Group')
  if (type === settingsType.ACTIONS.value && !value)
    errors.push('Please enter an Action Verb ')
  if (type === settingsType.RESULTS.ACCOMPLISHMENTS.value && !value)
    errors.push('Please enter an Accomplishment')
  if (type === settingsType.RESULTS.METRICS.value && !value)
    errors.push('Please enter a Metric')
  if (type === settingsType.STORY.TYPE.value && !value)
    errors.push('Please enter a Type')
  if (type === settingsType.STORY.SKILL.value && !value)
    errors.push('Please add a Skill')
  if (!type) errors.push('NO_TYPE_PROVIDED')
  if (errors.length) throw Error(errors.join('\n'))
}

export {
  fetchMomentAttributes,
  fetchMomentAttribute,
  createMomentAttribute,
  updateMomentAttribute,
  deleteMomentAttribute,
}
