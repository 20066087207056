import { Table, Tbody, Tr, Td, Skeleton } from '@chakra-ui/react'

const SkeletonTable = ({ rows = 3, columns = 3 }) => {
  return (
    <Table variant="unstyled">
      <Tbody>
        {[...Array(Number(rows))].map((_, index) => (
          <Tr key={index}>
            {[...Array(Number(columns))].map((_, index) => (
              <Td key={index}>
                <Skeleton height="3" />
              </Td>
            ))}
          </Tr>
        ))}
      </Tbody>
    </Table>
  )
}

export default SkeletonTable
