import { useState } from 'react'
import { Link as ReactRouterLink } from 'react-router-dom'

import { forgotPassword } from '../api/auth'
import {
  Input,
  Button,
  Box,
  Grid,
  GridItem,
  Flex,
  FormControl,
  FormLabel,
  Link,
  Alert,
  AlertDescription,
  AlertTitle,
  Heading,
  Text,
  Icon,
} from '@chakra-ui/react'
import Container from '../components/common/Container'
import { UilAngleRight } from '@iconscout/react-unicons'

const ForgotPassword = () => {
  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)

  const sendHandler = async (e) => {
    e.preventDefault()
    try {
      setLoading(true)
      await forgotPassword({ email })
      setLoading(false)
      setSuccess(true)
    } catch (error) {
      setLoading(false)
      alert(error)
    }
  }

  return (
    <Container d="flex" h="100%" w="100%">
      <Grid templateColumns={{ md: 'repeat(12, 1fr)' }} w="100%">
        <GridItem
          colStart={{ md: '3', lg: '4' }}
          colSpan={{ md: '8', lg: '6' }}
          d="flex"
          alignItems="center"
        >
          <Box boxShadow="base" p="6" mb="6" bg="white" w="100%" rounded="md">
            {success && (
              <Alert
                status="success"
                variant="subtle"
                mb="4"
                rounded="md"
                justifyContent="space-between"
              >
                <Box w="100%">
                  <Flex justify="space-between">
                    <AlertTitle fontSize="lg">Email Sent!</AlertTitle>
                    <Link
                      maxWidth="sm"
                      as={ReactRouterLink}
                      to="/reset-password"
                      fontWeight="600"
                      d="flex"
                      alignItems="center"
                      textDecoration="underline"
                    >
                      Reset Password
                      <Icon as={UilAngleRight} boxSize="6" />
                    </Link>
                  </Flex>
                  <AlertDescription>
                    Check your email for a link to reset your password.
                  </AlertDescription>
                </Box>
              </Alert>
            )}
            <form onSubmit={sendHandler}>
              <Box mb="4">
                <Heading as="h1" fontSize="3xl" mb="2">
                  Forgot Password
                </Heading>
                <Text>We'll send you a link to reset your password.</Text>
              </Box>
              <Grid gap="4">
                <GridItem mb="4">
                  <FormControl id="email">
                    <FormLabel>Email</FormLabel>
                    <Input
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      autoComplete="email"
                    />
                  </FormControl>
                </GridItem>
                <GridItem>
                  <Flex align="center">
                    <Box>
                      <Link
                        as={ReactRouterLink}
                        fontWeight="semibold"
                        color="orange.600"
                        to="/"
                      >
                        Back to Log In
                      </Link>
                    </Box>
                    <Button
                      ml="auto"
                      type="submit"
                      isLoading={loading}
                      loadingText="Submitting"
                      colorScheme="orange"
                    >
                      Send Email
                    </Button>
                  </Flex>
                </GridItem>
              </Grid>
            </form>
          </Box>
        </GridItem>
      </Grid>
    </Container>
  )
}

export default ForgotPassword
