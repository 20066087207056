import React from 'react'
import { Box, Text, VStack, Avatar, Flex } from '@chakra-ui/react'
import { useSelector } from 'react-redux'
import { formatDate } from '../../lib/utils'

const CommentThread = ({ comments }) => {
  const user = useSelector((state) => state.user.data)
  const isFromMe = (id) => id === user.id

  return (
    <VStack w="100%" spacing="4">
      {comments.map((comment, idx) => (
        <Box w="100%" key={idx}>
          <Flex>
            <Flex
              w="75%"
              ml={isFromMe(comment.userId) ? 'auto' : ''}
              align={isFromMe(comment.userId) ? 'flex-end' : 'flex-start'}
              flexDirection="column"
              justify="center"
            >
              <Box bg="warmGray.100" rounded="md" p="2" mb="1">
                {comment.type && (
                  <Text
                    as="span"
                    bold="true"
                    color="warmGray.500"
                    fontWeight="semibold"
                    textTransform="uppercase"
                    letterSpacing="0.025rem"
                    fontSize="xs"
                    d="flex"
                  >
                    Revision Request
                  </Text>
                )}
                <Text>{comment.content}</Text>
              </Box>
              <Text
                as="time"
                fontSize="xs"
                color="warmGray.500"
                dateTime={comment.createdAt}
              >
                {formatDate(comment.createdAt, 'MMM. D, h:mma')}
              </Text>
            </Flex>
            <Box
              sx={isFromMe(comment.userId) ? { ml: 2 } : { order: -1, mr: 2 }}
            >
              {comment.user && (
                <Avatar
                  name={`${comment.user.firstName} ${comment.user.lastName}`}
                  size="sm"
                />
              )}
            </Box>
          </Flex>
        </Box>
      ))}
    </VStack>
  )
}

export default CommentThread
