import React from 'react'
import { Box } from '@chakra-ui/react'
import { userType } from '../lib/types'
import { useSelector } from 'react-redux'
import AdminNavbar from '../components/global/AdminNavbar'
import UserNavbar from '../components/global/UserNavbar'
import Footer from '../components/global/Footer'
import '../../src/assets/css/global.css'

const DefaultLayout = (props) => {
  const user = useSelector((state) => state.user.data)

  return (
    <div id="page-container">
      {user.type === userType.SUPER || user.type === userType.ADMIN ? (
        <AdminNavbar />
      ) : (
        <UserNavbar />
      )}
      <Box sx={props.sx} id="content-wrap">
        {props.children}
      </Box>
      <Footer />
    </div>
  )
}

export default DefaultLayout
