import {
  setupRequest,
  fetchJSON,
  errorHandler,
  appendParamsToUrl,
} from '../lib/utils'
import { loadStoreItems } from '../redux/storeItems'

function fetchStoreItems(params = null) {
  return async (dispatch, getState) => {
    try {
      let state = getState()
      const path = params
        ? '/store-items?' + appendParamsToUrl(params)
        : '/store-items'
      const { url, options } = setupRequest(path, 'GET', state)
      let json = await fetchJSON(url, options)
      if (json.description) throw Error(json.description)
      if (json.error) throw Error(json.error)
      if (!json.storeItems) throw Error('API_ERROR_FETCH_STORE_ITEMS')
      dispatch(loadStoreItems(json.storeItems))
    } catch (error) {
      errorHandler(error)
      throw error
    }
  }
}

export { fetchStoreItems }
