import { errorHandler, fetchJSON, setupRequest } from '../lib/utils'
import { commentObjectType } from '../lib/types'
import { addCommentCareerStory } from '../redux/careerStories'

function createComment(data) {
  return async (dispatch, getState) => {
    let state = getState()
    try {
      const { url, options } = setupRequest(`/comments`, 'POST', state)
      data.userId = state.user.data.id
      options.body = JSON.stringify(data)
      let json = await fetchJSON(url, options)
      if (json.description) throw Error(json.description)
      if (json.error) throw Error(json.error)
      if (!json.comment) throw Error('API_ERROR_CREATE_COMMENT')
      if (json.comment.objectType === commentObjectType.CAREER_STORY)
        dispatch(addCommentCareerStory(json.comment))
      else throw Error('API_ERROR_CREATE_COMMENT_OBJECT_TYPE_NOT_RECOGNIZED')
      return json.comment
    } catch (error) {
      errorHandler(error)
      throw error
    }
  }
}

export { createComment }
