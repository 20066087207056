import React, { useCallback, useEffect, useState } from 'react'
import { Link as ReactRouterLink } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { register } from '../api/auth'
import {
  Input,
  Button,
  Box,
  Grid,
  GridItem,
  Flex,
  FormControl,
  FormLabel,
  Link,
  InputGroup,
  InputRightElement,
  Heading,
  useBoolean,
  FormHelperText,
} from '@chakra-ui/react'
import Container from '../components/common/Container'
import { useQuery } from '../lib/utils'
import { fetchUserByToken } from '../api/user'
import { tokenType } from '../lib/types'

const Register = () => {
  const query = useQuery()
  const dispatch = useDispatch()
  const token = query.get('token')

  const [show, setShow] = useBoolean()
  const [loading, setLoading] = useState(false)

  const [code, setCode] = useState(token || '')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')

  const getUser = useCallback(async () => {
    try {
      const user = await dispatch(
        fetchUserByToken(token, { type: tokenType.INVITATION })
      )
      setFirstName(user.firstName || '')
      setLastName(user.lastName || '')
      setEmail(user.email || '')
      setPhone(user.phone || '')
    } catch (error) {
      alert(error)
    }
  }, [dispatch, token])

  useEffect(() => {
    if (token) {
      getUser()
    }
  }, [getUser, token])

  const registerHandler = async (e) => {
    try {
      e.preventDefault()
      setLoading(true)
      await dispatch(
        register({
          firstName,
          lastName,
          email,
          phone,
          password,
          confirmPassword,
          code,
        })
      )
      setLoading(false)
    } catch (error) {
      setLoading(false)
      alert(error)
    }
  }

  return (
    <Container py="20">
      <Grid templateColumns={{ lg: 'repeat(12, 1fr)' }}>
        <GridItem colStart={{ lg: '4' }} colSpan={{ lg: '6' }}>
          <Box boxShadow="base" p="6" mb="6" bg="white" w="100%" rounded="md">
            <form onSubmit={registerHandler}>
              <Box mb="4">
                <Heading as="h1" fontSize="3xl">
                  Create Your Account
                </Heading>
              </Box>
              <Grid gap="4">
                <GridItem>
                  <FormControl id="firstName">
                    <FormLabel>First Name</FormLabel>
                    <Input
                      type="firstName"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      isRequired
                    />
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl id="lastName">
                    <FormLabel>Last Name</FormLabel>
                    <Input
                      type="lastName"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      isRequired
                    />
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl id="email">
                    <FormLabel>Email</FormLabel>
                    <Input
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      isRequired
                    />
                  </FormControl>
                </GridItem>
                <GridItem>
                  <Box pb="6" borderBottomWidth="1px">
                    <FormControl id="phone">
                      <FormLabel>Phone</FormLabel>
                      <Input
                        type="phone"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        isRequired
                      />
                    </FormControl>
                  </Box>
                </GridItem>
                <GridItem>
                  <FormControl id="password">
                    <FormLabel>Password</FormLabel>
                    <InputGroup size="md">
                      <Input
                        type={show ? 'text' : 'password'}
                        onChange={(e) => setPassword(e.target.value)}
                        autoComplete="new-password"
                        isRequired
                      />
                      <InputRightElement w="auto" mr="1">
                        <Button
                          colorScheme="orange"
                          variant="outline"
                          px="2"
                          size="sm"
                          onClick={setShow.toggle}
                        >
                          {show ? 'Hide' : 'Show'}
                        </Button>
                      </InputRightElement>
                    </InputGroup>
                  </FormControl>
                </GridItem>
                <GridItem>
                  <Box pb="6" borderBottomWidth="1px">
                    <FormControl id="confirm_password">
                      <FormLabel>Confirm Password</FormLabel>
                      <InputGroup size="md">
                        <Input
                          pr="4.5rem"
                          type={show ? 'text' : 'password'}
                          onChange={(e) => setConfirmPassword(e.target.value)}
                          autoComplete="new-password"
                        />
                      </InputGroup>
                    </FormControl>
                  </Box>
                </GridItem>
                <GridItem>
                  <Box mb="12">
                    <FormControl id="code">
                      <FormLabel>Have an Invite Code? Enter it here</FormLabel>
                      <Input
                        value={code}
                        onChange={(e) => setCode(e.target.value)}
                        autoComplete="one-time-code"
                        readOnly={token}
                        bg={token ? 'amberGray.100' : 'amberGray.50'}
                        borderColor={token ? 'amberGray.300' : 'amberGray.200'}
                      />
                      <FormHelperText color="warmGray.500">
                        If you've been invited, this field is required in order
                        to complete your registration.
                      </FormHelperText>
                    </FormControl>
                  </Box>
                </GridItem>
                <GridItem>
                  <Flex align="center">
                    <Box>
                      <Link
                        as={ReactRouterLink}
                        fontWeight="semibold"
                        color="orange.600"
                        to="/login"
                      >
                        Back to Log In
                      </Link>
                    </Box>
                    <Button
                      isLoading={loading}
                      loadingText="Submitting"
                      colorScheme="orange"
                      ml="auto"
                      type="submit"
                    >
                      Create Account
                    </Button>
                  </Flex>
                </GridItem>
              </Grid>
            </form>
          </Box>
        </GridItem>
      </Grid>
    </Container>
  )
}

export default Register
