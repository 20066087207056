import { setupRequest, fetchJSON, errorHandler } from '../lib/utils'
import {
  loadCareerMoments,
  loadCareerMoment,
  postCareerMoment,
  putCareerMoment,
} from '../redux/careerMoments'

function fetchCareerMoments(params = null) {
  return async (dispatch, getState) => {
    let state = getState()
    const { id } = state.user.data
    const userId = (params && params.userId) || id
    try {
      const { url, options } = setupRequest(
        `/career-moments?userId=${userId}`,
        'GET',
        state
      )
      let json = await fetchJSON(url, options)
      if (json.description) throw Error(json.description)
      if (json.error) throw Error(json.error)
      if (!json.careerMoments) throw Error('API_ERROR_FETCH_CAREER_MOMENTS')
      dispatch(loadCareerMoments(json.careerMoments))
    } catch (error) {
      errorHandler(error)
      throw error
    }
  }
}

function fetchCareerMoment(id) {
  return async (dispatch, getState) => {
    let state = getState()
    try {
      const { url, options } = setupRequest(
        `/career-moments/${id}`,
        'GET',
        state
      )
      let json = await fetchJSON(url, options)
      if (json.description) throw Error(json.description)
      if (json.error) throw Error(json.error)
      if (!json.careerMoment) throw Error('API_ERROR_FETCH_CAREER_MOMENT')
      dispatch(loadCareerMoment(json.careerMoment))
    } catch (error) {
      errorHandler(error)
      throw error
    }
  }
}

function createCareerMoment(data) {
  return async (dispatch, getState) => {
    let state = getState()
    try {
      const { url, options } = setupRequest(`/career-moments`, 'POST', state)
      const careerMoment = verificationData(data)
      options.body = JSON.stringify(careerMoment)
      let json = await fetchJSON(url, options)
      if (json.description) throw Error(json.description)
      if (json.error) throw Error(json.error)
      if (!json.careerMoment) throw Error('API_ERROR_CREATE_CAREER_MOMENT')
      dispatch(postCareerMoment(json.careerMoment))
      if (json) return json
    } catch (error) {
      errorHandler(error)
      throw error
    }
  }
}

function updateCareerMoment(id, data) {
  return async (dispatch, getState) => {
    let state = getState()
    try {
      const { url, options } = setupRequest(
        `/career-moments/${id}`,
        'PUT',
        state
      )
      const careerMoment = verificationData(data)
      options.body = JSON.stringify(careerMoment)
      let json = await fetchJSON(url, options)
      if (json.description) throw Error(json.description)
      if (json.error) throw Error(json.error)
      if (!json.careerMoment) throw Error('API_ERROR_UPDATE_CAREER_MOMENT')
      dispatch(putCareerMoment(json.careerMoment))
    } catch (error) {
      errorHandler(error)
      throw error
    }
  }
}

function verificationData(data) {
  data.challenges = data.challenges.filter(
    (c) => c.name !== '' && c.statement !== '' && c.type !== ''
  )
  data.actions = data.actions.filter(
    (c) => c.name !== '' && c.statement !== '' && c.type !== ''
  )
  data.results = data.results.filter(
    (c) => c.name !== '' && c.statement !== '' && c.type !== ''
  )
  data.learnings = data.learnings.filter(
    (c) => c.name !== '' && c.statement !== ''
  )
  return data
}

export {
  fetchCareerMoments,
  fetchCareerMoment,
  createCareerMoment,
  updateCareerMoment,
}
