import { Box } from '@chakra-ui/layout'
import { useRadio } from '@chakra-ui/radio'
import React from 'react'

export default function CompoundRadio({ sx, children, ...props }) {
  const { getInputProps, getCheckboxProps } = useRadio(props)

  const input = getInputProps()
  const checkbox = getCheckboxProps()

  return (
    <Box
      {...checkbox}
      as="label"
      w="100%"
      cursor="pointer"
      _focus={{
        boxShadow: 'outline',
      }}
      sx={sx}
    >
      <input {...input} required />
      <Box>{children}</Box>
    </Box>
  )
}
