import React from 'react'
import {
  Grid,
  GridItem,
  Box,
  Button,
  Flex,
  Text,
  FormLabel,
  Input,
  FormControl,
  Textarea,
  Stack,
  StackDivider,
  IconButton,
  Icon,
} from '@chakra-ui/react'
import { UilTrashAlt } from '@iconscout/react-unicons'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { cmStatusType } from '../../lib/types'

const LearningsDetails = ({
  onAppendOrRemove,
  setTabIndex,
  tabIndex,
  saveHandler,
  loadingSave,
  viewingUser,
  setUnsavedField,
}) => {
  const { register } = useFormContext()
  const { fields, append, remove } = useFieldArray({
    name: 'learnings',
  })

  const handleAppend = () => {
    onAppendOrRemove(1)
    append({ name: '', type: '', statement: '' })
  }

  const handleRemove = (idx) => {
    onAppendOrRemove(-1)
    remove(idx)
  }
  const handleBack = (prevTab) => {
    setTabIndex(prevTab)
  }

  return (
    <Grid templateColumns={{ lg: 'repeat(12, 1fr)' }} gap="6">
      <GridItem order={{ lg: '2' }} colSpan={{ lg: '4' }}>
        <Box
          bg="amberGray.75"
          p="4"
          rounded="md"
          fontSize="sm"
          position="sticky"
          top="6"
        >
          <Text fontWeight="semibold">Pro Tip:</Text>
          <Text>
            Learning can take many forms. You may be explicitly aware that you
            are learning, such as the case when working in a new industry where
            you are encountering new terminology and unfamiliar business
            processes. Learning can also come in the form of implicit learning
            where you are not consciously aware you are learning something new
            until you complete an exercise such as this where you reflect on the
            experience, this is most often the case in soft skill learning.
          </Text>
        </Box>
      </GridItem>
      <GridItem order={{ lg: '1' }} colSpan={{ lg: '8' }}>
        <Box mt="2" mb="12">
          <Text fontSize="lg" fontWeight="medium">
            All moments teach us something that we carry forward into future
            projects, positions or experiences. Let's identify and record what
            you learned.
            <br />
            <br />
            <b>Note:</b> Learning can include actions we would like to repeat
            again because they delivered success or actions to avoid in the
            future because they did not address the challenge or negatively
            impacted the result. List as many learnings as appropriate for this
            moment.
          </Text>
        </Box>
        <Stack
          spacing="6"
          divider={<StackDivider borderColor="amberGray.200" />}
        >
          {fields &&
            fields.map((learning, idx) => (
              <Box key={learning.id}>
                {fields.length > 1 && (
                  <Flex>
                    <IconButton
                      variant="ghost"
                      colorScheme="red"
                      ml="auto"
                      icon={<Icon h="6" w="6" as={UilTrashAlt} />}
                      onClick={() => handleRemove(idx)}
                    />
                  </Flex>
                )}
                <Grid
                  templateColumns={{
                    lg: 'repeat(2, 1fr)',
                  }}
                  gap="6"
                >
                  <GridItem colSpan={{ lg: '2' }}>
                    <FormControl id={`learningCategory-${idx}`}>
                      <FormLabel>
                        How would you categorize your learning- did you learn a
                        new technical or soft skill, a new industry, how to lead
                        or to fail?
                      </FormLabel>
                      <Input
                        type="text"
                        {...register(`learnings.${idx}.name`)}
                        defaultValue={learning.name}
                        autoComplete="off"
                        onChange={(e) =>
                          e.target.value
                            ? setUnsavedField(true)
                            : setUnsavedField(false)
                        }
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem colSpan={{ lg: '2' }}>
                    <FormControl id={`learningStatement-${idx}`}>
                      <FormLabel>
                        Explain what you learned in greater detail. It may be
                        helpful to consider this: if challenged with a similar
                        experience in the future, how would this moment have
                        equipped you to address it with increased profitability
                        or greater efficiency.
                      </FormLabel>
                      <Textarea
                        rows="6"
                        {...register(`learnings.${idx}.statement`)}
                        defaultValue={learning.statement}
                        onChange={(e) =>
                          e.target.value
                            ? setUnsavedField(true)
                            : setUnsavedField(false)
                        }
                      />
                    </FormControl>
                  </GridItem>
                </Grid>
              </Box>
            ))}
        </Stack>
        <Flex mt="4">
          <Button
            variant="outline"
            colorScheme="orange"
            onClick={() => handleBack(2)}
          >
            Back
          </Button>
          <Button mx="auto" colorScheme="orange" onClick={handleAppend}>
            Add Another Learning
          </Button>
          <Button
            onClick={() => {
              setUnsavedField(false)
              saveHandler(cmStatusType.PUBLISHED)
            }}
            colorScheme="orange"
            loadingText="Saving..."
            isDisabled={loadingSave && loadingSave !== cmStatusType.PUBLISHED}
            isLoading={loadingSave === cmStatusType.PUBLISHED}
          >
            Save
          </Button>
        </Flex>
      </GridItem>
    </Grid>
  )
}

export default LearningsDetails
