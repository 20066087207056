import {
  Button,
  FormControl,
  FormLabel,
  Box,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
  useToken,
  Text,
} from '@chakra-ui/react'
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { postSubscription } from '../api/stripe'
import { subscriptionType } from '../lib/types'
import SubscriptionRadioList from './SubscriptionRadioList'

export default function SubscriptionModal({ closeModalHandler }) {
  const user = useSelector((state) => state.user.data)
  const dispatch = useDispatch()
  const stripe = useStripe()
  const elements = useElements()
  const [isBusy, setIsBusy] = useState(false)
  const [stripeError, setStripeError] = useState(null)
  const [cardError, setCardError] = useState(null)
  const [subscription, setSubscription] = useState('')

  const [red500, amberGray600] = useToken('colors', [
    'red.500',
    'amberGray.600',
  ])

  const handleCreateSubscription = async (e) => {
    try {
      e.preventDefault()
      if (!stripe || !elements) {
        return
      }
      setIsBusy(true)
      const { paymentMethod, error: paymentError } =
        await stripe.createPaymentMethod({
          type: 'card',
          card: elements.getElement(CardElement),
          billing_details: {
            email: user.email,
          },
        })

      if (paymentError) {
        setStripeError(paymentError)
        setIsBusy(false)
      } else {
        await dispatch(
          postSubscription({
            userId: user.id,
            subscriptionTier: parseInt(subscription),
            paymentMethodId: paymentMethod.id,
          })
        )
      }
      closeModalHandler()
    } catch (error) {
      setIsBusy(false)
      alert(error)
    }
  }

  const subscriptionOptions = [
    {
      value: subscriptionType.MONTHLY.value,
      title: subscriptionType.MONTHLY.title,
      price: subscriptionType.MONTHLY.price,
      description:
        'Looking for more than the basic features? Get assigned a Career Coach to help convert your Moments into Stories and get access to purchase premium add-on services with a monthly subscription. Ideal for active job seekers or those looking to tell a compelling Career Story.',
    },
    {
      value: subscriptionType.ANNUAL.value,
      title: subscriptionType.ANNUAL.title,
      price: subscriptionType.ANNUAL.price,
      description:
        'Looking for more than the basic features and want to save money? Get all the benefits of the monthly subscription with the savings of an annual subscription. Ideal for active job seekers with many years of experience or for those looking for ongoing access to a Career Coach.',
    },
  ]

  return (
    <>
      <ModalHeader>Become a Premium Subscriber</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <FormControl id="subscription">
          <FormLabel>Select a Subscription</FormLabel>
          <SubscriptionRadioList
            value={subscription}
            handleChange={setSubscription}
            options={subscriptionOptions}
          />
        </FormControl>
      </ModalBody>
      <ModalFooter alignItems="start">
        <Box flexGrow="1">
          <Box
            borderColor={cardError && 'red.500'}
            bg="amberGray.50"
            borderWidth="1px"
            rounded="md"
            px="3"
            py="2"
            flexGrow="1"
          >
            <CardElement
              onChange={(e) => {
                setCardError(e.error)
              }}
              sx={{ width: '100%' }}
              options={{
                style: {
                  base: {
                    height: '40px',
                    fontSize: '16px',
                    '::placeholder': {
                      color: amberGray600,
                    },
                  },
                  invalid: {
                    color: red500,
                  },
                },
              }}
            />
          </Box>
          {(stripeError || cardError) && (
            <Box mt="1">
              <Text color="red.500" fontWeight="semibold" fontSize="sm">
                {(stripeError && stripeError.message) ||
                  (cardError && cardError.message)}
              </Text>
            </Box>
          )}
        </Box>
        <Button
          colorScheme="orange"
          isLoading={isBusy}
          loadingText="Updating..."
          isDisabled={!subscription}
          onClick={handleCreateSubscription}
          ml="2"
        >
          Subscribe
        </Button>
      </ModalFooter>
    </>
  )
}
