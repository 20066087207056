/* eslint-disable no-sequences */
// Actions
const LOAD = 'jobsListings/LOAD'
const LOAD_UNIQUE = 'jobsListings/LOAD_UNIQUE'
const CREATE = 'jobsListings/CREATE'
const UPDATE = 'jobsListings/UPDATE'
const REMOVE = 'jobsListings/REMOVE'
const CLEAR_CACHE = 'jobsListings/CLEAR_CACHE'

// Reducer
const initialState = {
  data: null,
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD: {
      return {
        ...state,
        data: action.jobsListings.reduce((a, b) => ((a[b.id] = b), a), {}),
      }
    }
    case LOAD_UNIQUE:
    case CREATE: {
      return {
        ...state,
        data: {
          ...state.data,
          [action.jobsListing.id]: { ...action.jobsListing },
        },
      }
    }
    case UPDATE: {
      return {
        ...state,
        data: {
          ...state.data,
          [action.jobsListing.id]: action.jobsListing,
        },
      }
    }
    case REMOVE:
      return initialState
    case CLEAR_CACHE:
      return initialState
    default:
      return state
  }
}

// Action Creators
export function loadJobsListings(jobsListings) {
  return { type: LOAD, jobsListings }
}

export function loadJobsListing(jobsListing) {
  return { type: LOAD_UNIQUE, jobsListing }
}

export function postJobListing(jobsListing) {
  return { type: CREATE, jobsListing }
}

export function putJobsListing(jobsListing) {
  return { type: UPDATE, jobsListing }
}

export function removeJobsListing() {
  return { type: REMOVE }
}
