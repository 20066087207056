import { useState } from 'react'
import { Link as ReactRouterLink } from 'react-router-dom'

import { resetPassword } from '../api/auth'
import {
  Input,
  Button,
  Box,
  Grid,
  GridItem,
  Flex,
  FormControl,
  FormLabel,
  Link,
  AlertTitle,
  AlertDescription,
  Icon,
  Alert,
  Heading,
  Text,
} from '@chakra-ui/react'
import Container from '../components/common/Container'

import { UilAngleRight } from '@iconscout/react-unicons'
import { useQuery } from '../lib/utils'

const ResetPassword = () => {
  const query = useQuery()
  const token = query.get('token')

  const [code, setCode] = useState(token || '')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)

  const resetHandler = async (e) => {
    e.preventDefault()
    try {
      setLoading(true)
      await resetPassword({ code, password, confirmPassword })
      setLoading(false)
      setSuccess(true)
      setPassword('')
      setConfirmPassword('')
    } catch (error) {
      setLoading(false)
      alert(error)
    }
  }

  return (
    <Container d="flex" h="100%" w="100%">
      <Grid templateColumns={{ md: 'repeat(12, 1fr)' }} w="100%">
        <GridItem
          colStart={{ md: '3', lg: '4' }}
          colSpan={{ md: '8', lg: '6' }}
          d="flex"
          alignItems="center"
        >
          <Box boxShadow="base" p="6" mb="6" bg="white" w="100%" rounded="md">
            {success && (
              <Alert status="success" variant="subtle" mb="4" rounded="md">
                <Box w="100%">
                  <Flex justify="space-between">
                    <AlertTitle fontSize="lg">Password Reset!</AlertTitle>
                    <Link
                      maxWidth="sm"
                      as={ReactRouterLink}
                      to="/"
                      fontWeight="600"
                      d="flex"
                      alignItems="center"
                      textDecoration="underline"
                    >
                      Log In
                      <Icon as={UilAngleRight} boxSize="6" />
                    </Link>
                  </Flex>
                  <AlertDescription>
                    You can now log in with your new password.
                  </AlertDescription>
                </Box>
              </Alert>
            )}
            <form onSubmit={resetHandler}>
              <Box mb="4">
                <Heading as="h1" fontSize="3xl" mb="2">
                  Reset Password
                </Heading>
                <Text>
                  Check your email for a{' '}
                  <Text as="span" fontWeight="bold">
                    password reset code
                  </Text>
                  , you'll need it to reset your password.
                </Text>
              </Box>
              <Grid gap="4">
                <GridItem>
                  <FormControl id="reset-code">
                    <FormLabel>Code</FormLabel>
                    <Input
                      value={code}
                      onChange={(e) => setCode(e.target.value)}
                      autoComplete="one-time-code"
                      readOnly={token}
                      bg={token ? 'amberGray.100' : 'amberGray.50'}
                      borderColor={token ? 'amberGray.300' : 'amberGray.200'}
                    />
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl id="new-password">
                    <FormLabel>New Password</FormLabel>
                    <Input
                      type="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      autoComplete="new-password"
                    />
                  </FormControl>
                </GridItem>
                <GridItem mb="4">
                  <FormControl id="new-password-confirm">
                    <FormLabel>Confirm New Password</FormLabel>
                    <Input
                      type="password"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      autoComplete="new-password"
                    />
                  </FormControl>
                </GridItem>
                <GridItem>
                  <Flex align="center">
                    <Box>
                      <Link
                        as={ReactRouterLink}
                        fontWeight="semibold"
                        color="orange.600"
                        to="/"
                      >
                        Back to Log In
                      </Link>
                    </Box>
                    <Button
                      ml="auto"
                      type="submit"
                      isLoading={loading}
                      loadingText="Submitting"
                      colorScheme="orange"
                    >
                      Reset Password
                    </Button>
                  </Flex>
                </GridItem>
              </Grid>
            </form>
          </Box>
        </GridItem>
      </Grid>
    </Container>
  )
}

export default ResetPassword
