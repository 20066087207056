import React from 'react'
import { Box, Button, Flex, useDisclosure, Icon } from '@chakra-ui/react'
import Container from '../components/common/Container'
import { UilBars, UilArrowLeft } from '@iconscout/react-unicons'
import StoryEditor from '../components/careerStories/StoryEditor'
import MomentsDrawer from '../components/careerStories/MomentsDrawer'
import { Link as ReactRouterLink, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import DefaultLayout from '../layouts/default'
import { userType } from '../lib/types'

const CareerStories = () => {
  const momentsDrawer = useDisclosure()
  const { userId } = useParams()

  const viewingUser = useSelector((state) =>
    userId && state.users.users && state.users.users[userId]
      ? state.users.users[userId]
      : state.user.data
  )

  return (
    <DefaultLayout>
      <Box
        borderBottomWidth="1px"
        position="sticky"
        top="0"
        bg="amberGray.100"
        py="2"
        zIndex="1"
      >
        <Container>
          <Flex>
            <Button
              size="sm"
              mr="2"
              colorScheme="orange"
              variant="link"
              leftIcon={<Icon boxSize="5" as={UilBars} />}
              onClick={momentsDrawer.onOpen}
            >
              View All Moments
            </Button>
          </Flex>
        </Container>
      </Box>
      <MomentsDrawer
        isDrawerOpen={momentsDrawer.isOpen}
        onDrawerClose={momentsDrawer.onClose}
      />
      <Container py="12">
        <Box mb="4">
          <Button
            as={ReactRouterLink}
            to={`/users/${userId}`}
            leftIcon={<Icon boxSize="6" as={UilArrowLeft} />}
            colorScheme="orange"
            variant="link"
          >
            {viewingUser.type === userType.USER
              ? 'Back to my Dashboard'
              : `Back to ${viewingUser.firstName}'s Dashboard`}
          </Button>
        </Box>
        <StoryEditor viewingUser={viewingUser} />
      </Container>
    </DefaultLayout>
  )
}

export default CareerStories
