import React, { useCallback, useEffect, useState } from 'react'
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  SkeletonText,
  Text,
  useDisclosure,
  useToken,
} from '@chakra-ui/react'
import Container from '../components/common/Container'
import { useDispatch, useSelector } from 'react-redux'
import { fetchStoreItems } from '../api/storeItems'
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { postPaymentIntent } from '../api/stripe'
import { createPayments } from '../api/payments'
import { formatPrice, isFreeUser } from '../lib/utils'
import SubscriptionModal from '../components/SubscriptionModal'
import DefaultLayout from '../layouts/default'

export default function Store() {
  const dispatch = useDispatch()
  const user = useSelector((state) => state.user.data)
  const storeItems = useSelector((state) => state.storeItems.data)
  const [isLoadingStoreItems, setIsLoadingStoreItems] = useState(false)
  const modalState = useDisclosure()
  const [modalType, setModalType] = useState(null)

  // TODO: Show Skeleton for store items

  const getStoreItems = useCallback(async () => {
    try {
      setIsLoadingStoreItems(true)
      await dispatch(fetchStoreItems())
      setIsLoadingStoreItems(false)
    } catch (error) {
      setIsLoadingStoreItems(false)

      alert(error)
    }
  }, [dispatch])

  useEffect(() => {
    getStoreItems()
  }, [getStoreItems])

  const openModalHandler = (type) => {
    setModalType(type)
    modalState.onOpen()
  }

  return (
    <DefaultLayout>
      <Container py="12">
        <Grid py="6" templateColumns={{ lg: 'repeat(12, 1fr)' }} gap="6">
          <GridItem colSpan={{ lg: '9' }}>
            <Box mb="8">
              <Heading mb="4">Add-On Store</Heading>
              <Text fontSize="xl">
                Premium add-ons enable you to tell your Career Story in the best
                way possible with the help of your assigned Career Coach.  These
                services are available to both monthly and annual subscribers.{' '}
                {user && isFreeUser(user) && (
                  <>
                    Free subscribers can{' '}
                    <Button
                      variant="link"
                      fontSize="inherit"
                      fontWeight="semibold"
                      colorScheme="orange"
                      onClick={() =>
                        openModalHandler(
                          <SubscriptionModal
                            closeModalHandler={modalState.onClose}
                          />
                        )
                      }
                    >
                      modify their subscription level
                    </Button>{' '}
                    to take advantage of these offerings.
                  </>
                )}
              </Text>
            </Box>
          </GridItem>
        </Grid>
        <Grid py="6" templateColumns={{ lg: 'repeat(12, 1fr)' }} gap="6">
          {storeItems && !isLoadingStoreItems ? (
            <>
              {Object.values(storeItems).map((si, idx) => (
                <GridItem key={idx} colSpan={{ lg: '4' }}>
                  <Flex
                    direction="column"
                    boxShadow="base"
                    p="6"
                    bg="white"
                    rounded="md"
                    h="100%"
                  >
                    <Box mb="4">
                      <Box mb="4">
                        <Heading fontSize="3xl" mb="1">
                          {si.name}
                        </Heading>
                        <Text>{formatPrice(si.price)}</Text>
                      </Box>
                      <Text>{si.description}</Text>
                    </Box>
                    <Button
                      mt="auto"
                      colorScheme="orange"
                      isFullWidth
                      onClick={() =>
                        openModalHandler(
                          <StoreItemModal
                            closeModalHandler={modalState.onClose}
                            item={si}
                          />
                        )
                      }
                    >
                      View Item
                    </Button>
                  </Flex>
                </GridItem>
              ))}
              <GridItem colSpan={{ lg: '4' }}>
                <OtherStoreItem
                  openModalHandler={openModalHandler}
                  closeModalHandler={modalState.onClose}
                />
              </GridItem>
            </>
          ) : (
            <StoreItemsSkeleton />
          )}
        </Grid>
      </Container>

      <Modal size="xl" isOpen={modalState.isOpen} onClose={modalState.onClose}>
        <ModalOverlay />
        <ModalContent>{modalType}</ModalContent>
      </Modal>
    </DefaultLayout>
  )
}

const OtherStoreItemModal = ({ item }) => {
  useEffect(() => {
    const injectForm = () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: 'na1',
          portalId: '20209710',
          formId: '6c5484e4-3d57-4d6a-a8bb-91110e7ac667',
          target: '#hubspotForm',
        })
      }
    }

    const script = document.createElement('script')
    script.src = 'https://js.hsforms.net/forms/v2.js'
    script.async = true
    document.body.appendChild(script)

    script.addEventListener('load', injectForm)

    return () => {
      script.removeEventListener('load', injectForm)
      script.remove()
    }
  }, [])

  return (
    <>
      <ModalHeader>Purchase Item</ModalHeader>
      <ModalCloseButton />
      <ModalBody px="0" pb="6">
        <Grid gap="6">
          <GridItem px="6" borderBottomWidth="1px" pb="6">
            <Box mb="4">
              <Heading fontSize="3xl" mb="1">
                {item.name}
              </Heading>
            </Box>
            <Text>{item.longDescription}</Text>
          </GridItem>
          <GridItem px="6">
            <Box id="hubspotForm" />
          </GridItem>
        </Grid>
      </ModalBody>
    </>
  )
}

const OtherStoreItem = ({ openModalHandler }) => {
  const name = 'Other'
  const description =
    'Not sure what you need or looking for something not listed here? Get advice from a Career Coach or request a quote.'
  const longDescription =
    'Our Career Coaches are here to help. Fill out the request form to get advice on recommended services based on your years of experience and unique needs or to get a custom quote on additional services beyond those offered in our Store.'

  return (
    <Flex
      direction="column"
      boxShadow="base"
      p="6"
      bg="white"
      rounded="md"
      h="100%"
    >
      <Box mb="4">
        <Box mb="4">
          <Heading fontSize="3xl" mb="1">
            {name}
          </Heading>
        </Box>
        <Text>{description}</Text>
      </Box>
      <Button
        mt="auto"
        colorScheme="orange"
        isFullWidth
        onClick={() =>
          openModalHandler(
            <OtherStoreItemModal
              item={{
                name,
                description,
                longDescription,
              }}
            />
          )
        }
      >
        View Item
      </Button>
    </Flex>
  )
}

const StoreItemsSkeleton = () => {
  return (
    <>
      {[...Array(6)].map((el, idx) => (
        <GridItem key={idx} colSpan={{ lg: '4' }}>
          <Box boxShadow="base" p="6" bg="white" rounded="md">
            <Skeleton h="8" mb="2" />
            <Skeleton h="4" w="12" mb="6" />
            <SkeletonText noOfLines={6} />
          </Box>
        </GridItem>
      ))}
    </>
  )
}

const StoreItemModal = ({ closeModalHandler, item }) => {
  const user = useSelector((state) => state.user.data)
  const dispatch = useDispatch()
  const stripe = useStripe()
  const elements = useElements()
  const [isBusy, setIsBusy] = useState(false)
  const [stripeError, setStripeError] = useState(null)
  const [cardError, setCardError] = useState(null)
  const [stripeSuccess, setStripeSuccess] = useState(false)

  const modalState = useDisclosure()
  const [modalType, setModalType] = useState(null)

  const openModalHandler = (type) => {
    setModalType(type)
    modalState.onOpen()
  }

  const [red500, amberGray600] = useToken('colors', [
    'red.500',
    'amberGray.600',
  ])

  const handleSubmit = async (e) => {
    try {
      e.preventDefault()
      if (!stripe || !elements) {
        return
      }
      setIsBusy(true)

      const paymentIntent = await dispatch(
        postPaymentIntent({
          stripeCustomerId: user.stripeCustomerId,
          purchasedItemIds: [item.id],
        })
      )
      const cardPayment = await stripe.confirmCardPayment(paymentIntent, {
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: {
            email: user.email,
          },
        },
      })

      if (cardPayment.error) {
        setStripeError(cardPayment.error)
        setIsBusy(false)
      } else {
        await dispatch(
          createPayments({ userId: user.id, purchasedItemIds: [item.id] })
        )
        setStripeSuccess(true)
        setIsBusy(false)
      }
    } catch (error) {
      setIsBusy(false)
      alert(error)
    }
  }
  return (
    <>
      <ModalHeader>Purchase Item</ModalHeader>
      <ModalCloseButton />
      <ModalBody px="0" pb="6">
        <Grid gap="6">
          <GridItem px="6" borderBottomWidth="1px" pb="6">
            <Box mb="4">
              <Heading fontSize="3xl" mb="1">
                {item.name}
              </Heading>
              <Text>{formatPrice(item.price)}</Text>
            </Box>
            <Text>{item.longDescription || item.description}</Text>
          </GridItem>
          <GridItem px="6">
            {user && isFreeUser(user) ? (
              <Button
                colorScheme="orange"
                isFullWidth
                onClick={() =>
                  openModalHandler(
                    <SubscriptionModal closeModalHandler={modalState.onClose} />
                  )
                }
              >
                Subscribe to Purchase Add-Ons
              </Button>
            ) : (
              <>
                {stripeSuccess ? (
                  <Alert
                    status="success"
                    variant="subtle"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    textAlign="center"
                    p="8"
                    rounded="md"
                  >
                    <AlertIcon boxSize="40px" mr="0" />
                    <AlertTitle mt="4" mb="1" fontSize="lg">
                      Purchase Completed Successfully!
                    </AlertTitle>
                    <AlertDescription maxWidth="sm" mb="4">
                      Thank you for your purchase.
                    </AlertDescription>
                    <Button
                      colorScheme="green"
                      size="md"
                      onClick={closeModalHandler}
                    >
                      Close Modal
                    </Button>
                  </Alert>
                ) : (
                  <Grid gap="4">
                    <GridItem>
                      <Box
                        borderColor={cardError && 'red.500'}
                        bg="amberGray.50"
                        borderWidth="1px"
                        rounded="md"
                        p="3"
                      >
                        <CardElement
                          onChange={(e) => {
                            setCardError(e.error)
                          }}
                          options={{
                            style: {
                              base: {
                                fontSize: '16px',
                                '::placeholder': {
                                  color: amberGray600,
                                },
                              },
                              invalid: {
                                color: red500,
                              },
                            },
                          }}
                        />
                      </Box>
                      {(stripeError || cardError) && (
                        <Box mt="1">
                          <Text
                            color="red.500"
                            fontWeight="semibold"
                            fontSize="sm"
                          >
                            {(stripeError && stripeError.message) ||
                              (cardError && cardError.message)}
                          </Text>
                        </Box>
                      )}
                    </GridItem>
                    <GridItem>
                      <Button
                        onClick={handleSubmit}
                        colorScheme="orange"
                        isFullWidth
                        isLoading={isBusy}
                        loadingText="Purchasing..."
                        isDisabled={!stripe || !elements}
                      >
                        Purchase
                      </Button>
                    </GridItem>
                  </Grid>
                )}
              </>
            )}
          </GridItem>
        </Grid>
      </ModalBody>
      <Modal size="xl" isOpen={modalState.isOpen} onClose={modalState.onClose}>
        <ModalOverlay />
        <ModalContent>{modalType}</ModalContent>
      </Modal>
    </>
  )
}
