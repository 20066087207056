// Actions
const CREATE = 'token/CREATE'
const CREATE_CODE_LINKEDIN = 'token/CREATE_CODE_LINKEDIN'
const CREATE_TOKEN_LINKEDIN = 'token/CREATE_TOKEN_LINKEDIN'
const DELETE_CODE_LINKEDIN = 'token/DELETE_CODE_LINKEDIN'
const DELETE_TOKEN_LINKEDIN = 'token/DELETE_TOKEN_LINKEDIN'
const REMOVE = 'token/REMOVE'

// Reducer
const initialState = {
  accessToken: null,
  codeLinkedin: null,
  accessTokenLinkedin: null,
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case CREATE: {
      return { ...state, accessToken: action.token }
    }
    case CREATE_CODE_LINKEDIN: {
      return { ...state, codeLinkedin: action.code }
    }
    case CREATE_TOKEN_LINKEDIN: {
      return { ...state, accessTokenLinkedin: action.token }
    }
    case DELETE_CODE_LINKEDIN: {
      return { ...state, codeLinkedin: null }
    }
    case DELETE_TOKEN_LINKEDIN: {
      return { ...state, accessTokenLinkedin: null }
    }
    case REMOVE:
      return initialState
    default:
      return state
  }
}

// Action Creators
export function createToken(token) {
  return { type: CREATE, token }
}

export function createCodeLinkedin(code) {
  return { type: CREATE_CODE_LINKEDIN, code }
}

export function createTokenLinkedin(token) {
  return { type: CREATE_TOKEN_LINKEDIN, token }
}

export function deleteCodeLinkedin() {
  return { type: DELETE_CODE_LINKEDIN }
}

export function deleteTokenLinkedin() {
  return { type: DELETE_TOKEN_LINKEDIN }
}

export function removeToken() {
  return { type: REMOVE }
}
