import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Link as ReactRouterLink, useHistory } from 'react-router-dom'
import {
  Grid,
  GridItem,
  Box,
  Button,
  Flex,
  Heading,
  Link,
  Text,
  Avatar,
  Modal,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalOverlay,
  ModalCloseButton,
  useDisclosure,
  FormLabel,
  Input,
  FormHelperText,
  FormControl,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Tag,
  Stack,
  HStack,
  Skeleton,
  Icon,
  Menu,
  MenuButton,
  MenuList,
  Textarea,
  useToken,
  IconButton,
  MenuItem,
  LinkBox,
  LinkOverlay,
  VisuallyHidden,
  Image,
  MenuOptionGroup,
  MenuItemOption,
  Center,
} from '@chakra-ui/react'

import {
  formatDate,
  sortFormatDate,
  formatBytes,
  isFreeUser,
} from '../lib/utils'
import { useDispatch, useSelector } from 'react-redux'
import { fetchCareerMoments } from '../api/careerMoments'
import { fetchCareerStories } from '../api/careerStories'
import {
  createJobsListing,
  updateJobsListing,
  fetchJobsListings,
} from '../api/jobsListings'
import {
  createCareerMaterial,
  updateCareerMaterial,
  fetchCareerMaterials,
  archivingCareerMaterial,
} from '../api/careerMaterials'
import {
  cmStatusType,
  cmComputedStatusType,
  csStatusType,
  csComputedStatusType,
  jlStatusType,
  mStatusType,
  userType,
} from '../lib/types'
import {
  UilBookOpen,
  UilDiary,
  UilUserCircle,
  UilClipboardNotes,
  UilFileAlt,
  UilFilter,
  UilExternalLinkAlt,
  UilEllipsisV,
  UilSortAmountDown,
  UilPlus,
  UilPadlock,
  UilLink,
} from '@iconscout/react-unicons'
import momentsIllustration from '../assets/images/add_moments.svg'
import jobsIllustration from '../assets/images/add_jobs.svg'
import moreMomentsIllustration from '../assets/images/add_more_moments.svg'

import Container from '../components/common/Container'
import CareerStoryModal from '../components/userDashboard/CareerStoryModal'
import { useParams } from 'react-router'
import StoryStatusIndicator from '../components/careerStories/StoryStatusIndicator'
import MomentStatusIndicator from '../components/careerMoments/MomentStatusIndicator'
import orderBy from 'lodash/orderBy'
import Overlay from '../components/common/Overlay'
import { buildUrl } from '../lib/utils'
import SubscriptionModal from '../components/SubscriptionModal'
import DefaultLayout from '../layouts/default'

const UserDashboard = () => {
  const dispatch = useDispatch()
  const { userId } = useParams()

  const modalState = useDisclosure()
  const [modalType, setModalType] = useState(null)

  const [loadingCareerMoments, setLoadingCareerMoments] = useState(false)
  const [loadingCareerStories, setLoadingCareerStories] = useState(false)
  const [loadingJobsListings, setLoadingJobsListings] = useState(false)
  const [loadingCareerMaterials, setLoadingCareerMaterials] = useState(false)

  const user = useSelector((state) => state.user.data)

  const isAssigned = useSelector(
    (state) =>
      state.user.data &&
      state.user.data.assignments &&
      state.user.data.assignments.some((u) => u.id === Number(userId))
  )

  const viewingUser = useSelector((state) =>
    ((user.type === userType.ADMIN && isAssigned) ||
      user.type === userType.SUPER) &&
    userId
      ? state.users.users[userId]
      : state.user.data
  )

  const jobsListings = useSelector((state) => state.jobsListings.data)
  const careerMaterials = useSelector((state) => state.careerMaterials.data)
  const careerStories = useSelector((state) => state.careerStories.data)
  const careerMoments = useSelector((state) => state.careerMoments.data)

  const getCareerMoments = useCallback(async () => {
    try {
      setLoadingCareerMoments(true)
      await dispatch(fetchCareerMoments({ userId: viewingUser.id }))
      setLoadingCareerMoments(false)
    } catch (error) {
      setLoadingCareerMoments(false)
      alert(error)
    }
  }, [dispatch, viewingUser])

  const getCareerStories = useCallback(async () => {
    try {
      setLoadingCareerStories(true)
      await dispatch(fetchCareerStories({ userId: viewingUser.id }))
      setLoadingCareerStories(false)
    } catch (error) {
      setLoadingCareerStories(false)
      alert(error)
    }
  }, [dispatch, viewingUser])

  const getJobsListings = useCallback(async () => {
    try {
      setLoadingJobsListings(true)
      await dispatch(fetchJobsListings({ userId: viewingUser.id }))
      setLoadingJobsListings(false)
    } catch (error) {
      setLoadingJobsListings(false)
      alert(error)
    }
  }, [dispatch, viewingUser])

  const getCareerMaterials = useCallback(async () => {
    try {
      setLoadingCareerMaterials(true)
      await dispatch(fetchCareerMaterials({ userId: viewingUser.id }))
      setLoadingCareerMaterials(false)
    } catch (error) {
      setLoadingCareerMaterials(false)
      alert(error)
    }
  }, [dispatch, viewingUser])

  useEffect(() => {
    getCareerMoments()
    getCareerStories()
    getJobsListings()
    getCareerMaterials()
  }, [getCareerMoments, getCareerStories, getJobsListings, getCareerMaterials])

  const openModalHandler = (type) => {
    setModalType(type)
    modalState.onOpen()
  }

  return (
    <DefaultLayout>
      <Container py="12">
        {user.type === userType.USER && (
          <>
            <Box>
              <Heading as="h1">Welcome, {viewingUser.firstName}</Heading>
            </Box>
            <Grid py="6" templateColumns={{ lg: 'repeat(12, 1fr)' }} gap="6">
              {/* {Object.keys(careerMoments).length === 0 && ( */}
              <GridItem colSpan="4">
                <OnboardingMoments />
              </GridItem>
              {/* // )} */}
              {/* {Object.keys(jobsListings).length === 0 && ( */}
              <GridItem colSpan="4">
                <OnboardingJobListings
                  modalState={modalState}
                  openModalHandler={openModalHandler}
                />
              </GridItem>
              {/* )} */}
              <GridItem colSpan="4">
                <DashboardTips />
              </GridItem>
            </Grid>
          </>
        )}
        {user.type !== userType.USER && (
          <>
            <Box>
              <Heading as="h1">
                {viewingUser.firstName} {viewingUser.lastName}
              </Heading>
            </Box>
          </>
        )}
        <Grid py="6" templateColumns={{ lg: 'repeat(12, 1fr)' }} gap="6">
          <GridItem colSpan={{ lg: '8' }} maxWidth="100%">
            <StoriesAndMoments
              loadingCareerMoments={loadingCareerMoments}
              loadingCareerStories={loadingCareerStories}
              careerMoments={careerMoments}
              careerStories={careerStories}
            />
          </GridItem>
          <GridItem colSpan={{ lg: '4' }}>
            <CareerCoach />
            <JobListings
              openModalHandler={openModalHandler}
              modalState={modalState}
              jobsListings={jobsListings}
              isLoading={loadingJobsListings}
            />
            <CareerMaterials
              openModalHandler={openModalHandler}
              modalState={modalState}
              careerMaterials={careerMaterials}
              isLoading={loadingCareerMaterials}
            />
          </GridItem>
        </Grid>
      </Container>
      <Modal isOpen={modalState.isOpen} onClose={modalState.onClose}>
        <ModalOverlay />
        <ModalContent>{modalType}</ModalContent>
      </Modal>
    </DefaultLayout>
  )
}

const JobListingModal = ({ closeModalHandler, jobsListing }) => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const [localJobsListing, setLocalJobsListing] = useState(
    jobsListing || { companyName: '', title: '', link: '' }
  )
  const { userId } = useParams()
  const viewingUser = useSelector((state) =>
    userId ? state.users.users[userId] : state.user.data
  )

  const submitHandler = async (e) => {
    e.preventDefault()
    try {
      setIsLoading(true)
      if (jobsListing) {
        await dispatch(updateJobsListing(localJobsListing.id, localJobsListing))
      } else {
        await dispatch(
          createJobsListing({ userId: viewingUser.id, ...localJobsListing })
        )
      }
      setIsLoading(false)
      closeModalHandler()
    } catch (error) {
      setIsLoading(false)
      alert(error)
    }
  }

  return (
    <>
      <ModalHeader>{jobsListing ? 'Edit' : 'Add'} Job Listing</ModalHeader>
      <ModalCloseButton />
      <form onSubmit={submitHandler}>
        <ModalBody>
          <Grid templateColumns="repeat(2, 1fr)" gap="4">
            <GridItem>
              <FormControl id="companyName">
                <FormLabel>Company</FormLabel>
                <Input
                  type="text"
                  value={localJobsListing.companyName}
                  onChange={(e) =>
                    setLocalJobsListing((jl) => ({
                      ...jl,
                      companyName: e.target.value,
                    }))
                  }
                  isRequired
                />
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl id="role">
                <FormLabel>Role</FormLabel>
                <Input
                  type="text"
                  value={localJobsListing.title}
                  onChange={(e) =>
                    setLocalJobsListing((jl) => ({
                      ...jl,
                      title: e.target.value,
                    }))
                  }
                  isRequired
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan="2">
              <FormControl id="ListingURL">
                <FormLabel>Listing URL</FormLabel>
                <Input
                  type="url"
                  value={localJobsListing.link}
                  onChange={(e) =>
                    setLocalJobsListing((jl) => ({
                      ...jl,
                      link: e.target.value,
                    }))
                  }
                  isRequired
                />
                <FormHelperText>Must be a valid link.</FormHelperText>
              </FormControl>
            </GridItem>
          </Grid>
        </ModalBody>

        <ModalFooter>
          <Button mr="3" variant="ghost" onClick={closeModalHandler}>
            Close
          </Button>
          <Button
            isLoading={isLoading}
            loadingText="Saving"
            colorScheme="orange"
            type="submit"
          >
            Save
          </Button>
        </ModalFooter>
      </form>
    </>
  )
}

const CareerMaterialsModal = ({ closeModalHandler, careerMaterial }) => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const [tabIndex, setTabIndex] = useState(0)
  const [localCareerMaterial, setLocalCareerMaterial] = useState(
    careerMaterial || {
      name: '',
      link: '',
      description: '',
      file: null,
    }
  )

  const { userId } = useParams()
  const viewingUser = useSelector((state) =>
    userId ? state.users.users[userId] : state.user.data
  )

  const [orange600] = useToken('colors', ['orange.600'])

  const submitHandler = async (e) => {
    e.preventDefault()
    try {
      setIsLoading(true)

      if (careerMaterial) {
        await dispatch(
          updateCareerMaterial(localCareerMaterial.id, {
            ...localCareerMaterial,
          })
        )
      } else {
        await dispatch(
          createCareerMaterial({
            userId: viewingUser.id,
            ...localCareerMaterial,
          })
        )
      }
      setIsLoading(false)
      closeModalHandler()
    } catch (error) {
      setIsLoading(false)
      alert(error)
    }
  }

  return (
    <>
      <ModalHeader>
        {careerMaterial ? 'Edit' : 'Add'} Career Material
      </ModalHeader>
      <ModalCloseButton />
      <form onSubmit={submitHandler}>
        <ModalBody>
          <Grid templateColumns="repeat(2, 1fr)" gap="4">
            <GridItem colSpan="2">
              <FormControl id="itemName">
                <FormLabel>Name</FormLabel>
                <Input
                  type="text"
                  value={localCareerMaterial.name}
                  onChange={(e) =>
                    setLocalCareerMaterial((cm) => ({
                      ...cm,
                      name: e.target.value,
                    }))
                  }
                  isRequired
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan="2">
              <FormControl id="description">
                <FormLabel>Description</FormLabel>
                <Textarea
                  value={localCareerMaterial.description}
                  onChange={(e) =>
                    setLocalCareerMaterial((cm) => ({
                      ...cm,
                      description: e.target.value,
                    }))
                  }
                  resize="none"
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan="2">
              <Tabs
                index={tabIndex}
                onChange={(idx) => setTabIndex(idx)}
                isFitted
              >
                <TabList>
                  {[{ label: 'Paste Link' }, { label: 'Upload File' }].map(
                    (tab, idx) => (
                      <Tab
                        key={idx}
                        fontWeight="semibold"
                        color="amberGray.700"
                        _selected={{
                          color: 'black',
                          boxShadow: `inset 0 -3px ${orange600}`,
                        }}
                        isDisabled={
                          localCareerMaterial.file || localCareerMaterial.link
                        }
                      >
                        {tab.label}
                      </Tab>
                    )
                  )}
                </TabList>
                <TabPanels>
                  <TabPanel>
                    {tabIndex === 0 && (
                      <FormControl id="link">
                        <FormLabel>Link</FormLabel>
                        <Input
                          type="text"
                          value={localCareerMaterial.link}
                          onChange={(e) =>
                            setLocalCareerMaterial((cm) => ({
                              ...cm,
                              link: e.target.value,
                            }))
                          }
                          isRequired
                        />
                      </FormControl>
                    )}
                  </TabPanel>
                  <TabPanel>
                    {tabIndex === 1 && (
                      <>
                        {localCareerMaterial.file ? (
                          <Box>
                            <Flex mb="4">
                              <Icon
                                as={UilFileAlt}
                                boxSize="6"
                                color="orange.600"
                                mt="1"
                              />
                              <Box ml="2">
                                <Text
                                  as="span"
                                  fontWeight="medium"
                                  fontSize="lg"
                                  wordBreak="break-all"
                                >
                                  {localCareerMaterial.file.name}
                                </Text>
                                <Text
                                  fontSize="sm"
                                  fontWeight="medium"
                                  color="amberGray.700"
                                >
                                  {formatBytes(localCareerMaterial.file.size)}
                                </Text>
                              </Box>
                            </Flex>
                            <Button
                              colorScheme="red"
                              size="sm"
                              variant="ghost"
                              onClick={() =>
                                setLocalCareerMaterial((cm) => ({
                                  ...cm,
                                  file: null,
                                }))
                              }
                            >
                              Cancel
                            </Button>
                          </Box>
                        ) : (
                          <Box position="relative">
                            <VisuallyHidden
                              id="upload"
                              as="input"
                              type="file"
                              h="100%"
                              w="100%"
                              onChange={(e) => {
                                setLocalCareerMaterial((cm) => ({
                                  ...cm,
                                  file: e.target.files[0],
                                }))
                              }}
                              required
                            />
                            <Button
                              as="label"
                              htmlFor="upload"
                              cursor="pointer"
                              isFullWidth
                            >
                              Choose a File to Upload
                            </Button>
                          </Box>
                        )}
                      </>
                    )}
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </GridItem>
          </Grid>
        </ModalBody>
        <ModalFooter>
          <Button mr="3" variant="ghost" onClick={closeModalHandler}>
            Close
          </Button>
          <Button
            isLoading={isLoading}
            loadingText="Saving"
            colorScheme="orange"
            type="submit"
          >
            Save
          </Button>
        </ModalFooter>
      </form>
    </>
  )
}

const OnboardingMoments = () => (
  <Box
    boxShadow="base"
    p="6"
    bg="white"
    rounded="md"
    position="relative"
    overflow="hidden"
    h="100%"
  >
    <Flex
      direction="column"
      mr={{ base: '24', sm: '32' }}
      zIndex="1"
      position="relative"
      h="100%"
    >
      <Box mb="4">
        <Text
          fontFamily="tisa"
          fontWeight="semibold"
          fontSize="2xl"
          lineHeight="1.2"
          mb="4"
        >
          Tell us about your important moments.
        </Text>
        <Text fontSize="lg">Your career moments help us tell your story.</Text>
      </Box>
      <Box mt="auto">
        <Button as={ReactRouterLink} to="/career-moments" colorScheme="orange">
          Add a Moment
        </Button>
      </Box>
    </Flex>
    <Image
      zIndex="0"
      position="absolute"
      top="0"
      right={{ base: '-20', sm: '-2', lg: '-28', xl: '-8' }}
      h="100%"
      src={momentsIllustration}
      alt=""
    />
  </Box>
)

const OnboardingJobListings = ({ openModalHandler, modalState }) => {
  const openHandler = useCallback(
    (jobsListing = null) => {
      openModalHandler(
        <JobListingModal
          closeModalHandler={modalState.onClose}
          jobsListing={jobsListing}
        />
      )
    },
    [modalState, openModalHandler]
  )

  return (
    <Box
      boxShadow="base"
      p="6"
      bg="white"
      rounded="md"
      position="relative"
      overflow="hidden"
      h="100%"
    >
      <Flex
        direction="column"
        mr={{ base: '24', sm: '32' }}
        zIndex="1"
        position="relative"
        h="100%"
      >
        <Box mb="4">
          <Text
            fontFamily="tisa"
            fontWeight="semibold"
            fontSize="2xl"
            lineHeight="1.2"
            mb="4"
          >
            Your next great career step is waiting.
          </Text>
          <Text fontSize="lg">
            Got your sights set on a specific position? Track your ideal jobs
            here.
          </Text>
        </Box>
        <Box mt="auto">
          <Button onClick={() => openHandler()} colorScheme="orange">
            Add a Job Listing
          </Button>
        </Box>
      </Flex>
      <Image
        zIndex="0"
        position="absolute"
        top="0"
        right={{ base: '-20', sm: '-2', lg: '-28', xl: '-8' }}
        h="100%"
        w="auto"
        src={jobsIllustration}
        alt=""
      />
    </Box>
  )
}

const DashboardTips = () => {
  return (
    <Box
      boxShadow="base"
      p="6"
      bg="white"
      rounded="md"
      position="relative"
      overflow="hidden"
      h="100%"
    >
      <Flex
        direction="column"
        mr={{ base: '24', sm: '32' }}
        zIndex="1"
        position="relative"
        h="100%"
      >
        <Box mb="4">
          <Text
            fontFamily="tisa"
            fontWeight="semibold"
            fontSize="2xl"
            lineHeight="1.2"
            mb="4"
          >
            Getting Started - Watch tutorials or ask a question.
          </Text>
          <Text fontSize="lg">
            Watch how-to videos, read FAQ's or ask your own questions.
          </Text>
        </Box>
        <Box mt="auto">
          <Link
            href="https://help.mycareerstory.io/knowledge"
            isExternal
            style={{ textDecoration: 'none' }}
          >
            <Button colorScheme="orange" textDecoration="none">
              Get Help
            </Button>
          </Link>
        </Box>
      </Flex>
      <Image
        zIndex="0"
        position="absolute"
        top="0"
        right={{ base: '-36', sm: '-8', lg: '-40', xl: '-28' }}
        h="100%"
        src={moreMomentsIllustration}
        alt=""
      />
    </Box>
  )
}

const SmallStackLoader = () => {
  const skeletonStyle = {
    startColor: 'amberGray.300',
    endColor: 'amberGray.700',
    height: '4',
  }

  return (
    <Stack spacing="8">
      {[...Array(3)].map((el, i) => (
        <Stack key={i}>
          <Skeleton {...skeletonStyle} w="75%" />
          <Skeleton {...skeletonStyle} w="50%" />
          <Skeleton {...skeletonStyle} w="25%" />
        </Stack>
      ))}
    </Stack>
  )
}

const StackLoader = () =>
  [...Array(3)].map((el, j) => (
    <Box key={j} bg="white" rounded="md" p="4" borderWidth="1px">
      <Stack>
        <Skeleton height="5" />
        <Skeleton height="5" />
        <Skeleton height="5" />
      </Stack>
    </Box>
  ))

const StoriesAndMoments = ({
  loadingCareerMoments,
  loadingCareerStories,
  careerMoments,
  careerStories,
}) => {
  const history = useHistory()
  const { userId } = useParams()
  const user = useSelector((state) => state.user.data)
  const viewingUser = useSelector((state) =>
    userId ? state.users.users[userId] : state.user.data
  )
  const [storyFilter, setStoryFilter] = useState(0)
  const [storySort, setStorySort] = useState(0)

  const [momentFilter, setMomentFilter] = useState(0)
  const [momentSort, setMomentSort] = useState(0)

  const [orange600] = useToken('colors', ['orange.600'])
  const [tabIndex, setTabIndex] = useState(0)

  const momentSortOptions = [
    {
      value: 'computedStatus',
      direction: 'asc',
      label: 'Status (A - Z)',
    },
    {
      value: 'computedStatus',
      direction: 'desc',
      label: 'Status (Z - A)',
    },
    {
      value: 'title',
      direction: 'asc',
      label: 'Title (A - Z)',
    },
    {
      value: 'title',
      direction: 'desc',
      label: 'Title (Z - A)',
    },
  ]

  const momentFilterOptions = [
    { value: null, label: 'Show All' },
    {
      value: cmComputedStatusType.ALL_APPROVED,
      label: 'All Approved',
    },
    {
      value: cmComputedStatusType.PENDING,
      label: 'Pending',
    },
    {
      value: cmComputedStatusType.NO_STORIES,
      label: 'No Stories',
    },
  ]

  if (user.type === userType.SUPER) {
    momentFilterOptions.push({
      value: cmComputedStatusType.DRAFT,
      label: 'Draft',
    })
  }

  const storySortOptions = [
    {
      value: 'computedStatus',
      direction: 'asc',
      label: 'Status (A - Z)',
    },
    {
      value: 'computedStatus',
      direction: 'desc',
      label: 'Status (Z - A)',
    },
    {
      value: 'title',
      direction: 'asc',
      label: 'Title (A - Z)',
    },
    {
      value: 'title',
      direction: 'desc',
      label: 'Title (Z - A)',
    },
  ]

  const storyFilterOptions = [
    { value: null, label: 'Show All' },
    {
      value: csComputedStatusType.APPROVED,
      label: 'Approved',
    },
    {
      value: csComputedStatusType.NEEDS_REVISION,
      label: 'Needs Revision',
    },
    {
      value: csComputedStatusType.PENDING,
      label: 'Waiting for Approval',
    },
  ]

  if (user.type === userType.SUPER) {
    storyFilterOptions.push({
      value: csComputedStatusType.DRAFT,
      label: 'Draft',
    })
  }

  const storyStatusSortOrder = [
    csComputedStatusType.APPROVED,
    csComputedStatusType.DRAFT,
    csComputedStatusType.NEEDS_REVISION,
    csComputedStatusType.PENDING,
  ]

  const modalState = useDisclosure()
  const [modalType, setModalType] = useState(null)

  const openModalHandler = (type) => {
    setModalType(type)
    modalState.onOpen()
  }

  return (
    <Box>
      <Box boxShadow="base" bg="white" rounded="md" overflow="hidden">
        <Box>
          <Tabs onChange={(idx) => setTabIndex(idx)} colorScheme="orange">
            <Box pt="4">
              <TabList px={{ base: '2', lg: '6' }} borderBottomWidth="1px">
                {[
                  {
                    label: 'Your Moments',
                    icon: UilDiary,
                    iconColor: 'green.600',
                  },
                  {
                    label: 'Your Stories',
                    icon: UilBookOpen,
                    iconColor: 'orange.600',
                  },
                ].map((tab, idx) => (
                  <Tab
                    key={idx}
                    fontFamily="tisa"
                    fontWeight="semibold"
                    fontSize={{ lg: 'lg' }}
                    color="amberGray.700"
                    _selected={{
                      color: 'black',
                      boxShadow: `inset 0 -3px ${orange600}`,
                    }}
                  >
                    <Icon
                      as={tab.icon}
                      transition="0.2s ease color"
                      boxSize="5"
                      color={tabIndex === idx ? tab.iconColor : 'inherit'}
                      mr="2"
                    />
                    {tab.label}
                  </Tab>
                ))}
              </TabList>
            </Box>
            <TabPanels bg="amberGray.50">
              <TabPanel>
                <HStack mb="2">
                  <Menu closeOnSelect={false} placement="bottom-end">
                    <MenuButton
                      as={Button}
                      size="sm"
                      leftIcon={<Icon as={UilFilter} h="5" w="5" />}
                      colorScheme="orange"
                      variant="ghost"
                      ml="auto"
                    >
                      Filter
                    </MenuButton>
                    <MenuList>
                      <MenuOptionGroup
                        title="Filter by Status"
                        value={momentFilter.toString()}
                        defaultValue={momentFilter.toString()}
                        onChange={setMomentFilter}
                        type="radio"
                      >
                        {momentFilterOptions.map((fo, idx) => (
                          <MenuItemOption
                            key={idx}
                            fontSize="sm"
                            value={idx.toString()}
                          >
                            {fo.label}
                          </MenuItemOption>
                        ))}
                      </MenuOptionGroup>
                    </MenuList>
                  </Menu>
                  <Menu closeOnSelect={false} placement="bottom-end">
                    <MenuButton
                      as={Button}
                      size="sm"
                      leftIcon={<Icon as={UilSortAmountDown} h="5" w="5" />}
                      colorScheme="orange"
                      variant="ghost"
                      ml="auto"
                    >
                      Sort
                    </MenuButton>
                    <MenuList>
                      <MenuOptionGroup
                        title="Sort Moments"
                        value={momentSort.toString()}
                        defaultValue={momentSort.toString()}
                        onChange={setMomentSort}
                        type="radio"
                      >
                        {momentSortOptions.map((so, idx) => (
                          <MenuItemOption
                            key={idx}
                            fontSize="sm"
                            value={idx.toString()}
                          >
                            {so.label}
                          </MenuItemOption>
                        ))}
                      </MenuOptionGroup>
                    </MenuList>
                  </Menu>
                </HStack>
                <Stack spacing="2">
                  {!loadingCareerMoments && careerMoments ? (
                    orderBy(
                      Object.values(careerMoments),
                      [
                        (cm) =>
                          momentSortOptions[momentSort].value === 'title'
                            ? cm.title.toLowerCase()
                            : cm[momentSortOptions[momentSort].value],
                      ],
                      [momentSortOptions[momentSort].direction]
                    )
                      .filter((cm) =>
                        user.type !== userType.USER
                          ? cm.status !== cmStatusType.DRAFT
                          : true
                      )
                      .filter((cm) =>
                        momentFilterOptions[momentFilter].value === null
                          ? true
                          : cm.computedStatus ===
                            momentFilterOptions[momentFilter].value
                      )
                      .map((cm, idx) => (
                        <Box
                          key={idx}
                          bg="white"
                          rounded="md"
                          p="4"
                          borderWidth="1px"
                          position="relative"
                          transition="all 0.2s ease"
                          _hover={{
                            transform: 'translateY(-3px)',
                            boxShadow: 'md',
                          }}
                        >
                          <LinkBox zIndex="0">
                            <Box>
                              <Box
                                flexGrow="1"
                                pl="10"
                                isTruncated
                                whiteSpace={{ base: 'normal', lg: 'nowrap' }}
                              >
                                <Box
                                  d={{ base: 'block', lg: 'flex' }}
                                  overflow="hidden"
                                >
                                  {cm.challenges && cm.challenges.length > 0 && (
                                    <Box
                                      order={{ lg: 2 }}
                                      ml={{ lg: 'auto' }}
                                      mb={{ lg: '0' }}
                                    >
                                      <Stack direction="row" spacing="2">
                                        {cm.challenges[0].name && (
                                          <Tag
                                            size="sm"
                                            borderRadius="full"
                                            variant="subtle"
                                            colorScheme="orange"
                                          >
                                            {cm.challenges[0].name}
                                          </Tag>
                                        )}
                                        {cm.challenges[0].type && (
                                          <Tag
                                            size="sm"
                                            borderRadius="full"
                                            variant="subtle"
                                            colorScheme="orange"
                                          >
                                            {cm.challenges[0].type}
                                          </Tag>
                                        )}
                                      </Stack>
                                    </Box>
                                  )}
                                  <LinkOverlay
                                    as={ReactRouterLink}
                                    to={
                                      user.type !== userType.USER
                                        ? `/users/${cm.userId}/career-moments/${cm.id}`
                                        : `/career-moments/${cm.id}`
                                    }
                                    fontFamily="tisa"
                                    fontWeight="semibold"
                                    fontSize="lg"
                                    mr="2"
                                    isTruncated
                                    whiteSpace={{
                                      base: 'normal',
                                      lg: 'nowrap',
                                    }}
                                  >
                                    {cm.title}
                                  </LinkOverlay>
                                </Box>
                                <Text fontSize="sm" color="warmGray.600">
                                  {cm.company}
                                  <Text as="span" px="1">
                                    •
                                  </Text>
                                  {cm.role}
                                </Text>
                              </Box>
                              {!isFreeUser(viewingUser) &&
                              cm.careerStories &&
                              cm.careerStories.length ? (
                                <Box>
                                  <Center ml="auto">
                                    <Box ml="auto">
                                      <Icon
                                        color="orange.500"
                                        as={UilLink}
                                        mr={1}
                                      />
                                      <Button
                                        as={ReactRouterLink}
                                        to={`/users/${cm.userId}/career-moments/${cm.id}`}
                                        fontFamily="tisa"
                                        fontWeight="semibold"
                                        fontSize="sm"
                                        isTruncated
                                        variant="link"
                                        colorScheme="orange"
                                        whiteSpace={{
                                          base: 'normal',
                                          lg: 'nowrap',
                                        }}
                                      >
                                        View Linked Stories
                                      </Button>
                                    </Box>
                                  </Center>
                                </Box>
                              ) : null}
                            </Box>
                          </LinkBox>
                          <Box position="absolute" top="4" left="4">
                            <MomentStatusIndicator content={cm} />
                          </Box>
                        </Box>
                      ))
                  ) : (
                    <StackLoader />
                  )}
                </Stack>
              </TabPanel>
              <TabPanel>
                {viewingUser && isFreeUser(viewingUser) ? (
                  <Overlay
                    h="56"
                    rounded="md"
                    overflow="hidden"
                    content={
                      <Flex
                        h="100%"
                        w="100%"
                        py="3"
                        px={{ base: '5', lg: '16' }}
                        align="center"
                        justify="center"
                        textAlign="center"
                      >
                        <Box>
                          <Icon
                            color="orange.500"
                            boxSize="8"
                            as={UilPadlock}
                            mb="3"
                          />
                          <Heading
                            fontSize="xl"
                            mb="3"
                            fontWeight="semibold"
                            color="amberGray.900"
                            lineHeight="1.4"
                          >
                            Become a monthly or annual subscriber and get a
                            personal Career Coach to help you start writing your
                            Career Story.
                          </Heading>
                          <Button
                            colorScheme="orange"
                            variant="link"
                            onClick={() =>
                              openModalHandler(
                                <SubscriptionModal
                                  closeModalHandler={modalState.onClose}
                                />
                              )
                            }
                          >
                            Subscribe Now
                          </Button>
                        </Box>
                      </Flex>
                    }
                  />
                ) : (
                  <>
                    <HStack mb="2">
                      <Menu closeOnSelect={false} placement="bottom-end">
                        <MenuButton
                          as={Button}
                          size="sm"
                          leftIcon={<Icon as={UilFilter} h="5" w="5" />}
                          colorScheme="orange"
                          variant="ghost"
                          ml="auto"
                        >
                          Filter
                        </MenuButton>
                        <MenuList minWidth="240px" title="Filter by Status">
                          <MenuOptionGroup
                            title="Filter by Status"
                            value={storyFilter.toString()}
                            defaultValue={storyFilter.toString()}
                            onChange={setStoryFilter}
                            type="radio"
                          >
                            {storyFilterOptions.map((fo, idx) => (
                              <MenuItemOption
                                key={idx}
                                fontSize="sm"
                                value={idx.toString()}
                              >
                                {fo.label}
                              </MenuItemOption>
                            ))}
                          </MenuOptionGroup>
                        </MenuList>
                      </Menu>
                      <Menu closeOnSelect={false} placement="bottom-end">
                        <MenuButton
                          as={Button}
                          size="sm"
                          leftIcon={<Icon as={UilSortAmountDown} h="5" w="5" />}
                          colorScheme="orange"
                          variant="ghost"
                          ml="auto"
                        >
                          Sort
                        </MenuButton>
                        <MenuList>
                          <MenuOptionGroup
                            title="Sort Stories"
                            value={storySort.toString()}
                            defaultValue={storySort.toString()}
                            onChange={setStorySort}
                            type="radio"
                          >
                            {storySortOptions.map((so, idx) => (
                              <MenuItemOption
                                key={idx}
                                fontSize="sm"
                                value={idx.toString()}
                              >
                                {so.label}
                              </MenuItemOption>
                            ))}
                          </MenuOptionGroup>
                        </MenuList>
                      </Menu>
                    </HStack>
                    <Stack spacing="2">
                      {!loadingCareerStories && careerStories ? (
                        orderBy(
                          Object.values(careerStories),
                          [
                            (cs) =>
                              storySortOptions[storySort].value === 'title'
                                ? cs.title.toLowerCase()
                                : storyStatusSortOrder[
                                    cs[storySortOptions[storySort].value]
                                  ],
                          ],
                          [storySortOptions[storySort].direction]
                        )
                          .filter((cs) =>
                            user.type === userType.USER
                              ? cs.status !== csStatusType.DRAFT
                              : true
                          )
                          .filter((cs) =>
                            storyFilterOptions[storyFilter].value === null
                              ? true
                              : cs.computedStatus ===
                                storyFilterOptions[storyFilter].value
                          )
                          .map((cs, idx) => (
                            <Box
                              key={idx}
                              as="button"
                              bg="white"
                              rounded="md"
                              textAlign="unset"
                              p="4"
                              borderWidth="1px"
                              position="relative"
                              transition="all 0.2s ease"
                              _hover={{
                                transform: 'translateY(-3px)',
                                boxShadow: 'md',
                              }}
                              onClick={() => {
                                user.type !== userType.USER
                                  ? history.push(
                                      `/users/${cs.userId}/career-moments/${cs.careerMomentId}#${cs.id}`
                                    )
                                  : openModalHandler(
                                      <CareerStoryModal
                                        careerStoryId={cs.id}
                                        closeModalHandler={modalState.onClose}
                                      />
                                    )
                              }}
                            >
                              <Box>
                                <Flex>
                                  <Box
                                    flexGrow="1"
                                    pl="10"
                                    isTruncated
                                    whiteSpace={{
                                      base: 'normal',
                                      lg: 'nowrap',
                                    }}
                                  >
                                    <Flex
                                      direction={{ base: 'column', lg: 'row' }}
                                      overflow="hidden"
                                    >
                                      {(cs.skill || cs.type) && (
                                        <Box
                                          order={{ lg: 2 }}
                                          ml={{ lg: 'auto' }}
                                          mb={{ lg: '0' }}
                                        >
                                          <Stack direction="row" spacing="2">
                                            {cs.skill && (
                                              <Tag
                                                size="sm"
                                                borderRadius="full"
                                                variant="subtle"
                                                colorScheme="orange"
                                              >
                                                {cs.skill}
                                              </Tag>
                                            )}
                                            {cs.type && (
                                              <Tag
                                                size="sm"
                                                borderRadius="full"
                                                variant="subtle"
                                                colorScheme="orange"
                                              >
                                                {cs.type}
                                              </Tag>
                                            )}
                                          </Stack>
                                        </Box>
                                      )}
                                      <Text
                                        fontFamily="tisa"
                                        fontWeight="semibold"
                                        fontSize="lg"
                                        mr="2"
                                        isTruncated
                                        whiteSpace={{
                                          base: 'normal',
                                          lg: 'nowrap',
                                        }}
                                      >
                                        {cs.title}
                                      </Text>
                                    </Flex>
                                    <Text fontSize="sm" color="warmGray.600">
                                      {cs.content}
                                    </Text>
                                    {user.type === userType.USER && (
                                      <Box>
                                        <Center ml="auto">
                                          <Box ml="auto">
                                            <Icon
                                              color="orange.500"
                                              as={UilLink}
                                              mr={1}
                                            />
                                            <Button
                                              as={ReactRouterLink}
                                              to={`/users/${cs.userId}/career-moments/${cs.careerMomentId}#${cs.id}`}
                                              fontFamily="tisa"
                                              fontWeight="semibold"
                                              fontSize="sm"
                                              variant="link"
                                              isTruncated
                                              colorScheme="orange"
                                              whiteSpace={{
                                                base: 'normal',
                                                lg: 'nowrap',
                                              }}
                                            >
                                              View Linked Moment
                                            </Button>
                                          </Box>
                                        </Center>
                                      </Box>
                                    )}
                                  </Box>
                                </Flex>
                              </Box>
                              <Box position="absolute" top="4" left="4">
                                <StoryStatusIndicator content={cs} />
                              </Box>
                            </Box>
                          ))
                      ) : (
                        <StackLoader />
                      )}
                    </Stack>
                  </>
                )}
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
      </Box>
      <Modal size="xl" isOpen={modalState.isOpen} onClose={modalState.onClose}>
        <ModalOverlay />
        <ModalContent>{modalType}</ModalContent>
      </Modal>
    </Box>
  )
}

const CareerCoach = () => {
  const user = useSelector((state) => state.user.data)
  const { userId } = useParams()
  const viewingUser = useSelector((state) =>
    userId ? state.users.users[userId] : state.user.data
  )
  const careerCoach =
    viewingUser.recipients &&
    viewingUser.recipients.length &&
    viewingUser.recipients[0]

  const modalState = useDisclosure()
  const [modalType, setModalType] = useState(null)

  const openModalHandler = (type) => {
    setModalType(type)
    modalState.onOpen()
  }

  return (
    <>
      <Box boxShadow="base" mb="6" bg="white" rounded="md" overflow="hidden">
        <Box>
          <Overlay
            show={viewingUser && isFreeUser(viewingUser)}
            content={
              <Flex
                h="100%"
                w="100%"
                py="3"
                px="6"
                align="center"
                justify="center"
                textAlign="center"
              >
                <Box>
                  <Icon color="orange.500" boxSize="8" as={UilPadlock} mb="2" />
                  <Heading
                    fontSize="xl"
                    mb="2"
                    fontWeight="semibold"
                    color="amberGray.900"
                  >
                    Become a premium subscriber and get a personal Career Coach.
                  </Heading>
                  {user && user.type === userType.USER && (
                    <Button
                      colorScheme="orange"
                      variant="link"
                      onClick={() =>
                        openModalHandler(
                          <SubscriptionModal
                            closeModalHandler={modalState.onClose}
                          />
                        )
                      }
                    >
                      Subscribe Now
                    </Button>
                  )}
                </Box>
              </Flex>
            }
          >
            <Flex mb="4" pt="4" px="6" alignItems="center">
              <Icon as={UilUserCircle} color="teal.600" mr="2" w="6" h="6" />
              <Heading as="h2" size="md" fontWeight="semibold">
                Career Coach
              </Heading>
            </Flex>
            <Box bg="amberGray.50" p="6" borderTopWidth="1px">
              <Flex>
                {viewingUser && isFreeUser(viewingUser) ? (
                  <>
                    <Avatar mr="4" bg="teal.500" />
                    <Box>
                      <Text fontFamily="tisa" fontWeight="semibold">
                        Your Coach
                      </Text>
                      <Text
                        fontSize="sm"
                        fontWeight="medium"
                        color="amberGray.700"
                      >
                        Coach
                      </Text>
                    </Box>
                  </>
                ) : careerCoach ? (
                  <>
                    <Avatar
                      name={`${careerCoach.firstName} ${careerCoach.lastName}`}
                      mr="4"
                      bg="teal.500"
                    />
                    <Box>
                      <Text
                        fontFamily="tisa"
                        fontWeight="semibold"
                      >{`${careerCoach.firstName} ${careerCoach.lastName}`}</Text>
                      <Text
                        fontSize="sm"
                        fontWeight="medium"
                        color="amberGray.700"
                      >
                        {careerCoach.type}
                      </Text>
                    </Box>
                  </>
                ) : (
                  <Text
                    fontSize="sm"
                    fontWeight="medium"
                    color="amberGray.700"
                    fontStyle="italic"
                  >
                    Waiting for your coach to be assigned
                  </Text>
                )}
              </Flex>
            </Box>
          </Overlay>
        </Box>
      </Box>
      <Modal size="xl" isOpen={modalState.isOpen} onClose={modalState.onClose}>
        <ModalOverlay />
        <ModalContent>{modalType}</ModalContent>
      </Modal>
    </>
  )
}

const JobListings = ({
  openModalHandler,
  modalState,
  jobsListings,
  isLoading,
}) => {
  const dispatch = useDispatch()
  const [activePagination, setActivePagination] = useState(5)
  const [archivePagination, setArchivePagination] = useState(5)
  const [orange600] = useToken('colors', ['orange.600'])
  const paginationStep = 5

  const openHandler = useCallback(
    (jobsListing = null) => {
      openModalHandler(
        <JobListingModal
          closeModalHandler={modalState.onClose}
          jobsListing={jobsListing}
        />
      )
    },
    [modalState, openModalHandler]
  )

  const handleArchive = async (jobsListing, doArchive = false) => {
    try {
      await dispatch(
        updateJobsListing(jobsListing.id, {
          ...jobsListing,
          status: doArchive ? jlStatusType.ARCHIVED : jlStatusType.ACTIVATED,
        })
      )
    } catch (error) {
      alert(error)
    }
  }

  const activeJobListings = useMemo(() => {
    return jobsListings
      ? Object.values(jobsListings)
          .filter((jl) => jl.status !== jlStatusType.ARCHIVED)
          .sort(
            (a, b) => sortFormatDate(b.createdAt) - sortFormatDate(a.createdAt)
          )
      : []
  }, [jobsListings])

  const archiveJobListings = useMemo(() => {
    return jobsListings
      ? Object.values(jobsListings)
          .filter((jl) => jl.status === jlStatusType.ARCHIVED)
          .sort(
            (a, b) => sortFormatDate(b.createdAt) - sortFormatDate(a.createdAt)
          )
      : []
  }, [jobsListings])

  return (
    <Box boxShadow="base" mb="6" bg="white" rounded="md" overflow="hidden">
      <Flex py="4" px="6" align="center">
        <Icon as={UilClipboardNotes} color="green.600" mr="2" w="6" h="6" />
        <Heading as="h2" size="md" fontWeight="semibold">
          Job Listings
        </Heading>
        <Box ml="auto">
          <Button
            d={{ base: 'none', lg: 'block' }}
            size="sm"
            onClick={() => openHandler()}
            colorScheme="orange"
            variant="outline"
          >
            <Text>Add Listing</Text>
          </Button>
          <IconButton
            aria-label="Add Listing"
            d={{ lg: 'none' }}
            icon={<Icon boxSize="6" as={UilPlus} />}
            onClick={() => openHandler()}
            colorScheme="orange"
            variant="outline"
          />
        </Box>
      </Flex>
      <Box bg="amberGray.50" borderTopWidth="1px">
        {!isLoading && jobsListings ? (
          <Tabs colorScheme="orange">
            <TabList px="6" pt="2" borderBottomWidth="1px">
              {[{ label: 'Active' }, { label: 'Archived' }].map((tab, idx) => (
                <Tab
                  key={idx}
                  fontWeight="semibold"
                  color="amberGray.700"
                  _selected={{
                    color: 'black',
                    boxShadow: `inset 0 -3px ${orange600}`,
                  }}
                >
                  {tab.label}
                </Tab>
              ))}
            </TabList>
            <TabPanels>
              <TabPanel pb="0" px="0">
                <Stack spacing="6" pb="4">
                  {activeJobListings
                    .slice(0, activePagination)
                    .map((jl, idx) => (
                      <Box key={idx} px="6">
                        <Flex>
                          <Link
                            href={jl.link}
                            color="orange.600"
                            fontFamily="tisa"
                            fontWeight="semibold"
                            fontSize="lg"
                            d="flex"
                            alignItems="center"
                            isExternal
                          >
                            {jl.companyName}
                            <Icon ml="2" as={UilExternalLinkAlt} />
                          </Link>
                          <Menu placement="bottom-end">
                            <MenuButton
                              as={IconButton}
                              aria-label="Modify Job Listing"
                              variant="ghost"
                              colorScheme="orange"
                              size="sm"
                              ml="auto"
                              icon={<Icon boxSize="5" as={UilEllipsisV} />}
                            />
                            <MenuList>
                              <MenuItem onClick={() => openHandler(jl)}>
                                Edit
                              </MenuItem>
                              <MenuItem onClick={() => handleArchive(jl, true)}>
                                Archive
                              </MenuItem>
                            </MenuList>
                          </Menu>
                        </Flex>
                        <Text mb="1">{jl.title}</Text>
                        <Text
                          fontSize="sm"
                          fontWeight="medium"
                          color="amberGray.700"
                        >
                          {formatDate(jl.createdAt, 'MMM D, YYYY')}
                        </Text>
                      </Box>
                    ))}
                </Stack>
                {activeJobListings.length - activePagination > 0 && (
                  <Box borderTopWidth="1px" py="2" px="6">
                    <Button
                      colorScheme="orange"
                      variant="ghost"
                      w="100%"
                      onClick={() =>
                        setActivePagination(activePagination + paginationStep)
                      }
                    >
                      Show{' '}
                      {activeJobListings.length - activePagination >=
                      paginationStep
                        ? paginationStep
                        : activeJobListings.length - activePagination}{' '}
                      More
                    </Button>
                  </Box>
                )}
              </TabPanel>
              <TabPanel pb="0" px="0">
                <Stack spacing="6" pb="4">
                  {archiveJobListings
                    .slice(0, archivePagination)
                    .map((jl, idx) => (
                      <Box key={idx} px="6">
                        <Flex>
                          <Link
                            href={jl.link}
                            isExternal
                            color="orange.600"
                            fontFamily="tisa"
                            fontWeight="semibold"
                            fontSize="lg"
                            d="flex"
                            alignItems="center"
                          >
                            {jl.companyName}
                            <Icon ml="2" as={UilExternalLinkAlt} />
                          </Link>
                          <Menu placement="bottom-end">
                            <MenuButton
                              as={IconButton}
                              aria-label="Modify Job Listing"
                              variant="ghost"
                              colorScheme="orange"
                              size="sm"
                              ml="auto"
                              icon={<Icon boxSize="5" as={UilEllipsisV} />}
                            />
                            <MenuList>
                              <MenuItem onClick={() => openHandler(jl)}>
                                Edit
                              </MenuItem>
                              <MenuItem onClick={() => handleArchive(jl)}>
                                Un-Archive
                              </MenuItem>
                            </MenuList>
                          </Menu>
                        </Flex>
                        <Text mb="1">{jl.title}</Text>
                        <Text
                          fontSize="sm"
                          fontWeight="medium"
                          color="amberGray.700"
                        >
                          {formatDate(jl.createdAt, 'MMM D, YYYY')}
                        </Text>
                      </Box>
                    ))}
                </Stack>
                {archiveJobListings.length - archivePagination > 0 && (
                  <Box borderTopWidth="1px" py="2" px="6">
                    <Button
                      colorScheme="orange"
                      variant="ghost"
                      w="100%"
                      onClick={() =>
                        setArchivePagination(archivePagination + paginationStep)
                      }
                    >
                      Show{' '}
                      {archiveJobListings.length - archivePagination >=
                      paginationStep
                        ? paginationStep
                        : archiveJobListings.length - archivePagination}{' '}
                      More
                    </Button>
                  </Box>
                )}
              </TabPanel>
            </TabPanels>
          </Tabs>
        ) : (
          <Box p="4">
            <SmallStackLoader />
          </Box>
        )}
      </Box>
    </Box>
  )
}

const CareerMaterials = ({
  openModalHandler,
  modalState,
  careerMaterials,
  isLoading,
}) => {
  const dispatch = useDispatch()
  const [activePagination, setActivePagination] = useState(5)
  const [archivePagination, setArchivePagination] = useState(5)
  const paginationStep = 5
  const [orange600] = useToken('colors', ['orange.600'])

  const openHandler = useCallback(
    (careerMaterial = null) => {
      openModalHandler(
        <CareerMaterialsModal
          closeModalHandler={modalState.onClose}
          careerMaterial={careerMaterial}
        />
      )
    },
    [modalState, openModalHandler]
  )
  const handleArchive = async (material, doArchive = false) => {
    try {
      await dispatch(
        archivingCareerMaterial(material.id, {
          ...material,
          status: doArchive ? mStatusType.ARCHIVED : mStatusType.ACTIVATED,
        })
      )
    } catch (error) {
      alert(error)
    }
  }
  const sortedCareerMaterials = useMemo(() => {
    return careerMaterials
      ? Object.values(careerMaterials)
          .filter((cm) => cm.status !== mStatusType.ARCHIVED)
          .sort(
            (a, b) => sortFormatDate(b.createdAt) - sortFormatDate(a.createdAt)
          )
      : []
  }, [careerMaterials])

  const archiveCareerMaterials = useMemo(() => {
    return careerMaterials
      ? Object.values(careerMaterials)
          .filter((cm) => cm.status === mStatusType.ARCHIVED)
          .sort(
            (a, b) => sortFormatDate(b.createdAt) - sortFormatDate(a.createdAt)
          )
      : []
  }, [careerMaterials])

  return (
    <Box boxShadow="base" bg="white" rounded="md" overflow="hidden">
      <Flex py="4" px="6" align="center">
        <Icon as={UilFileAlt} color="orange.600" mr="2" w="6" h="6" />
        <Heading as="h2" size="md" fontWeight="semibold">
          Career Materials
        </Heading>
        <Box ml="auto">
          <Button
            d={{ base: 'none', lg: 'block' }}
            size="sm"
            onClick={() => openHandler()}
            colorScheme="orange"
            variant="outline"
          >
            <Text>Add Material</Text>
          </Button>
          <IconButton
            aria-label="Add Material"
            d={{ lg: 'none' }}
            icon={<Icon boxSize="6" as={UilPlus} />}
            onClick={() => openHandler()}
            colorScheme="orange"
            variant="outline"
          />
        </Box>
      </Flex>
      <Box bg="amberGray.50" borderTopWidth="1px">
        {!isLoading && careerMaterials ? (
          <Tabs colorScheme="orange">
            <TabList px="6" pt="2" borderBottomWidth="1px">
              {[{ label: 'Active' }, { label: 'Archived' }].map((tab, idx) => (
                <Tab
                  key={idx}
                  fontWeight="semibold"
                  color="amberGray.700"
                  _selected={{
                    color: 'black',
                    boxShadow: `inset 0 -3px ${orange600}`,
                  }}
                >
                  {tab.label}
                </Tab>
              ))}
            </TabList>
            <TabPanels>
              <TabPanel pb="0" px="0">
                <Stack spacing="6" pb="4">
                  {sortedCareerMaterials
                    .slice(0, activePagination)
                    .map((material, idx) => (
                      <Box key={idx} px="6">
                        <Flex>
                          <Link
                            href={material.link || buildUrl(material.s3link)}
                            color="orange.600"
                            fontFamily="tisa"
                            fontWeight="semibold"
                            fontSize="lg"
                            d="flex"
                            alignItems="center"
                            isExternal
                          >
                            {material.name}
                            <Icon ml="2" as={UilExternalLinkAlt} />
                          </Link>
                          <Menu placement="bottom-end">
                            <MenuButton
                              as={IconButton}
                              aria-label="Modify Job Listing"
                              variant="ghost"
                              colorScheme="orange"
                              size="sm"
                              ml="auto"
                              icon={<Icon boxSize="5" as={UilEllipsisV} />}
                            />
                            <MenuList>
                              <MenuItem onClick={() => openHandler(material)}>
                                Edit
                              </MenuItem>
                              <MenuItem
                                onClick={() => handleArchive(material, true)}
                              >
                                Archive
                              </MenuItem>
                            </MenuList>
                          </Menu>
                        </Flex>
                        <Text mb="1">{material.description}</Text>
                        <Text
                          fontSize="sm"
                          fontWeight="medium"
                          color="amberGray.700"
                        >
                          {formatDate(material.createdAt, 'MMM D, YYYY')}
                        </Text>
                      </Box>
                    ))}
                </Stack>
                {sortedCareerMaterials.length - activePagination > 0 && (
                  <Box borderTopWidth="1px" py="2" px="6">
                    <Button
                      colorScheme="orange"
                      variant="ghost"
                      w="100%"
                      onClick={() =>
                        setActivePagination(activePagination + paginationStep)
                      }
                    >
                      Show{' '}
                      {sortedCareerMaterials.length - activePagination >=
                      paginationStep
                        ? paginationStep
                        : sortedCareerMaterials.length - activePagination}{' '}
                      More
                    </Button>
                  </Box>
                )}
              </TabPanel>
              <TabPanel pb="0" px="0">
                <Stack spacing="6" pb="4">
                  {archiveCareerMaterials
                    .slice(0, archivePagination)
                    .map((material, idx) => (
                      <Box key={idx} px="6">
                        <Flex>
                          <Link
                            href={material.link || buildUrl(material.s3link)}
                            isExternal
                            color="orange.600"
                            fontFamily="tisa"
                            fontWeight="semibold"
                            fontSize="lg"
                            d="flex"
                            alignItems="center"
                          >
                            {material.name}
                            <Icon ml="2" as={UilExternalLinkAlt} />
                          </Link>
                          <Menu placement="bottom-end">
                            <MenuButton
                              as={IconButton}
                              aria-label="Modify Job Listing"
                              variant="ghost"
                              colorScheme="orange"
                              size="sm"
                              ml="auto"
                              icon={<Icon boxSize="5" as={UilEllipsisV} />}
                            />
                            <MenuList>
                              <MenuItem onClick={() => openHandler(material)}>
                                Edit
                              </MenuItem>
                              <MenuItem onClick={() => handleArchive(material)}>
                                Un-Archive
                              </MenuItem>
                            </MenuList>
                          </Menu>
                        </Flex>
                        <Text mb="1">{material.description}</Text>
                        <Text
                          fontSize="sm"
                          fontWeight="medium"
                          color="amberGray.700"
                        >
                          {formatDate(material.createdAt, 'MMM D, YYYY')}
                        </Text>
                      </Box>
                    ))}
                </Stack>
                {archiveCareerMaterials.length - archivePagination > 0 && (
                  <Box borderTopWidth="1px" py="2" px="6">
                    <Button
                      colorScheme="orange"
                      variant="ghost"
                      w="100%"
                      onClick={() =>
                        setArchivePagination(archivePagination + paginationStep)
                      }
                    >
                      Show{' '}
                      {archiveCareerMaterials.length - archivePagination >=
                      paginationStep
                        ? paginationStep
                        : archiveCareerMaterials.length -
                          archivePagination}{' '}
                      More
                    </Button>
                  </Box>
                )}
              </TabPanel>
            </TabPanels>
          </Tabs>
        ) : (
          <Box p="4">
            <SmallStackLoader />
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default UserDashboard
