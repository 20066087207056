import {
  setupRequest,
  fetchJSON,
  errorHandler,
  appendParamsToUrl,
} from '../lib/utils'
import { loadUser, putUser } from '../redux/user'

function fetchUser(id) {
  return async (dispatch, getState) => {
    try {
      let state = getState()
      const { url, options } = setupRequest('/users/' + id, 'GET', state)
      let json = await fetchJSON(url, options)
      if (json.description) throw Error(json.description)
      if (json.error) throw Error(json.error)
      if (!json.user) throw Error('API_ERROR_FETCH_USER')
      dispatch(loadUser(json.user))
    } catch (error) {
      errorHandler(error)
      throw error
    }
  }
}

function fetchUserByToken(code, type) {
  return async () => {
    try {
      const path = `/users/token/${code}?${appendParamsToUrl(type)}`
      const { url, options } = setupRequest(path, 'GET')
      let json = await fetchJSON(url, options)
      if (json.description) throw Error(json.description)
      if (json.error) throw Error(json.error)
      if (!json.user) throw Error('API_ERROR_FETCH_USER')
      return json.user
    } catch (error) {
      errorHandler(error)
      throw error
    }
  }
}

function updateUser(id, data) {
  return async (dispatch, getState) => {
    try {
      let state = getState()
      const { url, options } = setupRequest('/users/' + id, 'PUT', state)
      options.body = JSON.stringify(data)
      let json = await fetchJSON(url, options)
      if (json.description) throw Error(json.description)
      if (json.error) throw Error(json.error)
      if (!json.user) throw Error('API_ERROR_UPDATE_USER')
      dispatch(putUser(json.user))
    } catch (error) {
      errorHandler(error)
      throw error
    }
  }
}

export { fetchUser, fetchUserByToken, updateUser }
