import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, Route } from 'react-router-dom'
import { userType } from '../lib/types'
import SuperAdminDashboard from '../pages/SuperAdminDashboard'
import CoachDashboard from '../pages/CoachDashboard'
import AdminAccountSettings from '../pages/AdminAccountSettings'
import Login from '../pages/Login'
import ForgotPassword from '../pages/ForgotPassword'
import ResetPassword from '../pages/ResetPassword'
import Register from '../pages/Register'
import UserDashboard from '../pages/UserDashboard'
import { removeToken } from '../redux/token'
import { removeUser } from '../redux/user'
import { removeCareerMoment } from '../redux/careerMoments'
import { removeCareerStory } from '../redux/careerStories'
import { removeJobsListing } from '../redux/jobsListings'
import { removeClients } from '../redux/admin/users'
import { removeRegistration } from '../redux/registration'
import { removePayments } from '../redux/payments'

const GuardedRoute = ({ component: Component, ...rest }) => {
  const dispatch = useDispatch()
  const token = useSelector((state) => state.token.accessToken)
  const user = useSelector((state) => state.user.data)

  const renderHandler = (props) => {
    if (rest.path === '/logout') {
      dispatch(removeToken())
      dispatch(removeUser())
      dispatch(removeCareerMoment())
      dispatch(removeCareerStory())
      dispatch(removeJobsListing())
      dispatch(removeClients())
      dispatch(removeRegistration())
      dispatch(removePayments())
      return <Redirect to="/login" />
    }

    if ((!user || !token) && rest.path === '/forgot-password')
      return <ForgotPassword {...props} />
    if ((!user || !token) && rest.path === '/reset-password')
      return <ResetPassword {...props} />

    if ((!user || !token) && rest.path === '/register')
      return <Register {...props} />

    if (!user || !token) return <Login {...props} />

    if (
      (user.subscriptionTier || user.type !== userType.USER) &&
      (rest.path === '/onboarding/1' ||
        rest.path === '/onboarding/2' ||
        rest.path === '/onboarding/confirm')
    ) {
      return <Redirect to="/dashboard" />
    }

    if (rest.path === '/') return <Redirect to="/dashboard" />

    const isAuth =
      rest.path === '/login' ||
      rest.path === '/forgot-password' ||
      rest.path === '/reset-password' ||
      rest.path === '/register'

    if (isAuth)
      return (
        <Redirect to={{ pathname: '/', state: { from: props.location } }} />
      )

    if (
      user.type === userType.SUPER &&
      (rest.path === '/' || rest.path === '/dashboard')
    )
      return <SuperAdminDashboard {...props} />
    if (
      user.type === userType.ADMIN &&
      (rest.path === '/' || rest.path === '/dashboard')
    )
      return <CoachDashboard {...props} />

    if (user.type !== userType.USER && rest.path === '/account')
      return <AdminAccountSettings />

    if (
      user.type !== userType.USER &&
      rest.path === ('/career-moments' || '/career-moments/:careerMomentId')
    )
      return <Redirect to="/dashboard" />

    if (user.type !== userType.USER && rest.path === '/users/:userId')
      return <UserDashboard />

    if (user.type === userType.USER && rest.path === '/users/:userId')
      return <Redirect to="/dashboard" />

    // if (
    //   user.type === userType.USER &&
    //   rest.path === '/users/:userId/career-moments/:careerMomentId'
    // )
    //   return <Redirect to="/dashboard" />

    if (
      user.type === userType.USER &&
      !user.subscriptionTier &&
      rest.path === '/dashboard'
    ) {
      if (!(rest.location && rest.location.search === '?skip=true')) {
        return <Redirect to="/onboarding/1" />
      }
    }

    if (
      user.type !== userType.SUPER &&
      (rest.path === '/app-settings' ||
        rest.path === 'dashboard/admins' ||
        rest.path === '/coaches/:userId' ||
        rest.path === '/coaches/:userId/account')
    )
      return <Redirect to="/dashboard" />

    return <Component {...props} />
  }

  return <Route {...rest} render={renderHandler} />
}

export default GuardedRoute
