import React, { useCallback, useEffect, useState } from 'react'
import { Link as ReactRouterLink } from 'react-router-dom'
import {
  Box,
  Flex,
  Tag,
  Text,
  VStack,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  LinkBox,
  LinkOverlay,
  Stack,
} from '@chakra-ui/react'
import { useDispatch, useSelector } from 'react-redux'
import { formatDate } from '../../lib/utils'
import { fetchCareerMoments } from '../../api/careerMoments'
import { useParams } from 'react-router-dom'
import { cmStatusType } from '../../lib/types'

const MomentsDrawer = ({ isDrawerOpen, onDrawerClose }) => {
  const { userId, careerMomentId } = useParams()
  const dispatch = useDispatch()

  const [loadingCareerMoments, setLoadingCareerMoments] = useState(true)

  const getCareerMoments = useCallback(async () => {
    try {
      setLoadingCareerMoments(true)
      await dispatch(fetchCareerMoments(userId))
      setLoadingCareerMoments(false)
    } catch (error) {
      setLoadingCareerMoments(false)
      alert(error)
    }
  }, [dispatch, userId])

  const careerMoments = useSelector(
    (state) =>
      (state.careerMoments.data && Object.values(state.careerMoments.data)) ||
      []
  )

  useEffect(() => {
    !careerMoments && getCareerMoments()
    setLoadingCareerMoments(false)
  }, [careerMoments, getCareerMoments])

  return (
    <Drawer isOpen={isDrawerOpen} placement="left" onClose={onDrawerClose}>
      <DrawerOverlay>
        <DrawerContent bg="amberGray.75">
          <DrawerCloseButton />
          <DrawerHeader>Career Moments</DrawerHeader>
          <DrawerBody>
            <VStack align="stretch">
              {loadingCareerMoments ? (
                <Box>Loading</Box>
              ) : (
                <>
                  {careerMoments &&
                    careerMoments
                      .filter((cm) => cm.status !== cmStatusType.DRAFT)
                      .map((cm, idx) => (
                        <LinkBox
                          key={idx}
                          transition="all 0.2s ease"
                          boxShadow="base"
                          bg="white"
                          borderRadius="md"
                          p="2"
                          sx={
                            cm.id === Number(careerMomentId)
                              ? {
                                  borderColor: 'orange.600',
                                  borderWidth: '2px',
                                }
                              : null
                          }
                          _hover={{
                            transform: 'translateY(-3px)',
                            boxShadow: 'lg',
                          }}
                        >
                          <LinkOverlay
                            onClick={onDrawerClose}
                            as={ReactRouterLink}
                            to={`/users/${userId}/career-moments/${cm.id}`}
                          >
                            <Text as="span" fontWeight="semibold">
                              {cm.title}
                            </Text>
                          </LinkOverlay>
                          <Text
                            color="warmGray.500"
                            fontSize="sm"
                            fontWeight="medium"
                            mb="2"
                          >
                            {cm.role} • {cm.company}
                          </Text>
                          <Flex alignItems="center">
                            {cm.challenges && cm.challenges.length > 0 && (
                              <Stack direction="row" spacing="2">
                                {cm.challenges[0].name && (
                                  <Tag
                                    size="sm"
                                    borderRadius="full"
                                    variant="subtle"
                                    colorScheme="orange"
                                  >
                                    {cm.challenges[0].name}
                                  </Tag>
                                )}
                                {cm.challenges[0].type && (
                                  <Tag
                                    size="sm"
                                    borderRadius="full"
                                    variant="subtle"
                                    colorScheme="orange"
                                  >
                                    {cm.challenges[0].type}
                                  </Tag>
                                )}
                              </Stack>
                            )}
                            <Text
                              fontSize="xs"
                              as="span"
                              ml="auto"
                              fontWeight="medium"
                              color="warmGray.500"
                            >
                              {formatDate(cm.createdAt, 'MMM. DD, YYYY')}
                            </Text>
                          </Flex>
                        </LinkBox>
                      ))}
                </>
              )}
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  )
}

export default MomentsDrawer
