import React, { useState } from 'react'
import { Link as ReactRouterLink } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { login } from '../api/auth'
import {
  Input,
  Button,
  Box,
  Grid,
  GridItem,
  Flex,
  FormControl,
  FormLabel,
  Link,
  Heading,
} from '@chakra-ui/react'
import Container from '../components/common/Container'

const Login = () => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const loginHandler = async (e) => {
    e.preventDefault()
    try {
      setLoading(true)
      await dispatch(login({ email, password }))
    } catch (error) {
      setLoading(false)
      alert(error)
    }
  }

  return (
    <Container d="flex" h="100%">
      <Grid templateColumns={{ md: 'repeat(12, 1fr)' }} w="100%">
        <GridItem
          colStart={{ md: '3', lg: '4' }}
          colSpan={{ md: '8', lg: '6' }}
          d="flex"
          alignItems="center"
        >
          <Box boxShadow="base" p="6" mb="6" bg="white" w="100%" rounded="md">
            <form onSubmit={loginHandler}>
              <Box mb="4">
                <Heading as="h1" fontSize="3xl">
                  Login
                </Heading>
              </Box>
              <Grid gap="4">
                <GridItem>
                  <FormControl id="email">
                    <FormLabel>Email</FormLabel>
                    <Input
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      autoComplete="email"
                    />
                  </FormControl>
                </GridItem>
                <GridItem mb="4">
                  <FormControl id="password">
                    <FormLabel>Password</FormLabel>
                    <Input
                      type="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      autoComplete="current-password"
                    />
                  </FormControl>
                </GridItem>
                <GridItem>
                  <Flex
                    direction={{ base: 'column', md: 'row' }}
                    align="center"
                  >
                    <Flex align="center" mb={{ base: '8', md: '0' }}>
                      <Button
                        type="submit"
                        isLoading={loading}
                        loadingText="Submitting"
                        colorScheme="orange"
                      >
                        Login
                      </Button>
                      <Link
                        as={ReactRouterLink}
                        fontWeight="semibold"
                        color="orange.600"
                        to="/register"
                        ml="4"
                      >
                        Register
                      </Link>
                    </Flex>
                    <Box ml={{ base: '0', md: 'auto' }}>
                      <Link
                        as={ReactRouterLink}
                        fontWeight="semibold"
                        color="orange.600"
                        to="/forgot-password"
                      >
                        Forgot Your Password?
                      </Link>
                    </Box>
                  </Flex>
                </GridItem>
              </Grid>
            </form>
          </Box>
        </GridItem>
      </Grid>
    </Container>
  )
}

export default Login
