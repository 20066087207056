/* eslint-disable no-sequences */
// Actions
const LOAD = 'storeItems/LOAD'
const REMOVE = 'storeItems/REMOVE'
const CLEAR_CACHE = 'storeItems/CLEAR_CACHE'

// Reducer
const initialState = {
  data: null,
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD: {
      return {
        ...state,
        data: action.storeItems.reduce((a, b) => ((a[b.id] = b), a), {}),
      }
    }
    case REMOVE:
      return initialState
    case CLEAR_CACHE:
      return initialState
    default:
      return state
  }
}

// Action Creators
export function loadStoreItems(storeItems) {
  return { type: LOAD, storeItems }
}

export function removeStoreItems() {
  return { type: REMOVE }
}
