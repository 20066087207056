import { userType } from '../../lib/types'
import {
  setupRequest,
  fetchJSON,
  appendParamsToUrl,
  errorHandler,
  validateEmail,
} from '../../lib/utils'
import {
  loadAdmins,
  loadUsers,
  inviteClient,
  putAdmin,
  removeUser,
  removeAdmin,
} from '../../redux/admin/users'

function fetchUsers(params) {
  return async (dispatch, getState) => {
    let state = getState()
    try {
      const path = '/users?' + appendParamsToUrl(params)
      const { url, options } = setupRequest(path, 'GET', state)
      let json = await fetchJSON(url, options)
      if (json.description) throw Error(json.description)
      if (json.error) throw Error(json.error)
      if (!json.users) throw Error('API_ERROR_FETCH_USERS')
      params && params.type === userType.ADMIN
        ? dispatch(loadAdmins(json.users))
        : dispatch(loadUsers(json.users))
    } catch (error) {
      errorHandler(error)
      throw error
    }
  }
}

function inviteUser(data) {
  return async (dispatch, getState) => {
    let state = getState()
    try {
      validationUser(data)
      const { url, options } = setupRequest('/users/invite', 'POST', state)
      options.body = JSON.stringify(data)
      let json = await fetchJSON(url, options)
      if (json.description) throw Error(json.description)
      if (json.error) throw Error(json.error)
      if (!json.user) throw Error('API_ERROR_INVITE_USER')
      dispatch(inviteClient(json.user))
    } catch (error) {
      errorHandler(error)
      throw error
    }
  }
}

function reinviteUser(userId) {
  return async (dispatch, getState) => {
    let state = getState()
    try {
      const { url, options } = setupRequest('/users/reinvite', 'POST', state)
      options.body = JSON.stringify({ userId })
      let json = await fetchJSON(url, options)
      if (json.description) throw Error(json.description)
      if (json.error) throw Error(json.error)
      if (!json.success) throw Error('API_ERROR_REINVITE_USER')
    } catch (error) {
      errorHandler(error)
      throw error
    }
  }
}

function deleteUser(id, isAdmin = false) {
  return async (dispatch, getState) => {
    let state = getState()
    try {
      const { url, options } = setupRequest('/users/' + id, 'DELETE', state)
      let json = await fetchJSON(url, options)
      if (json.description) throw Error(json.description)
      if (json.error) throw Error(json.error)
      if (!json.success) throw Error('API_ERROR_REINVITE_USER')
      isAdmin ? dispatch(removeAdmin(id)) : dispatch(removeUser(id))
    } catch (error) {
      errorHandler(error)
      throw error
    }
  }
}

function updateAdmin(id, data) {
  return async (dispatch, getState) => {
    try {
      validationUpdateUser(data)
      let state = getState()
      const { url, options } = setupRequest('/users/' + id, 'PUT', state)
      options.body = JSON.stringify(data)
      let json = await fetchJSON(url, options)
      if (json.description) throw Error(json.description)
      if (json.error) throw Error(json.error)
      if (!json.user) throw Error('API_ERROR_UPDATE_ADMIN')
      dispatch(putAdmin(json.user))
    } catch (error) {
      errorHandler(error)
      throw error
    }
  }
}

function validationUser(data) {
  const errors = []
  if (!data) errors.push('NO_BODY_PROVIDED')
  if (!data.email) errors.push('Please enter an email')
  if (!validateEmail(data.email)) errors.push('Please enter a valid email')
  if (!data.firstName) errors.push('Please enter a First Name')
  if (!data.lastName) errors.push('Please enter a Last Name')
  if (!data.type) errors.push('NO_TYPE_PROVIDED')
  if (errors.length) throw Error(errors.join('\n'))
}

function validationUpdateUser(data) {
  const errors = []
  if (!data) errors.push('NO_BODY_PROVIDED')
  if (!data.email) errors.push('Please enter an email')
  if (!validateEmail(data.email)) errors.push('Please enter a valid email')
  if (!data.firstName) errors.push('Please enter a First Name')
  if (!data.lastName) errors.push('Please enter a Last Name')
  if (errors.length) throw Error(errors.join('\n'))
}

export { fetchUsers, inviteUser, reinviteUser, deleteUser, updateAdmin }
