import {
  decodeToken,
  errorHandler,
  fetchJSON,
  setupRequest,
  validateEmail,
} from '../lib/utils'
import { createToken } from '../redux/token'
import { loadUser } from '../redux/user'
import { fetchUser } from './user'

function login(credentials) {
  return async (dispatch) => {
    try {
      verifyCredentials(credentials)
      const { url, options } = setupRequest('/login', 'POST')
      options.body = JSON.stringify(credentials)
      const json = await fetchJSON(url, options)
      const { error, ok, access_token } = json
      if (!ok || error || !access_token) apiErrorCheck(error)
      await dispatch(createToken(access_token))
      await dispatch(fetchUser(decodeToken(access_token)))
    } catch (error) {
      errorHandler(error)
      throw error
    }
  }
}

async function forgotPassword(email) {
  try {
    const { url, options } = setupRequest('/forgot-password', 'POST')
    options.body = JSON.stringify(email)
    let json = await fetchJSON(url, options)
    if (json.description) throw Error(json.description)
    if (json.error) throw Error(json.error)
    if (!json.success) throw Error('API_ERROR_SEND_CODE')
  } catch (error) {
    errorHandler(error)
    throw error
  }
}

async function resetPassword(data) {
  try {
    const { url, options } = setupRequest('/reset-password', 'POST')
    options.body = JSON.stringify(data)
    let json = await fetchJSON(url, options)
    if (json.description) throw Error(json.description)
    if (json.error) throw Error(json.error)
    if (!json.success) throw Error('API_ERROR_SEND_CODE')
  } catch (error) {
    errorHandler(error)
    throw error
  }
}

function register(data) {
  return async (dispatch) => {
    try {
      verifyInitialRegister(data)
      const { url, options } = setupRequest('/users', 'POST')
      options.body = JSON.stringify(data)
      const res = await fetch(url, options)
      const json = await res.json()
      if (json.description) throw Error(json.description)
      const { error, ok, access_token, user } = json
      if (!ok || error || !access_token || !user) apiErrorCheck(error)
      if (!json.user) throw Error('API_ERROR_REGISTER_USER')
      if (!json.access_token) throw Error('API_ERROR_REGISTER_TOKEN')
      await dispatch(createToken(access_token))
      await dispatch(loadUser(json.user))
    } catch (error) {
      errorHandler(error)
      throw error
    }
  }
}

function completeRegistration(id, data, isPaid = false) {
  return async () => {
    try {
      verifyCompleteRegister(data, isPaid)
      const { url, options } = setupRequest(`/users/complete/${id}`, 'PUT')
      options.body = JSON.stringify(data)
      const res = await fetch(url, options)
      const json = await res.json()
      if (json.description) throw Error(json.description)
      if (!json.user) throw Error('API_ERROR_REGISTER_USER')
      return json.user
    } catch (error) {
      errorHandler(error)
      throw error
    }
  }
}

function apiErrorCheck(error) {
  try {
    if (error === 'need to setup account for this user with email')
      throw Error('This user does not exist.')
    if (error === 'ERROR_USER_LOGIN')
      throw Error('Your credentials are incorrect.')
    if (error) throw Error(error)
  } catch (error) {
    throw error
  }
}

function verifyCredentials(credentials) {
  const { email, password } = credentials
  if (!email && !password) throw Error('Empty Credentials')
  if (!email) throw Error('Empty Email')
  if (!validateEmail(email)) throw Error('Email not valid')
  if (!password) throw Error('Empty Password')
}

function verifyInitialRegister(data) {
  if (!data.email) throw Error('Please enter an email address')
  if (!data.firstName) throw Error('Please enter a First Name')
  if (!data.lastName) throw Error('Please enter a Last Name')
  if (!data.password) throw Error('Please enter a password')
  if (!data.confirmPassword) throw Error('Please confirm password')
  if (!data.phone) throw Error('Please enter a phone number')
  if (data.password !== data.confirmPassword)
    throw Error('Please enter matching passwords')
  if (!validateEmail(data.email))
    throw Error('Please enter a valid email address')
}

function verifyCompleteRegister(data, isPaid = false) {
  if (!data.registrationAnswer1)
    throw Error(
      'Please answer: Why is telling your Career Story most important to you?'
    )
  if (!data.registrationAnswer2)
    throw Error(
      'Please answer: What else is important for us to know about you?'
    )
  if (!data.subscriptionTier) throw Error('Please select a subscription tier')
  if (!data.paymentMethodId && isPaid)
    throw Error('NO_PAYMENT_METHOD_ID_PROVIDED')
}

export { login, register, completeRegistration, forgotPassword, resetPassword }
