// Actions
const LOAD = 'user/LOAD'
const UPDATE_USER = 'user/UPDATE_USER'
const LINKEDIN = 'user/LINKEDIN'
const REMOVE = 'user/REMOVE'
const CLEAR_CACHE = 'user/CLEAR_CACHE'

// Reducer
const initialState = {
  data: null,
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD: {
      return {
        ...state,
        data: action.user,
      }
    }
    case UPDATE_USER: {
      return {
        ...state,
        data: { ...state.data, ...action.user },
      }
    }
    case LINKEDIN: {
      return {
        ...state,
        data: { ...state.data, linkedin: action.linkedin },
      }
    }
    case REMOVE:
      return initialState
    case CLEAR_CACHE:
      return initialState
    default:
      return state
  }
}

// Action Creators
export function loadUser(user) {
  return { type: LOAD, user }
}

export function putUser(user) {
  return { type: UPDATE_USER, user }
}

export function loadLinkedin(linkedin) {
  return { type: LINKEDIN, linkedin }
}

export function removeUser() {
  return { type: REMOVE }
}
