/* eslint-disable no-sequences */
// Actions
const LOAD_ALL = 'momentAttributes/LOAD_ALL'
const LOAD_UNIQUE = 'momentAttributes/LOAD_UNIQUE'
const CREATE = 'momentAttributes/CREATE'
const UPDATE = 'momentAttributes/UPDATE'
const REMOVE = 'momentAttributes/REMOVE'
const CLEAR_CACHE = 'momentAttributes/CLEAR_CACHE'

// Reducer
const initialState = {
  data: null,
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_ALL: {
      return {
        ...state,
        data: action.momentAttributes.reduce((a, b) => ((a[b.id] = b), a), {}),
      }
    }
    case LOAD_UNIQUE:
    case CREATE: {
      return {
        ...state,
        data: {
          ...state.data,
          [action.momentAttribute.id]: { ...action.momentAttribute },
        },
      }
    }
    case UPDATE: {
      return {
        ...state,
        data: {
          ...state.data,
          [action.momentAttribute.id]: action.momentAttribute,
        },
      }
    }
    case REMOVE:
      return {
        ...state,
        data: Object.values(state.data).filter((cm) => cm.id !== action.id),
      }
    case CLEAR_CACHE:
      return initialState
    default:
      return state
  }
}

// Action Creators
export function loadMomentAttributes(momentAttributes) {
  return { type: LOAD_ALL, momentAttributes }
}

export function loadMomentAttribute(momentAttribute) {
  return { type: LOAD_UNIQUE, momentAttribute }
}

export function postMomentAttribute(momentAttribute) {
  return { type: CREATE, momentAttribute }
}

export function putMomentAttribute(momentAttribute) {
  return { type: UPDATE, momentAttribute }
}

export function removeMomentAttribute(id) {
  return { type: REMOVE, id }
}
