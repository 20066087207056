const ffTisaPro = `"ff-tisa-web-pro", Georgia, Cambria, "Times New Roman", Times, serif`
const rubik = `"Rubik", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`

const theme = {
  fonts: {
    heading: ffTisaPro,
    body: rubik,
    tisa: ffTisaPro,
    rubik: rubik,
  },
  colors: {
    amberGray: {
      50: '#faf9f9',
      75: '#F8F7F7',
      100: '#efedec',
      200: '#e4e0dd',
      300: '#d7d2ce',
      400: '#cac2bd',
      500: '#bbb1ab',
      600: '#a99e96',
      700: '#95867c',
      800: '#79695e',
      900: '#473e37',
    },
    warmGray: {
      50: '#FAFAFA',
      100: '#F4F4F5',
      200: '#E4E4E7',
      300: '#D4D4D8',
      400: '#A1A1AA',
      500: '#71717A',
      600: '#52525B',
      700: '#3F3F46',
      800: '#27272A',
      900: '#18181B,',
    },
  },
  styles: {
    global: () => ({
      'html, body, #root': {
        bg: 'amberGray.100',
        height: '100%',
      },
      '*, *::before, *::after': {
        borderColor: 'amberGray.200',
      },
    }),
  },
  components: {
    Input: {
      variants: {
        outline: {
          field: {
            bg: 'amberGray.50',
            _hover: {
              borderColor: 'amberGray.300',
            },
            _placeholder: {
              color: 'amberGray.600',
            },
          },
        },
      },
    },
    NumberInput: {
      variants: {
        outline: {
          field: {
            bg: 'amberGray.50',
            _hover: {
              borderColor: 'amberGray.300',
            },
            _placeholder: {
              color: 'amberGray.600',
            },
          },
        },
      },
    },
    Select: {
      variants: {
        outline: {
          field: {
            bg: 'amberGray.50',
            _hover: {
              borderColor: 'amberGray.300',
            },
            _placeholder: {
              color: 'amberGray.600',
            },
          },
        },
      },
    },
    Textarea: {
      variants: {
        outline: {
          bg: 'amberGray.50',
          _hover: {
            borderColor: 'amberGray.300',
          },
          _placeholder: {
            color: 'amberGray.600',
          },
        },
      },
    },
  },
}

export default theme
