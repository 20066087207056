import React, { useRef } from 'react'
import { Link as ReactRouterLink, useRouteMatch } from 'react-router-dom'
import {
  Box,
  Flex,
  Link,
  useDisclosure,
  CloseButton,
  Icon,
  useToken,
  LinkBox,
  LinkOverlay,
  Image,
} from '@chakra-ui/react'
import {
  UilApps,
  UilBriefcase,
  UilShoppingBag,
  UilUserCircle,
  UilBars,
  UilTimes,
} from '@iconscout/react-unicons'
import Container from '../common/Container'
import Logo from '../../assets/images/logo.png'

const UserNavbar = (props) => {
  const menu = useRef(null)
  const currentPath = useRouteMatch().path
  const { isOpen, onOpen, onClose } = useDisclosure()

  const isPathMatch = (path) => {
    return currentPath === path
  }

  const handleToggle = () => {
    return isOpen ? onClose() : onOpen()
  }

  const menuItems = [
    {
      name: 'Dashboard',
      path: '/dashboard?skip=true',
      icon: UilApps,
      iconColor: 'orange.600',
    },
    {
      name: 'Career Moments',
      path: '/career-moments',
      icon: UilBriefcase,
      iconColor: 'green.600',
    },
    {
      name: 'Store',
      path: '/store',
      icon: UilShoppingBag,
      iconColor: 'purple.600',
    },
    {
      name: 'Account',
      path: '/account',
      icon: UilUserCircle,
      iconColor: 'teal.600',
    },
  ]

  const linkStyle = {
    fontWeight: 'semibold',
    color: 'orange.600',
    _hover: { color: 'orange.800' },
    px: '4',
    h: '20',
    alignItems: 'center',
    d: { base: 'inline-flex', lg: 'flex' },
    textDecoration: 'none',
  }

  const [orange600] = useToken('colors', ['orange.600'])

  return (
    <Box
      as="nav"
      bg="amberGray.200"
      borderBottomWidth="1px"
      borderColor="amberGray.300"
      {...props}
    >
      <Container>
        <Flex wrap="wrap" align="center">
          <Flex align="center" mr="12" h="20">
            <Image h="100%" src={Logo} alt="" py="1" />
          </Flex>

          <CloseButton
            ml="auto"
            d="flex"
            display={{ base: 'block', md: 'none' }}
            onClick={handleToggle}
          >
            {isOpen ? (
              <Icon boxSize="6" as={UilTimes} />
            ) : (
              <Icon boxSize="6" as={UilBars} />
            )}
          </CloseButton>

          <Box
            display={{ base: isOpen ? 'block' : 'none', md: 'flex' }}
            width={{ base: 'full', md: 'auto' }}
            alignItems="center"
            flexGrow="1"
            ref={menu}
          >
            {menuItems.map((link, idx) => (
              <LinkBox key={idx}>
                <Flex
                  sx={
                    isPathMatch(link.path)
                      ? {
                          ...linkStyle,
                          boxShadow: `inset 0 -3px ${orange600}`,
                        }
                      : { ...linkStyle, color: 'amberGray.800' }
                  }
                >
                  <Icon
                    mr="2"
                    as={link.icon}
                    boxSize="5"
                    sx={isPathMatch(link.path) ? { color: link.iconColor } : ''}
                  />
                  <LinkOverlay
                    onClick={onClose}
                    as={ReactRouterLink}
                    to={link.path}
                    sx={isPathMatch(link.path) ? { color: 'black' } : ''}
                  >
                    {link.name}
                  </LinkOverlay>
                </Flex>
              </LinkBox>
            ))}
            <Link as={ReactRouterLink} to="/logout" ml="auto" {...linkStyle}>
              Log Out
            </Link>
          </Box>
        </Flex>
      </Container>
    </Box>
  )
}

export default UserNavbar
