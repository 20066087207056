import React from 'react'
import {
  Grid,
  GridItem,
  Box,
  Button,
  Flex,
  Text,
  FormLabel,
  FormControl,
  Textarea,
  Select,
  useToken,
  Stack,
  StackDivider,
  IconButton,
  Icon,
} from '@chakra-ui/react'
import CreatableSelect from 'react-select/creatable'
import { useSelector } from 'react-redux'
import { settingsType } from '../../lib/types'
import groupBy from 'lodash/groupBy'
import { UilTrashAlt } from '@iconscout/react-unicons'
import {
  useFieldArray,
  useFormContext,
  Controller,
  useWatch,
} from 'react-hook-form'

const ActionsDetails = ({
  onAppendOrRemove,
  loadingMomentAttributes,
  setTabIndex,
  tabIndex,
  setAccIndex,
  accIndex,
  setUnsavedField,
}) => {
  const actionAttributes =
    useSelector(
      (state) =>
        state.momentAttributes.data &&
        Object.values(state.momentAttributes.data).filter(
          (a) => a.type === settingsType.ACTIONS.value
        )
    ) || []

  const { register, control, setValue } = useFormContext()
  const { fields, append, remove } = useFieldArray({
    name: 'actions',
  })

  const handleAppend = () => {
    onAppendOrRemove(1)
    append({ name: '', type: '', statement: '' })
  }

  const handleRemove = (idx) => {
    onAppendOrRemove(-1)
    remove(idx)
  }

  const handleBack = (nextTab) => {
    setTabIndex(nextTab)
  }

  const handleNext = (lastTab) => {
    setTabIndex(lastTab)
  }
  return (
    <Grid templateColumns={{ lg: 'repeat(12, 1fr)' }} gap="6">
      <GridItem order={{ lg: '2' }} colSpan={{ lg: '4' }}>
        <Box
          bg="amberGray.75"
          p="4"
          rounded="md"
          fontSize="sm"
          position="sticky"
          top="6"
        >
          <Text fontWeight="semibold">Pro Tip:</Text>
          <Text>
            The actions you took should reflect how you or your personal
            contribution solved the problem. You may also consider why you
            decided to perform this action over another.
          </Text>
        </Box>
      </GridItem>
      <GridItem order={{ lg: '1' }} colSpan={{ lg: '8' }}>
        <Box mt="2" mb="12">
          <Text fontSize="lg" fontWeight="medium">
            After you identified the context, or challenge, you acted. Let's
            collect the details of what you did.
            <br />
            <br />
            <b>Note:</b> If multiple actions were completed, add each to the
            moment.
          </Text>
        </Box>
        <Stack
          spacing="6"
          divider={<StackDivider borderColor="amberGray.200" />}
        >
          {fields &&
            fields.map((action, idx) => (
              <Box key={action.id}>
                {fields.length > 1 && (
                  <Flex>
                    <IconButton
                      variant="ghost"
                      colorScheme="red"
                      ml="auto"
                      icon={<Icon h="6" w="6" as={UilTrashAlt} />}
                      onClick={() => handleRemove(idx)}
                    />
                  </Flex>
                )}
                <Grid
                  templateColumns={{
                    lg: 'repeat(2, 1fr)',
                  }}
                  gap="6"
                >
                  <GridItem colSpan="1">
                    <FormControl
                      h="100%"
                      d="flex"
                      flexDir="column"
                      id={`skillGroup-${idx}`}
                    >
                      <FormLabel>What type of action did you take?</FormLabel>
                      <Controller
                        control={control}
                        name={`actions.${idx}.name`}
                        render={({ field: { onChange, ...inputProps } }) => (
                          <Select
                            mt="auto"
                            placeholder={
                              loadingMomentAttributes
                                ? 'Loading...'
                                : 'Select option'
                            }
                            isDisabled={loadingMomentAttributes}
                            onChange={(e) => {
                              onChange(e.target.value)
                              setValue(`actions.${idx}.type`, null)
                              e.target.value
                                ? setUnsavedField(true)
                                : setUnsavedField(false)
                            }}
                            {...inputProps}
                          >
                            {Object.entries(groupBy(actionAttributes, 'group'))
                              .sort()
                              .map(([group], index) => (
                                <option key={index} value={group}>
                                  {group}
                                </option>
                              ))}
                          </Select>
                        )}
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem colSpan="1">
                    <FormControl id={`actionVerb-${idx}`}>
                      <FormLabel>
                        Which verb best describes the action?{' '}
                      </FormLabel>
                      <CustomCreatableSelect
                        idx={idx}
                        action={action}
                        loadingMomentAttributes={loadingMomentAttributes}
                        onChange={(e) =>
                          e.target.value
                            ? setUnsavedField(true)
                            : setUnsavedField(false)
                        }
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem colSpan={{ lg: '2' }}>
                    <FormControl id={`actionStatement-${idx}`}>
                      <FormLabel>
                        Using the verb selected above, describe in greater
                        detail what actions or steps you took to address the
                        challenge.
                      </FormLabel>
                      <Textarea
                        rows="6"
                        {...register(`actions.${idx}.statement`)}
                        defaultValue={action.statement}
                        onChange={(e) =>
                          e.target.value
                            ? setUnsavedField(true)
                            : setUnsavedField(false)
                        }
                      />
                    </FormControl>
                  </GridItem>
                </Grid>
              </Box>
            ))}
        </Stack>
        <Flex mt="4">
          <Button
            variant="outline"
            colorScheme="orange"
            onClick={() => handleBack(0)}
          >
            Back
          </Button>
          <Button mx="auto" colorScheme="orange" onClick={handleAppend}>
            Add Another Action
          </Button>
          <Button
            variant="outline"
            colorScheme="orange"
            onClick={() => handleNext(2)}
          >
            Next
          </Button>
        </Flex>
      </GridItem>
    </Grid>
  )
}

const CustomCreatableSelect = ({ action, loadingMomentAttributes, idx }) => {
  const actionAttributes =
    useSelector(
      (state) =>
        state.momentAttributes.data &&
        Object.values(state.momentAttributes.data).filter(
          (a) => a.type === settingsType.ACTIONS.value
        )
    ) || []

  const { control } = useFormContext()
  const [amberGray50, amberGray100, amberGray200] = useToken('colors', [
    'amberGray.50',
    'amberGray.100',
    'amberGray.200',
  ])

  const typeValue = useWatch({
    name: `actions.${idx}.name`,
  })

  return (
    <Controller
      control={control}
      name={`actions.${idx}.type`}
      defaultValue={action ? action.type : null}
      render={({ field: { value, onChange } }) => (
        <CreatableSelect
          components={{ DropdownIndicator: () => null }}
          formatCreateLabel={(input) => `Choose "${input}"`}
          styles={{
            control: (provided, state) => ({
              ...provided,
              backgroundColor: state.isDisabled ? amberGray100 : amberGray50,
              cursor: 'text',
            }),
            menu: (provided) => ({
              ...provided,
              borderColor: amberGray200,
              backgroundColor: amberGray50,
            }),
            menuList: (provided) => ({
              ...provided,
              borderColor: amberGray200,
            }),
            option: (provided) => ({
              ...provided,
              '&:hover': {
                backgroundColor: amberGray100,
              },
              color: 'black',
              backgroundColor: amberGray50,
            }),
            indicatorSeparator: () => ({
              display: 'none',
            }),
          }}
          isDisabled={loadingMomentAttributes || !typeValue}
          isClearable
          placeholder={
            loadingMomentAttributes ? 'Loading...' : 'Type or select option'
          }
          options={actionAttributes
            .filter((a) => a.group === typeValue)
            .sort((a, b) =>
              a.value > b.value ? 1 : b.value > a.value ? -1 : 0
            )
            .map((a) => ({
              value: a.value,
              label: a.value,
            }))}
          value={
            value && {
              value: value,
              label: value,
            }
          }
          onChange={(e) => {
            onChange(e && e.value)
          }}
        />
      )}
    />
  )
}

export default ActionsDetails
