import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link as ReactRouterLink } from 'react-router-dom'
import {
  deleteUser,
  fetchUsers,
  inviteUser,
  reinviteUser,
} from '../api/admin/users'
import { createAssignment } from '../api/admin/assignments'
import { subscriptionType, userType } from '../lib/types'
import {
  Button,
  Box,
  Heading,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Grid,
  GridItem,
  FormControl,
  FormLabel,
  Input,
  Select,
  Avatar,
  AvatarGroup,
  Tooltip,
  Text,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Icon,
  IconButton,
  HStack,
} from '@chakra-ui/react'
import {
  UilEllipsisV,
  UilEnvelope,
  UilUserArrows,
  UilSchedule,
  UilTrashAlt,
} from '@iconscout/react-unicons'
import Container from '../components/common/Container'
import SkeletonTable from '../components/common/SkeletonTable'
import DataTable from '../components/common/DataTable'
import DefaultLayout from '../layouts/default'
import { formatDate } from '../lib/utils'
import DatePicker from '../components/common/Datepicker'
import dayjs from 'dayjs'
import { postSubscriptionPause } from '../api/stripe'

const SuperAdminDashboard = () => {
  const dispatch = useDispatch()
  const user = useSelector((state) => state.user.data)

  const [loadingUsers, setLoadingUsers] = useState(false)
  const [loadingAdmins, setLoadingAdmins] = useState(false)

  const getUsers = useCallback(async () => {
    try {
      setLoadingUsers(true)
      await dispatch(fetchUsers({ type: userType.USER }))
      setLoadingUsers(false)
    } catch (error) {
      setLoadingUsers(false)
      alert(error)
    }
  }, [dispatch])

  const getAdmins = useCallback(async () => {
    try {
      setLoadingAdmins(true)
      await dispatch(fetchUsers({ type: userType.ADMIN }))
      setLoadingAdmins(false)
    } catch (error) {
      setLoadingAdmins(false)
      alert(error)
    }
  }, [dispatch])

  useEffect(() => {
    getUsers()
    user && getAdmins()
  }, [getUsers, getAdmins, user])

  return (
    <DefaultLayout>
      <Container py="12">
        <Box mb="12">
          <Flex justify="space-between" align="center" mb="4">
            <Heading as="h2" fontSize="3xl">
              Users
            </Heading>
            <InviteUser />
          </Flex>
          <Box overflowX="auto" mx={{ base: '-6' }}>
            <Box px={{ base: '6' }} minW="fit-content">
              <Box
                boxShadow="base"
                bg="white"
                rounded="md"
                mb="4"
                overflow="hidden"
              >
                {loadingUsers ? (
                  <SkeletonTable rows="5" columns="6" />
                ) : (
                  <UserTable />
                )}
              </Box>
            </Box>
          </Box>
        </Box>
        <Box>
          <Flex justify="space-between" align="center" mb="4">
            <Heading as="h2" fontSize="3xl">
              Coaches
            </Heading>
            <InviteCoach />
          </Flex>
          <Box overflowX="auto" mx={{ base: '-6' }}>
            <Box px={{ base: '6' }} minW="fit-content">
              <Box
                boxShadow="base"
                bg="white"
                rounded="md"
                overflow="hidden"
                mb="4"
              >
                {loadingAdmins ? (
                  <SkeletonTable rows="5" columns="6" />
                ) : (
                  <CoachTable />
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Container>
    </DefaultLayout>
  )
}

const InviteUser = () => {
  const [modalType, setModalType] = useState(null)

  const modalState = useDisclosure()
  const openModalHandler = (type) => {
    setModalType(type)
    modalState.onOpen()
  }
  return (
    <>
      <Button
        onClick={() =>
          openModalHandler(
            <InviteModal
              closeModalHandler={modalState.onClose}
              type={userType.USER}
            />
          )
        }
        colorScheme="orange"
        variant="outline"
      >
        Invite a User
      </Button>
      <Modal isOpen={modalState.isOpen} onClose={modalState.onClose}>
        <ModalOverlay />
        <ModalContent>{modalType}</ModalContent>
      </Modal>
    </>
  )
}

const InviteCoach = () => {
  const [modalType, setModalType] = useState(null)

  const modalState = useDisclosure()
  const openModalHandler = (type) => {
    setModalType(type)
    modalState.onOpen()
  }
  return (
    <>
      <Button
        onClick={() =>
          openModalHandler(
            <InviteModal
              closeModalHandler={modalState.onClose}
              type={userType.ADMIN}
            />
          )
        }
        colorScheme="orange"
        variant="outline"
      >
        Invite a Coach
      </Button>
      <Modal isOpen={modalState.isOpen} onClose={modalState.onClose}>
        <ModalOverlay />
        <ModalContent>{modalType}</ModalContent>
      </Modal>
    </>
  )
}

const AssignmentAvatars = ({ users, limit }) => (
  <Tooltip
    label={
      <Box>
        {users.map((a, i) => (
          <Text key={i}>
            {a.firstName} {a.lastName}
          </Text>
        ))}
        {users.length > limit && <Text>+ {users.length - limit} more</Text>}
      </Box>
    }
    aria-label="A tooltip"
    placement="bottom"
  >
    <AvatarGroup size="sm" max={limit} d="inline-flex">
      {users.map((u, i) => (
        <Avatar key={i} name={`${u.firstName} ${u.lastName}`} src="" />
      ))}
    </AvatarGroup>
  </Tooltip>
)

const InviteModal = ({ closeModalHandler, type }) => {
  const dispatch = useDispatch()

  const [isLoading, setLoading] = useState(false)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [industry, setIndustry] = useState('')

  const today = dayjs().startOf('d').toDate()
  const [trialUntil, setTrialUntil] = useState(today)

  const submitHandler = async () => {
    try {
      setLoading(true)
      await dispatch(
        inviteUser({
          firstName,
          lastName,
          email,
          type,
          industry,
          trialUntil,
        })
      )
      setLoading(false)
      closeModalHandler()
    } catch (error) {
      setLoading(false)
      alert(error)
    }
  }

  return (
    <>
      <ModalHeader>
        Invite a New {type === userType.ADMIN ? 'Coach' : 'User'}
      </ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <Grid templateColumns="repeat(2, 1fr)" gap="4">
          <GridItem>
            <FormControl id="firstName">
              <FormLabel>First Name</FormLabel>
              <Input
                type="text"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl id="lastName">
              <FormLabel>Last Name</FormLabel>
              <Input
                type="text"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </FormControl>
          </GridItem>
          <GridItem colSpan="2">
            <FormControl id="email">
              <FormLabel>Email</FormLabel>
              <Input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </FormControl>
          </GridItem>
          {type === userType.ADMIN ? (
            <GridItem colSpan="2">
              <FormControl id="industry">
                <FormLabel>Industry/Specialty</FormLabel>
                <Input
                  type="text"
                  value={industry}
                  onChange={(e) => setIndustry(e.target.value)}
                />
              </FormControl>
            </GridItem>
          ) : (
            <GridItem colSpan="2">
              <FormControl id="trialUntil">
                <FormLabel>Select a Trial Until Date</FormLabel>
                <DatePicker
                  selected={trialUntil}
                  onChange={setTrialUntil}
                  showPopperArrow={true}
                />
              </FormControl>
            </GridItem>
          )}
        </Grid>
      </ModalBody>

      <ModalFooter>
        <Button variant="ghost" mr="3" onClick={closeModalHandler}>
          Cancel
        </Button>
        <Button
          isLoading={isLoading}
          loadingText="Sending..."
          colorScheme="orange"
          onClick={submitHandler}
        >
          Send Invitation
        </Button>
      </ModalFooter>
    </>
  )
}

const TrialUntilModal = ({ closeModalHandler, user }) => {
  const [isLoading, setLoading] = useState(false)
  const today = dayjs().startOf('d').toDate()
  const [trialUntil, setTrialUntil] = useState(today)
  const dispatch = useDispatch()

  const handleSetDate = async () => {
    try {
      setLoading(true)
      await dispatch(postSubscriptionPause(user.id, { trialUntil }))
      setLoading(false)
      closeModalHandler()
    } catch (error) {
      setLoading(false)
      alert(error)
    }
  }

  return (
    <>
      <ModalHeader>Set Trial Until Date</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <FormControl id="trialUntil">
          <FormLabel>Select a Date</FormLabel>
          <DatePicker
            selected={trialUntil}
            onChange={setTrialUntil}
            showPopperArrow={true}
          />
        </FormControl>
      </ModalBody>
      <ModalFooter>
        <Button variant="ghost" mr="3" onClick={closeModalHandler}>
          Cancel
        </Button>
        <Button
          type="button"
          isLoading={isLoading}
          loadingText="Saving..."
          colorScheme="orange"
          onClick={handleSetDate}
        >
          Save
        </Button>
      </ModalFooter>
    </>
  )
}

const AssignModal = ({ closeModalHandler, user }) => {
  const [isLoading, setLoading] = useState(false)
  const [adminId, setAdminId] = useState('')

  const dispatch = useDispatch()
  const admins = useSelector(
    (state) => (state.users.admins && Object.values(state.users.admins)) || []
  )

  const handleAssign = async () => {
    try {
      setLoading(true)
      await dispatch(createAssignment({ userId: user.id, adminId }))
      setLoading(false)
      closeModalHandler()
    } catch (error) {
      setLoading(false)
      alert(error)
    }
  }

  return (
    <>
      <ModalHeader>Assign User</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <FormControl id="assignCoach">
          <FormLabel>Select a Coach to assign:</FormLabel>
          <Select
            placeholder="Select option"
            value={adminId}
            onChange={(e) => setAdminId(e.target.value)}
            required
          >
            {admins &&
              admins.map((a, idx) => (
                <option
                  key={idx}
                  value={a.id}
                >{`${a.firstName} ${a.lastName}`}</option>
              ))}
          </Select>
        </FormControl>
      </ModalBody>
      <ModalFooter>
        <Button variant="ghost" mr="3" onClick={closeModalHandler}>
          Cancel
        </Button>
        <Button
          type="button"
          isLoading={isLoading}
          loadingText="Assigning..."
          colorScheme="orange"
          onClick={handleAssign}
        >
          Assign
        </Button>
      </ModalFooter>
    </>
  )
}

const UserActions = ({ user }) => {
  const dispatch = useDispatch()
  const modalState = useDisclosure()
  const [modalType, setModalType] = useState(null)

  const openModalHandler = useCallback(
    (type) => {
      setModalType(type)
      modalState.onOpen()
    },
    [modalState]
  )

  const resendHandler = useCallback(async () => {
    try {
      await dispatch(reinviteUser(user.id))
      alert(`Email resent to ${user.email}`)
    } catch (error) {
      alert(error)
    }
  }, [dispatch, user.email, user.id])

  const deleteHandler = useCallback(async () => {
    try {
      var r = window.confirm(
        'Are you sure you want to delete this user? This action is permanent and cannot be undone.'
      )
      if (r !== true) return

      await dispatch(deleteUser(user.id))
      alert('User deleted!')
    } catch (error) {
      alert(error)
    }
  }, [dispatch, user.id])

  return (
    <>
      <HStack>
        <Button
          as={ReactRouterLink}
          to={`/users/${user.id}`}
          colorScheme="orange"
          variant="ghost"
        >
          View
        </Button>
        <Menu placement="bottom-end">
          <MenuButton
            as={IconButton}
            aria-label="Modify Job Listing"
            variant="ghost"
            colorScheme="orange"
            size="sm"
            ml="auto"
            icon={<Icon boxSize="5" as={UilEllipsisV} />}
          />
          <MenuList>
            <MenuItem
              icon={<Icon boxSize="5" as={UilUserArrows} />}
              onClick={() => {
                openModalHandler(
                  <AssignModal
                    closeModalHandler={modalState.onClose}
                    user={user}
                  />
                )
              }}
            >
              Assign to Coach
            </MenuItem>
            <MenuItem
              icon={<Icon boxSize="5" as={UilEnvelope} />}
              isDisabled={user.password}
              onClick={resendHandler}
            >
              Resend Invitation
            </MenuItem>
            <MenuItem
              icon={<Icon boxSize="5" as={UilSchedule} />}
              onClick={() => {
                openModalHandler(
                  <TrialUntilModal
                    closeModalHandler={modalState.onClose}
                    user={user}
                  />
                )
              }}
            >
              Set Trial Until
            </MenuItem>
            <MenuItem
              color="red.600"
              icon={<Icon boxSize="5" as={UilTrashAlt} />}
              onClick={deleteHandler}
            >
              Delete User
            </MenuItem>
          </MenuList>
        </Menu>
      </HStack>
      <Modal isOpen={modalState.isOpen} onClose={modalState.onClose}>
        <ModalOverlay />
        <ModalContent>{modalType}</ModalContent>
      </Modal>
    </>
  )
}

const UserTable = () => {
  const users = useSelector(
    (state) => (state.users.users && Object.values(state.users.users)) || []
  )

  const mappedUsers = users.map((u) => ({ ...u }))
  const data = useMemo(() => mappedUsers, [mappedUsers])
  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: (u) => `${u.firstName} ${u.lastName}`,
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'Assigned To',
        id: 'assignedTo',
        accessor: (u) => u,
        Cell: ({ value: u }) => {
          return u.recipients && u.recipients.length > 0 ? (
            <AssignmentAvatars users={u.recipients} limit={1} />
          ) : (
            ''
          )
        },
        disableSortBy: true,
      },
      {
        Header: 'Subscription',
        accessor: 'subscriptionTier',
        Cell: ({ value }) =>
          value
            ? Object.values(subscriptionType).find(
                (type) => type.value === parseInt(value)
              ).title
            : '-',
      },
      {
        Header: 'Paid Until',
        accessor: 'paidUntil',
        Cell: ({ value }) => (value ? formatDate(value) : '-'),
      },
      {
        Header: 'Trial Until',
        accessor: 'trialUntil',
        Cell: ({ value }) => (value ? formatDate(value) : '-'),
      },
      {
        id: 'action',
        accessor: (u) => u,
        Cell: ({ value }) => <UserActions user={value} />,
        disableSortBy: true,
      },
    ],
    []
  )

  return <DataTable size="sm" columns={columns} data={data} />
}

const CoachTable = () => {
  const dispatch = useDispatch()
  const admins = useSelector(
    (state) => (state.users.admins && Object.values(state.users.admins)) || []
  )
  const [resendId, setResendId] = useState(null)
  const [deleteId, setDeleteId] = useState(null)

  const resendHandler = useCallback(
    async (user) => {
      try {
        await dispatch(reinviteUser(user.id))
        setResendId(null)
        alert('Email sent!')
      } catch (error) {
        setResendId(null)
        alert(error)
      }
    },
    [dispatch]
  )

  const deleteHandler = useCallback(
    async (admin) => {
      try {
        var r = window.confirm(
          'Are you sure you want to delete this coach? This action is permanent and cannot be undone.'
        )
        if (r !== true) return
        await dispatch(deleteUser(admin.id, true))
        setDeleteId(null)
        alert('Coach deleted!')
      } catch (error) {
        setDeleteId(null)
        alert(error)
      }
    },
    [dispatch]
  )

  const mappedCoaches = admins.map((a) => ({ ...a }))
  const data = useMemo(() => mappedCoaches, [mappedCoaches])
  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: (u) => `${u.firstName} ${u.lastName}`,
      },
      {
        Header: 'Email Address',
        accessor: 'email',
      },
      {
        Header: 'Assignees',
        id: 'assignees',
        accessor: (row) => row,
        Cell: ({ value: c }) =>
          c.assignments && c.assignments.length > 0 ? (
            <AssignmentAvatars users={c.assignments} limit={5} />
          ) : (
            ''
          ),
        disableSortBy: true,
      },
      {
        Header: 'Industry',
        accessor: 'industry',
      },
      {
        id: 'action',
        accessor: (row) => row,
        Cell: ({ value: a }) => (
          <Box whiteSpace="nowrap">
            <Button
              as={ReactRouterLink}
              to={`/coaches/${a.id}/`}
              colorScheme="orange"
              variant="ghost"
            >
              View
            </Button>
            {!a.password && (
              <Button
                id={a.id}
                colorScheme="orange"
                variant="ghost"
                isLoading={resendId === a.id ? true : false}
                loadingText="Sending..."
                onClick={() => {
                  setResendId(a.id)
                  resendHandler(a)
                }}
              >
                Resend Invitation
              </Button>
            )}
            <Button
              id={a.id}
              colorScheme="red"
              variant="ghost"
              isLoading={deleteId === a.id ? true : false}
              loadingText="Deleting..."
              onClick={() => {
                setDeleteId(a.id)
                deleteHandler(a)
              }}
            >
              Delete
            </Button>
          </Box>
        ),
        disableSortBy: true,
      },
    ],
    [resendId, deleteId, deleteHandler, resendHandler]
  )

  return <DataTable columns={columns} data={data} />
}

export default SuperAdminDashboard
