import { setupRequest, fetchJSON, errorHandler } from '../../lib/utils'
import {
  postAssignments,
  putAssignments,
  removeAssignments,
} from '../../redux/admin/users'

function createAssignment(data) {
  return async (dispatch, getState) => {
    let state = getState()
    try {
      validationAssignment(data)
      const { url, options } = setupRequest('/assignments', 'POST', state)
      options.body = JSON.stringify(data)
      let json = await fetchJSON(url, options)
      if (json.description) throw Error(json.description)
      if (json.error) throw Error(json.error)
      if (!json.user) throw Error('API_ERROR_CREATE_ASSIGNMENT')
      if (json.admin) dispatch(postAssignments(json.admin, json.user))
      else if (json.admins) dispatch(putAssignments(json.admins, json.user))
      else throw Error('API_ERROR_CREATE_ASSIGNMENT')
    } catch (error) {
      errorHandler(error)
      throw error
    }
  }
}

function updateAssignment(id, data) {
  return async (dispatch, getState) => {
    let state = getState()
    try {
      validationAssignment(data, false)
      const { url, options } = setupRequest(`/assignments/${id}`, 'PUT', state)
      options.body = JSON.stringify(data)
      let json = await fetchJSON(url, options)
      if (json.description) throw Error(json.description)
      if (json.error) throw Error(json.error)
      if (!json.admins || !json.user)
        throw Error('API_ERROR_UPDATE_ASSIGNMENTS')
      dispatch(putAssignments(json.admins, json.user))
    } catch (error) {
      errorHandler(error)
      throw error
    }
  }
}

function deleteAssignment(id, data) {
  return async (dispatch, getState) => {
    let state = getState()
    try {
      const { url, options } = setupRequest(
        `/assignments/${id}`,
        'DELETE',
        state
      )
      options.body = JSON.stringify(data)
      let json = await fetchJSON(url, options)
      if (json.description) throw Error(json.description)
      if (json.error) throw Error(json.error)
      if (!json.admin || !json.user) throw Error('API_ERROR_DELETE_ASSIGNMENT')
      dispatch(removeAssignments(json.admin, json.user))
    } catch (error) {
      errorHandler(error)
      throw error
    }
  }
}

function validationAssignment(data, create = true) {
  if (!data) throw Error('NO_BODY_PROVIDED')
  if (!data.userId && create) throw Error('NO_USER_ID_PROVIDED')
  if (!data.adminId) throw Error('Please select a Coach')
}

export { createAssignment, updateAssignment, deleteAssignment }
