import React from 'react'
import {
  Flex,
  useRadioGroup,
  Text,
  Circle,
  VStack,
  Box,
} from '@chakra-ui/react'
import CompoundRadio from './common/CompoundRadio'
import { formatPrice } from '../lib/utils'
import { subscriptionType } from '../lib/types'

export default function SubscriptionRadioList({
  handleChange,
  options = [],
  defaultValue = '',
}) {
  const { getRootProps, getRadioProps } = useRadioGroup({
    name: 'subscriptions',
    onChange: handleChange,
    defaultValue,
  })

  const group = getRootProps()
  return (
    <VStack {...group} alignItems="start" spacing="-1px">
      {options &&
        options.map((s, idx) => {
          const radio = getRadioProps({ value: s.value.toString() })
          return (
            <CompoundRadio
              {...radio}
              key={idx}
              sx={{
                p: '4',
                borderWidth: '1px',
                mt: '-1px',
                _first: {
                  borderTopRadius: 'md',
                },
                _last: {
                  borderBottomRadius: 'md',
                },
                _checked: {
                  zIndex: '1',
                  bg: 'amberGray.100',
                  borderColor: 'orange.600',
                },
              }}
            >
              <Flex justify="space-between" mb="1">
                <Flex align="center">
                  <Circle
                    boxSize="4"
                    borderWidth="2px"
                    borderColor={radio.isChecked && 'orange.600'}
                    p="2px"
                  >
                    <Circle
                      boxSize="100%"
                      bg={radio.isChecked && 'orange.600'}
                    />
                  </Circle>
                  <Text
                    as="span"
                    fontWeight="bold"
                    fontSize="lg"
                    ml="2"
                    color={radio.isChecked && 'orange.600'}
                  >
                    {s.title} Subscription
                  </Text>
                </Flex>
                <Box>
                  {s.value === subscriptionType.ANNUAL.value ? (
                    <>
                      <Text as="s" color="gray.500" fontWeight="semibold">
                        {formatPrice(subscriptionType.MONTHLY.price * 12)} /
                        year
                      </Text>
                      <Text as="span" ml="2" fontSize="lg" fontWeight="bold">
                        {formatPrice(s.price)} / year
                      </Text>
                    </>
                  ) : (
                    <Text as="span" fontSize="lg" fontWeight="bold">
                      {formatPrice(s.price)} / month
                    </Text>
                  )}
                </Box>
              </Flex>
              <Text>{s.description}</Text>
            </CompoundRadio>
          )
        })}
    </VStack>
  )
}
