import React from 'react'
import { Box, useToken } from '@chakra-ui/react'

export default function Overlay({
  show = true,
  content = <div />,
  children,
  ...props
}) {
  const [amberGray300] = useToken('colors', ['amberGray.300'])

  return (
    <Box {...props} position="relative">
      {show && (
        <Box
          position="absolute"
          h="100%"
          w="100%"
          style={{
            backdropFilter: 'blur(6px)',
            backgroundColor: `${amberGray300}EE`,
          }}
          zIndex="1"
        >
          {content}
        </Box>
      )}
      <Box zIndex="0">{children}</Box>
    </Box>
  )
}
