import React from 'react'
import { Circle, Tooltip, Icon } from '@chakra-ui/react'
import {
  UilCheckCircle,
  UilClock,
  UilExclamationCircle,
  UilPen,
} from '@iconscout/react-unicons'
import { cmComputedStatusType, csStatusType } from '../../lib/types'

const MomentStatusIndicator = ({ content }) => {
  const countApprovedStories = content.careerStories
    ? content.careerStories.filter((cs) => cs.status === csStatusType.APPROVED)
        .length
    : 0

  const getStatus =
    content.computedStatus === cmComputedStatusType.DRAFT
      ? {
          bg: 'gray.100',
          fg: 'gray.600',
          icon: UilPen,
          label: 'Draft',
        }
      : content.computedStatus === cmComputedStatusType.NO_STORIES
      ? {
          bg: 'yellow.200',
          fg: 'yellow.600',
          icon: UilExclamationCircle,
          label: 'No Stories',
        }
      : content.computedStatus === cmComputedStatusType.ALL_APPROVED
      ? {
          bg: 'green.100',
          fg: 'green.600',
          icon: UilCheckCircle,
          label: `${countApprovedStories} / ${content.careerStories.length} Stories Approved`,
        }
      : {
          bg: 'blue.100',
          fg: 'blue.600',
          icon: UilClock,
          label: `${countApprovedStories} / ${
            (content.careerStories || []).length
          } Stories Approved`,
        }

  return (
    <Tooltip label={getStatus.label} placement="top">
      <Circle size="7" bg={getStatus.bg}>
        <Icon as={getStatus.icon} color={getStatus.fg} boxSize="5" />
      </Circle>
    </Tooltip>
  )
}

export default MomentStatusIndicator
