/* eslint-disable no-sequences */
// Actions
const LOAD = 'payments/LOAD'
const CREATE = 'payments/CREATE'
const UPDATE = 'payments/UPDATE'
const REMOVE = 'payments/REMOVE'
const CLEAR_CACHE = 'payments/CLEAR_CACHE'

// Reducer
const initialState = {
  data: null,
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD: {
      return {
        ...state,
        data: action.payments.reduce((a, b) => ((a[b.id] = b), a), {}),
      }
    }
    case CREATE: {
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payments.reduce((a, b) => ((a[b.id] = b), a), {}),
        },
      }
    }
    case UPDATE: {
      return {
        ...state,
        data: {
          ...state.data,
          [action.payment.id]: action.payment,
        },
      }
    }
    case REMOVE:
      return initialState
    case CLEAR_CACHE:
      return initialState
    default:
      return state
  }
}

// Action Creators
export function loadPayments(payments) {
  return { type: LOAD, payments }
}

export function postPayments(payments) {
  return { type: CREATE, payments }
}

export function putPayment(payment) {
  return { type: UPDATE, payment }
}

export function removePayments() {
  return { type: REMOVE }
}
