import React, { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Button,
  Box,
  Grid,
  GridItem,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  RadioGroup,
  VStack,
  Radio,
} from '@chakra-ui/react'
import Container from '../components/common/Container'
import { useDispatch, useSelector } from 'react-redux'
import { putRegistration } from '../redux/registration'
import { onboardingQuestions } from '../lib/types'

export default function OnboardingStepOne() {
  const registration = useSelector((state) => state.registration.data)
  const dispatch = useDispatch()
  const history = useHistory()
  const [form, setForm] = useState({
    years: (registration && registration.years) || '',
    primaryReason: (registration && registration.primaryReason) || '',
    secondaryReason: (registration && registration.secondaryReason) || '',
  })

  const handleChange = (key, value) => {
    setForm(() => {
      return key === 'primaryReason' && value === form.secondaryReason
        ? {
            ...form,
            [key]: value,
            secondaryReason: '',
          }
        : {
            ...form,
            [key]: value,
          }
    })
  }

  const updateRegState = useCallback(async () => {
    await dispatch(
      putRegistration({
        years: form.years,
        primaryReason: form.primaryReason,
        secondaryReason: form.secondaryReason,
      })
    )
  }, [dispatch, form])

  useEffect(() => {
    updateRegState()
  }, [form, updateRegState])

  const handleSubmit = async (e) => {
    e.preventDefault()
    history.push('/onboarding/2')
  }

  return (
    <Container py="20">
      <Grid templateColumns={{ lg: 'repeat(12, 1fr)' }}>
        <GridItem colStart={{ lg: '4' }} colSpan={{ lg: '6' }}>
          <Box boxShadow="base" p="6" mb="6" bg="white" w="100%" rounded="md">
            <Box mb="4">
              <Heading as="h1" fontSize="3xl" mb="8">
                Let's Get Started
              </Heading>
            </Box>
            <form onSubmit={handleSubmit}>
              <Grid gap="8">
                <GridItem>
                  <FormControl id="years">
                    <FormLabel>
                      How many years of work are included in your Career Story?
                    </FormLabel>
                    <RadioGroup
                      value={form.years}
                      onChange={(value) => handleChange('years', value)}
                    >
                      <VStack alignItems="start">
                        {onboardingQuestions.YEARS.map((value, idx) => (
                          <Radio
                            key={idx}
                            value={idx.toString()}
                            colorScheme="orange"
                            isRequired
                          >
                            {value}
                          </Radio>
                        ))}
                      </VStack>
                    </RadioGroup>
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl id="primaryReason">
                    <FormLabel>
                      Why is telling your Career Story most important to you?
                    </FormLabel>
                    <RadioGroup
                      value={form.primaryReason}
                      onChange={(value) => handleChange('primaryReason', value)}
                    >
                      <VStack alignItems="start">
                        {onboardingQuestions.REASONS.map((value, idx) => (
                          <Radio
                            key={idx}
                            value={idx.toString()}
                            colorScheme="orange"
                            isChecked={form.primaryReason === value}
                            isRequired
                          >
                            {value}
                          </Radio>
                        ))}
                      </VStack>
                    </RadioGroup>
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl id="secondaryReason">
                    <FormLabel>
                      What else is important for us to know about you?
                    </FormLabel>
                    <RadioGroup
                      defaultValue="1"
                      onChange={(value) =>
                        handleChange('secondaryReason', value)
                      }
                      value={form.secondaryReason}
                    >
                      <VStack alignItems="start">
                        {onboardingQuestions.REASONS.map((value, idx) => (
                          <Radio
                            key={idx}
                            value={idx.toString()}
                            colorScheme="orange"
                            isDisabled={form.primaryReason === idx.toString()}
                            isChecked={form.secondaryReason === idx.toString()}
                            isRequired
                          >
                            {value}
                          </Radio>
                        ))}
                      </VStack>
                    </RadioGroup>
                  </FormControl>
                </GridItem>
                <GridItem>
                  <Flex align="center" mt="4">
                    <Button type="submit" colorScheme="orange" isFullWidth>
                      Continue
                    </Button>
                  </Flex>
                </GridItem>
              </Grid>
            </form>
          </Box>
        </GridItem>
      </Grid>
    </Container>
  )
}
