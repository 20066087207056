import { useCheckbox } from '@chakra-ui/checkbox'
import { Box } from '@chakra-ui/layout'
import React from 'react'

export default function CompoundCheckbox({ sx, children, ...props }) {
  const { getInputProps, getCheckboxProps } = useCheckbox(props)
  const input = getInputProps()
  const checkbox = getCheckboxProps()

  return (
    <Box
      {...checkbox}
      as="label"
      w="100%"
      cursor="pointer"
      _focus={{
        boxShadow: 'outline',
      }}
      sx={sx}
    >
      <input {...input} />
      <Box>{children}</Box>
    </Box>
  )
}
