import { Box, Input } from '@chakra-ui/react'
import React from 'react'
import ReactDatePicker from 'react-datepicker'

import 'react-datepicker/dist/react-datepicker.css'
import '../../assets/css/datepicker.css'

const styles = {
  '.react-datepicker__navigation-icon': {
    top: '5px',
  },
  '.react-datepicker__navigation-icon::before': {
    borderColor: 'black',
  },
  '.react-datepicker': {
    fontFamily: 'unset',
    fontSize: '0.9rem',
    borderColor: 'amberGray.300',
  },
  '.react-datepicker-wrapper, .react-datepicker__input-container': {
    display: 'block',
  },
  '.react-datepicker__input-container': {
    fontSize: 'inherit',
    paddingLeft: '0',
    paddingRight: '0',
    borderRadius: '0',
    border: 'none',

    borderColor: 'amberGray.200',
  },
  // '.react-datapicker__input-text': { backgroundColor: 'transparent' },
  '.react-datepicker__input-container:hover': { borderColor: 'gray.300' },
  '.react-datepicker__input-container:focus-within': {
    zIndex: 1,
    borderColor: 'blue.600',
  },
  '.react-datepicker__input-container > input': {
    width: '100%',
    height: '100%',
    outline: '0',
  },
  '.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button)':
    {
      right: '90px',
    },
  '.react-datepicker__navigation--previous, .react-datepicker__navigation--next':
    {
      height: 'auto',
    },
  // '.react-datepicker__navigation--previous': {
  //   borderRightColor: 'blue.400',
  // },
  // '.react-datepicker__navigation--previous:hover': {
  //   borderRightColor: 'blue.500',
  // },
  // '.react-datepicker__navigation--next': { borderLeftColor: 'blue.400' },
  // '.react-datepicker__navigation--next:hover': {
  //   borderLeftColor: 'blue.500',
  // },
  '.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after':
    {
      borderBottomColor: 'amberGray.300',
    },
  '.react-datepicker__header': {
    backgroundColor: 'amberGray.300',
    borderColor: 'amberGray.300',
    borderTopLeftRadius: '0.2rem',
    '&:not(.react-datepicker__header--has-time-select)': {
      borderTopRightRadius: '0.2rem',
    },
  },
  '.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header':
    {
      fontSize: 'inherit',
      fontWeight: 'bold',
    },
  '.react-datepicker__month': {
    backgroundColor: 'amberGray.100',
    margin: '0',
    padding: '0.4rem',
  },
  '.react-datepicker__time-container, .react-datepicker__time, .react-datepicker__time-box, ul.react-datepicker__time-list, li.react-datepicker__time-list-item':
    {
      margin: '0 1px 0 0',
      height: 'auto',
      padding: '7px 10px',
    },
  '.react-datepicker__time-container, .react-datepicker__time, .react-datepicker__time-box, ul.react-datepicker__time-list, li.react-datepicker__time-list-item:hover':
    {
      background: 'blue.200',
    },
  '.react-datepicker__day': {
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: 'transparent',
  },
  '.react-datepicker__day:hover': { background: 'amberGray.400' },
  '.react-datepicker__day--selected': {
    background: 'orange.500',
    fontWeight: 'bold',
    color: 'white',
  },
  '.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected':
    {
      bg: 'transparent',
      borderColor: 'orange.500',
      borderWidth: '1px',
      borderStyle: 'solid',
    },
  '.react-datepicker__day--selected:hover': {
    background: 'orange.600',
  },
  '.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__time-container, .react-datepicker__time, .react-datepicker__time-box, ul.react-datepicker__time-list, li.react-datepicker__time-list-item--selected':
    {
      background: 'orange.500',
      color: 'white',
    },
  '.react-datepicker__time-container, .react-datepicker__time, .react-datepicker__time-box, ul.react-datepicker__time-list, li.react-datepicker__time-list-item--selected:hover':
    {
      background: 'orange.700',
    },
  '.react-datepicker__close-icon::after': {
    backgroundColor: 'unset',
    borderRadius: 'unset',
    fontSize: '1.5rem',
    fontWeight: 'bold',
    color: 'gray.100',
    height: '20px',
    width: '20px',
  },
  '.react-datepicker__close-icon::after:hover': { color: 'gray.300' },
}

const DatePicker = ({
  isClearable = false,
  showPopperArrow = false,
  ...props
}) => {
  return (
    <Box sx={styles}>
      <Input
        as={ReactDatePicker}
        isClearable={isClearable}
        showPopperArrow={showPopperArrow}
        className="react-datapicker__input-text" //input is white by default and there is no already defined class for it so I created a new one
        {...props}
      />
    </Box>
  )
}

export default DatePicker
