/* eslint-disable no-sequences */
// Actions
const LOAD = 'careerStories/LOAD'
const LOAD_UNIQUE = 'careerStories/LOAD_UNIQUE'
const CREATE = 'careerStories/CREATE'
const ADD_COMMENT = 'careerStories/ADD_COMMENT'
const UPDATE = 'careerStories/UPDATE'
const REMOVE = 'careerStories/REMOVE'
const CLEAR_CACHE = 'careerStories/CLEAR_CACHE'

// Reducer
const initialState = {
  data: null,
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD: {
      return {
        ...state,
        data: action.careerStories.reduce((a, b) => ((a[b.id] = b), a), {}),
      }
    }
    case LOAD_UNIQUE:
    case CREATE: {
      return {
        ...state,
        data: {
          ...state.data,
          [action.careerStory.id]: { ...action.careerStory },
        },
      }
    }
    case ADD_COMMENT: {
      return {
        ...state,
        data: {
          ...state.data,
          [action.comment.objectId]: {
            ...state.data[action.comment.objectId],
            comments: [
              ...(state.data[action.comment.objectId].comments || []),
              action.comment,
            ],
          },
        },
      }
    }
    case UPDATE: {
      return {
        ...state,
        data: {
          ...state.data,
          [action.careerStory.id]: {
            ...state.data[action.careerStory.id],
            ...action.careerStory,
          },
        },
      }
    }
    case REMOVE:
      return initialState
    case CLEAR_CACHE:
      return initialState
    default:
      return state
  }
}

// Action Creators
export function loadCareerStories(careerStories) {
  return { type: LOAD, careerStories }
}

export function loadCareerStory(careerStory) {
  return { type: LOAD_UNIQUE, careerStory }
}

export function postCareerStory(careerStory) {
  return { type: CREATE, careerStory }
}

export function addCommentCareerStory(comment) {
  return { type: ADD_COMMENT, comment }
}

export function putCareerStory(careerStory) {
  return { type: UPDATE, careerStory }
}

export function removeCareerStory() {
  return { type: REMOVE }
}
