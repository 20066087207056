import { errorHandler, fetchJSON, setupRequest } from '../lib/utils'
import { putPayment } from '../redux/payments'
import { putUser } from '../redux/user'

function postPaymentIntent(data) {
  return async (dispatch, getState) => {
    let state = getState()
    try {
      const path = '/stripe/payment-intent'
      const { url, options } = setupRequest(path, 'POST', state)
      options.body = JSON.stringify(data)
      let json = await fetchJSON(url, options)
      if (json.description) throw Error(json.description)
      if (json.error) throw Error(json.error)
      return json.clientSecret
    } catch (error) {
      errorHandler(error)
      throw error
    }
  }
}

function postSubscription(data) {
  return async (dispatch, getState) => {
    let state = getState()
    try {
      const { url, options } = setupRequest(
        `/stripe/subscription/`,
        'POST',
        state
      )
      options.body = JSON.stringify(data)
      let json = await fetchJSON(url, options)
      if (json.description) throw Error(json.description)
      if (json.error) throw Error(json.error)
      if (!json.user || !json.payment)
        throw Error('API_ERROR_POST_SUBSCRIPTION')
      await dispatch(putUser(json.user))
      await dispatch(putPayment(json.payment))
    } catch (error) {
      errorHandler(error)
      throw error
    }
  }
}

function putSubscription(id, data) {
  return async (dispatch, getState) => {
    let state = getState()
    try {
      const { url, options } = setupRequest(
        `/stripe/subscription/${id}`,
        'PUT',
        state
      )
      options.body = JSON.stringify(data)
      let json = await fetchJSON(url, options)
      if (json.description) throw Error(json.description)
      if (json.error) throw Error(json.error)
      if (!json.user || !json.payment) throw Error('API_ERROR_PUT_SUBSCRIPTION')
      await dispatch(putUser(json.user))
      await dispatch(putPayment(json.payment))
    } catch (error) {
      errorHandler(error)
      throw error
    }
  }
}

function deleteSubscription(id, data) {
  return async (dispatch, getState) => {
    let state = getState()
    try {
      const { url, options } = setupRequest(
        `/stripe/subscription/${id}`,
        'DELETE',
        state
      )
      options.body = JSON.stringify(data)
      let json = await fetchJSON(url, options)
      if (json.description) throw Error(json.description)
      if (json.error) throw Error(json.error)
      if (!json.user) throw Error('API_ERROR_DELETE_SUBSCRIPTION')
      dispatch(putUser(json.user))
    } catch (error) {
      errorHandler(error)
      throw error
    }
  }
}

function postSubscriptionPause(id, data) {
  return async (dispatch, getState) => {
    const state = getState()
    try {
      const { url, options } = setupRequest(
        `/stripe/subscription/${id}/pause`,
        'POST',
        state
      )
      options.body = JSON.stringify(data)
      let json = await fetchJSON(url, options)
      if (json.description) throw Error(json.description)
      if (json.error) throw Error(json.error)
      if (!json.user) throw Error('API_ERROR_POST_SUBSCRIPTION_PAUSE')
      await dispatch(putUser(json.user))
    } catch (error) {
      errorHandler(error)
      throw error
    }
  }
}

export {
  postPaymentIntent,
  postSubscription,
  putSubscription,
  deleteSubscription,
  postSubscriptionPause,
}
