import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { Link as ReactRouterLink } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { fetchUsers } from '../api/admin/users'
import { userType } from '../lib/types'
import { Box, Heading, Flex, Button } from '@chakra-ui/react'
import Container from '../components/common/Container'
import { useParams } from 'react-router-dom'
import SkeletonTable from '../components/common/SkeletonTable'
import DataTable from '../components/common/DataTable'
import DefaultLayout from '../layouts/default'

const CoachDashboard = () => {
  const dispatch = useDispatch()
  const { userId } = useParams()

  const [loadingAdmins, setLoadingAdmins] = useState(false)
  const [loadingUsers, setLoadingUsers] = useState(false)

  const getAdmins = useCallback(async () => {
    try {
      setLoadingAdmins(true)
      await dispatch(fetchUsers({ type: userType.ADMIN }))
      setLoadingAdmins(false)
    } catch (error) {
      setLoadingAdmins(false)
      alert(error)
    }
  }, [dispatch])

  const getUsers = useCallback(async () => {
    try {
      setLoadingUsers(true)
      await dispatch(fetchUsers({ type: userType.USER }))
      setLoadingUsers(false)
    } catch (error) {
      setLoadingUsers(false)
      alert(error)
    }
  }, [dispatch])

  useEffect(() => {
    userId ? getAdmins() : getUsers()
  }, [getAdmins, getUsers, userId])

  const user = useSelector((state) =>
    userId ? state.users.admins && state.users.admins[userId] : state.user.data
  )

  return (
    <DefaultLayout>
      <Container py="12">
        {userId ? (
          <>
            <Flex mb="8">
              <Heading as="h1">
                {user.firstName} {user.lastName}
              </Heading>
              <Button
                ml="auto"
                colorScheme="orange"
                variant="outline"
                to={`/coaches/${user.id}/account/`}
                as={ReactRouterLink}
              >
                Edit
              </Button>
            </Flex>
            <Box mb="4">
              <Heading as="h2" fontSize="2xl">
                Assigned Users
              </Heading>
            </Box>
          </>
        ) : (
          <Box mb="4">
            <Heading as="h1">Users</Heading>
          </Box>
        )}
        <Box overflowX="auto" mx={{ base: '-6' }}>
          <Box px={{ base: '6' }} minW="fit-content">
            <Box
              boxShadow="base"
              bg="white"
              rounded="md"
              mb="4"
              overflow="hidden"
            >
              {loadingUsers || loadingAdmins ? (
                <SkeletonTable rows="3" columns="5" />
              ) : (
                <UserTable />
              )}
            </Box>
          </Box>
        </Box>
      </Container>
    </DefaultLayout>
  )
}

const UserTable = () => {
  const { userId } = useParams()
  const user = useSelector((state) =>
    userId ? state.users.admins && state.users.admins[userId] : state.user.data
  )

  const assignments =
    useSelector((state) =>
      userId
        ? state.users.admins &&
          state.users.admins[userId] &&
          state.users.admins[userId].assignments
        : user.assignments
    ) || []

  const mappedAssignments = assignments.map((a) => ({ ...a }))

  const data = useMemo(() => mappedAssignments, [mappedAssignments])
  const columns = useMemo(
    () => [
      {
        Header: 'First Name',
        accessor: 'firstName',
      },
      {
        Header: 'Last Name',
        accessor: 'lastName',
      },
      {
        Header: 'Email Address',
        accessor: 'email',
      },
      {
        Header: 'Stories',
        accessor: 'storiesCount',
        isNumeric: true,
      },
      {
        Header: 'Moments',
        accessor: 'momentsCount',
        isNumeric: true,
      },
      {
        Header: 'Job Listings',
        accessor: 'jobListingsCount',
        isNumeric: true,
      },
      {
        Header: '',
        accessor: 'id',
        disableSortBy: true,
        Cell: ({ value }) => (
          <Button
            as={ReactRouterLink}
            to={`/users/${value}/`}
            colorScheme="orange"
            variant="ghost"
          >
            View
          </Button>
        ),
      },
    ],
    []
  )

  return <DataTable columns={columns} data={data} />
}

export default CoachDashboard
