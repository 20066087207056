import {
  setupRequest,
  fetchJSON,
  errorHandler,
  validURL,
  appendParamsToUrl,
} from '../lib/utils'
import {
  loadCareerMaterials,
  loadCareerMaterial,
  postCareerMaterial,
  putCareerMaterial,
} from '../redux/careerMaterials'

import { mStatusType } from '../lib/types'

function fetchCareerMaterials(params = null) {
  return async (dispatch, getState) => {
    let state = getState()
    try {
      const path = params
        ? '/career-materials?' + appendParamsToUrl(params)
        : '/career-materials'
      const { url, options } = setupRequest(path, 'GET', state)
      let json = await fetchJSON(url, options)
      if (json.description) throw Error(json.description)
      if (json.error) throw Error(json.error)
      if (!json.careerMaterials) throw Error('API_ERROR_FETCH_CAREER_MATERIALS')
      dispatch(loadCareerMaterials(json.careerMaterials))
    } catch (error) {
      errorHandler(error)
      throw error
    }
  }
}

function fetchCareerMaterial(id) {
  return async (dispatch, getState) => {
    let state = getState()
    try {
      const { url, options } = setupRequest(
        `/career-materials/${id}`,
        'GET',
        state
      )
      let json = await fetchJSON(url, options)
      if (json.description) throw Error(json.description)
      if (json.error) throw Error(json.error)
      if (!json.careerMaterial) throw Error('API_ERROR_FETCH_CAREER_MATERIAL')
      dispatch(loadCareerMaterial(json.careerMaterial))
    } catch (error) {
      errorHandler(error)
      throw error
    }
  }
}

function createCareerMaterial(data) {
  return async (dispatch, getState) => {
    let state = getState()
    try {
      data.status = mStatusType.ACTIVATED
      const body = validateFormDataCareerMaterial(data, true)
      const { url, options } = setupRequest(`/career-materials`, 'POST', state)
      options.body = body
      delete options.headers['Content-Type']
      let json = await fetchJSON(url, options)
      if (json.error) throw Error(json.error)
      if (!json.careerMaterial) throw Error('API_ERROR_CREATE_CAREER_MATERIAL')
      dispatch(postCareerMaterial(json.careerMaterial))
    } catch (error) {
      errorHandler(error)
      throw error
    }
  }
}

function updateCareerMaterial(id, data) {
  return async (dispatch, getState) => {
    let state = getState()
    try {
      validateFormDataCareerMaterial({ ...data, id })
      const { url, options } = setupRequest(
        `/career-materials/${id}`,
        'PUT',
        state
      )
      options.body = JSON.stringify(data)
      let json = await fetchJSON(url, options)
      if (json.description) throw Error(json.description)
      if (json.error) throw Error(json.error)
      if (!json.careerMaterial) throw Error('API_ERROR_UPDATE_CAREER_MATERIAL')
      dispatch(putCareerMaterial(json.careerMaterial))
    } catch (error) {
      errorHandler(error)
      throw error
    }
  }
}

function archivingCareerMaterial(id, data) {
  return async (dispatch, getState) => {
    let state = getState()
    try {
      console.log('archivingCareerMaterial', data)
      validateDataCareerMaterial({ ...data, id })
      const { url, options } = setupRequest(
        `/career-materials/${id}/archiving`,
        'PUT',
        state
      )
      options.body = JSON.stringify(data)
      let json = await fetchJSON(url, options)
      if (json.description) throw Error(json.description)
      if (json.error) throw Error(json.error)
      if (!json.careerMaterial) throw Error('API_ERROR_UPDATE_CAREER_MATERIAL')
      dispatch(putCareerMaterial(json.careerMaterial))
    } catch (error) {
      errorHandler(error)
      throw error
    }
  }
}

function validateDataCareerMaterial({
  id,
  userId,
  name,
  link,
  file,
  status,
  s3link,
}) {
  if (!id) throw Error('NO_ID_PROVIDED')
  if (!status) throw Error('NO_STATUS_REQUIRED')
  if (!userId) throw Error('NO_USER_ID_PROVIDED')
  if (!name) throw Error('Please enter a name')
  if (!link && !file && !s3link)
    throw Error('Please provide a link or upload a file')
  if (link && !validURL(link)) throw Error('Please provide a valid link')
  if ((file && !file.type) || (file && !file.name))
    throw Error('Please provide a valid file')
}

function validateFormDataCareerMaterial(
  { id, userId, name, description, link, file, status },
  created = false
) {
  if (!id && !created) throw Error('NO_ID_PROVIDED')
  if (!status) throw Error('NO_STATUS_REQUIRED')
  if (!userId) throw Error('NO_USER_ID_PROVIDED')
  if (!name) throw Error('Please enter a name')
  if (!link && !file) throw Error('Please provide a link or upload a file')
  if (link && !validURL(link)) throw Error('Please provide a valid link')
  if ((file && !file.type) || (file && !file.name))
    throw Error('Please provide a valid file')

  const formData = new FormData()
  file && formData.append(`file`, file)
  file && formData.append(`fileName`, file.name)
  file && formData.append(`fileType`, file.type)
  formData.append('name', name)
  formData.append('link', link)
  formData.append('description', description)
  formData.append('userId', userId)
  formData.append('status', status)
  !created && formData.append('id', id)
  return formData
}

export {
  fetchCareerMaterials,
  fetchCareerMaterial,
  createCareerMaterial,
  updateCareerMaterial,
  archivingCareerMaterial,
}
