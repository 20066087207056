import React, { useRef } from 'react'
import { Link as ReactRouterLink, useRouteMatch } from 'react-router-dom'
import {
  Box,
  Flex,
  Link,
  useDisclosure,
  CloseButton,
  Icon,
  useToken,
  LinkOverlay,
  LinkBox,
  Image,
} from '@chakra-ui/react'
import Container from '../common/Container'
import { useSelector } from 'react-redux'
import { userType } from '../../lib/types'
import Logo from '../../assets/images/logo.png'

import { UilBars, UilTimes } from '@iconscout/react-unicons'

const UserNavbar = (props) => {
  const menu = useRef(null)
  const currentPath = useRouteMatch().path
  const { isOpen, onOpen, onClose } = useDisclosure()
  const user = useSelector((state) => state.user.data)

  const isPathMatch = (path) => {
    return currentPath === path
  }

  const handleToggle = () => {
    return isOpen ? onClose() : onOpen()
  }

  const menuItems = [
    {
      name: 'Dashboard',
      path: '/dashboard',
    },
    {
      name: 'Account',
      path: '/account',
    },
  ]

  if (user && user.type === userType.SUPER) {
    menuItems.splice(1, 0, {
      name: 'App Settings',
      path: '/app-settings',
    })
  }

  const linkStyle = {
    fontWeight: 'semibold',
    color: 'orange.600',
    _hover: { color: 'orange.800' },
    px: '4',
    h: '20',
    alignItems: 'center',
    d: { base: 'inline-flex', lg: 'flex' },
    textDecoration: 'none',
  }

  const [orange600] = useToken('colors', ['orange.600'])

  return (
    <Box
      as="nav"
      bg="amberGray.200"
      borderBottomWidth="1px"
      borderColor="amberGray.300"
      {...props}
    >
      <Container>
        <Flex wrap="wrap" align="center">
          <Flex align="center" mr="12" h="20">
            <Image h="100%" src={Logo} alt="" py="1" />
          </Flex>

          <CloseButton
            ml="auto"
            d="flex"
            display={{ base: 'block', md: 'none' }}
            onClick={handleToggle}
          >
            {isOpen ? (
              <Icon boxSize="6" as={UilTimes} />
            ) : (
              <Icon boxSize="6" as={UilBars} />
            )}
          </CloseButton>

          <Box
            display={{ base: isOpen ? 'block' : 'none', md: 'flex' }}
            width={{ base: 'full', md: 'auto' }}
            alignItems="center"
            flexGrow="1"
            ref={menu}
          >
            {menuItems.map((link, idx) => (
              <LinkBox key={idx}>
                <Box
                  sx={
                    isPathMatch(link.path)
                      ? {
                          ...linkStyle,
                          boxShadow: `inset 0 -3px ${orange600}`,
                        }
                      : { ...linkStyle, color: 'amberGray.800' }
                  }
                >
                  <LinkOverlay
                    onClick={onClose}
                    as={ReactRouterLink}
                    to={link.path}
                    sx={isPathMatch(link.path) ? { color: 'black' } : ''}
                  >
                    {link.name}
                  </LinkOverlay>
                </Box>
              </LinkBox>
            ))}
            <Link as={ReactRouterLink} to="/logout" ml="auto" {...linkStyle}>
              Log Out
            </Link>
          </Box>
        </Flex>
      </Container>
    </Box>
  )
}

export default UserNavbar
