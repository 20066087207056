import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Input,
  Modal,
  ModalContent,
  ModalOverlay,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  VisuallyHidden,
  useDisclosure,
  ModalBody,
  ModalHeader,
  ModalCloseButton,
  ModalFooter,
} from '@chakra-ui/react'
import Container from '../components/common/Container'
import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  createMomentAttribute,
  updateMomentAttribute,
  deleteMomentAttribute,
  fetchMomentAttributes,
} from '../api/admin/momentAttributes'
import { useDispatch, useSelector } from 'react-redux'
import { settingsType } from '../lib/types'
import { formatDate } from '../lib/utils'
import DataTable from '../components/common/DataTable'
import SkeletonTable from '../components/common/SkeletonTable'
import DefaultLayout from '../layouts/default'

const UserStorySettings = () => {
  const dispatch = useDispatch()
  const settingsModal = useDisclosure()
  const [modalType, setModalType] = useState(null)
  const [isLoading, setLoading] = useState(null)

  const [
    problemAttributes,
    actionAttributes,
    accomplishmentAttributes,
    metricAttributes,
    skillAttributes,
    typeAttributes,
  ] = useSelector((state) => [
    state.momentAttributes.data &&
      Object.values(state.momentAttributes.data).filter(
        (a) => a.type === settingsType.CONTEXTS.value
      ),
    state.momentAttributes.data &&
      Object.values(state.momentAttributes.data).filter(
        (a) => a.type === settingsType.ACTIONS.value
      ),
    state.momentAttributes.data &&
      Object.values(state.momentAttributes.data).filter(
        (a) => a.type === settingsType.RESULTS.ACCOMPLISHMENTS.value
      ),
    state.momentAttributes.data &&
      Object.values(state.momentAttributes.data).filter(
        (a) => a.type === settingsType.RESULTS.METRICS.value
      ),
    state.momentAttributes.data &&
      Object.values(state.momentAttributes.data).filter(
        (a) => a.type === settingsType.STORY.SKILL.value
      ),
    state.momentAttributes.data &&
      Object.values(state.momentAttributes.data).filter(
        (a) => a.type === settingsType.STORY.TYPE.value
      ),
  ])

  const openModalHandler = (type) => {
    setModalType(type)
    settingsModal.onOpen()
  }

  const getMomentAttributes = useCallback(async () => {
    try {
      setLoading(true)
      await dispatch(fetchMomentAttributes())
      setLoading(false)
    } catch (error) {
      setLoading(false)
      alert(error)
    }
  }, [dispatch])

  useEffect(() => {
    getMomentAttributes()
  }, [getMomentAttributes])

  return (
    <DefaultLayout>
      <Container py="12">
        <Box mb="4">
          <Heading as="h2" fontSize="3xl">
            App Settings
          </Heading>
        </Box>
        <Box>
          <Tabs colorScheme="orange">
            <Box overflowX="auto" mx={{ base: '-6' }}>
              <Box
                px={{ base: '6' }}
                minW="fit-content"
                whiteSpace="nowrap"
                py="1"
              >
                <TabList>
                  {Object.values(settingsType).map((s, i) => (
                    <Tab key={i}>{s.title}</Tab>
                  ))}
                </TabList>
              </Box>
            </Box>

            <TabPanels>
              <TabPanel px="0">
                <Box overflowX="auto" mx={{ base: '-6' }}>
                  <Box px={{ base: '6' }} minW="fit-content">
                    <Box bg="white" shadow="base" mt="4" rounded="md" p="8">
                      {isLoading ? (
                        <SkeletonTable rows="5" columns="3" />
                      ) : (
                        <SettingsTable
                          momentAttributes={problemAttributes}
                          openModalHandler={openModalHandler}
                          settingsModal={settingsModal}
                          settingsTable={{
                            name: 'Challenge',
                            type: settingsType.CONTEXTS.value,
                            group: 'Problem Type',
                            value: 'Nature of Challenge',
                          }}
                        />
                      )}
                    </Box>
                  </Box>
                </Box>
              </TabPanel>
              <TabPanel px="0">
                <Box overflowX="auto" mx={{ base: '-6' }}>
                  <Box px={{ base: '6' }} minW="fit-content">
                    <Box bg="white" shadow="base" mt="4" rounded="md" p="8">
                      {isLoading ? (
                        <SkeletonTable rows="5" columns="3" />
                      ) : (
                        <SettingsTable
                          momentAttributes={actionAttributes}
                          openModalHandler={openModalHandler}
                          settingsModal={settingsModal}
                          settingsTable={{
                            name: 'Action',
                            type: settingsType.ACTIONS.value,
                            group: 'Skill Group',
                            value: 'Action Verb',
                          }}
                        />
                      )}
                    </Box>
                  </Box>
                </Box>
              </TabPanel>
              <TabPanel px="0">
                <Grid mt="4" gap="6" templateColumns={{ lg: 'repeat(2, 1fr)' }}>
                  <GridItem overflowX="auto" mx={{ base: '-6' }}>
                    <Box px={{ base: '6' }} minW="fit-content">
                      <Box bg="white" shadow="base" rounded="md" p="8">
                        {isLoading ? (
                          <SkeletonTable rows="5" columns="3" />
                        ) : (
                          <SettingsTable
                            momentAttributes={accomplishmentAttributes}
                            openModalHandler={openModalHandler}
                            settingsModal={settingsModal}
                            settingsTable={{
                              name: 'Accomplishment',
                              type: settingsType.RESULTS.ACCOMPLISHMENTS.value,
                              value: 'Accomplishment Verb',
                            }}
                          />
                        )}
                      </Box>
                    </Box>
                  </GridItem>
                  <GridItem overflowX="auto" mx={{ base: '-6' }}>
                    <Box px={{ base: '6' }} minW="fit-content">
                      <Box bg="white" shadow="base" rounded="md" p="8">
                        {isLoading ? (
                          <SkeletonTable rows="5" columns="3" />
                        ) : (
                          <SettingsTable
                            momentAttributes={metricAttributes}
                            openModalHandler={openModalHandler}
                            settingsModal={settingsModal}
                            settingsTable={{
                              name: 'Metric',
                              type: settingsType.RESULTS.METRICS.value,
                              value: 'Metric Type',
                            }}
                          />
                        )}
                      </Box>
                    </Box>
                  </GridItem>
                </Grid>
              </TabPanel>
              <TabPanel px="0">
                <Grid mt="4" gap="6" templateColumns={{ lg: 'repeat(2, 1fr)' }}>
                  <GridItem overflowX="auto" mx={{ base: '-6' }}>
                    <Box px={{ base: '6' }} minW="fit-content">
                      <Box bg="white" shadow="base" rounded="md" p="8" w="100%">
                        {isLoading ? (
                          <SkeletonTable rows="5" columns="3" />
                        ) : (
                          <SettingsTable
                            momentAttributes={typeAttributes}
                            openModalHandler={openModalHandler}
                            settingsModal={settingsModal}
                            settingsTable={{
                              name: 'Type',
                              type: settingsType.STORY.TYPE.value,
                              value: 'Type',
                            }}
                          />
                        )}
                      </Box>
                    </Box>
                  </GridItem>
                  <GridItem overflowX="auto" mx={{ base: '-6' }}>
                    <Box px={{ base: '6' }} minW="fit-content">
                      <Box bg="white" shadow="base" rounded="md" p="8" w="100%">
                        {isLoading ? (
                          <SkeletonTable rows="5" columns="3" />
                        ) : (
                          <SettingsTable
                            momentAttributes={skillAttributes}
                            openModalHandler={openModalHandler}
                            settingsModal={settingsModal}
                            settingsTable={{
                              name: 'Skill',
                              type: settingsType.STORY.SKILL.value,
                              value: 'Skill',
                            }}
                          />
                        )}
                      </Box>
                    </Box>
                  </GridItem>
                </Grid>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
      </Container>
      <Modal isOpen={settingsModal.isOpen} onClose={settingsModal.onClose}>
        <ModalOverlay />
        <ModalContent>{modalType}</ModalContent>
      </Modal>
    </DefaultLayout>
  )
}

const SettingsModal = ({
  closeModalHandler,
  momentAttribute,
  settingsTable,
}) => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)

  const [value, setValue] = useState(
    (momentAttribute && momentAttribute.value) || ''
  )
  const [group, setGroup] = useState(
    (momentAttribute && momentAttribute.group) || ''
  )

  const deleteHandler = () => {
    try {
      var answer = window.confirm('Are you sure you want to delete?')
      if (answer !== true) return
      dispatch(deleteMomentAttribute(momentAttribute.id))
      closeModalHandler()
    } catch (error) {
      alert(error)
    }
  }

  const submitHandler = async (e) => {
    e.preventDefault()

    try {
      setIsLoading(true)
      if (momentAttribute) {
        await dispatch(
          updateMomentAttribute(momentAttribute.id, {
            group,
            value,
            type: settingsTable.type,
          })
        )
      } else {
        await dispatch(
          createMomentAttribute({ group, value, type: settingsTable.type })
        )
      }
      setIsLoading(false)
      closeModalHandler()
    } catch (error) {
      setIsLoading(false)
      alert(error)
    }
  }

  return (
    <>
      <ModalHeader>
        {momentAttribute ? 'Edit' : 'Add'} {settingsTable.name}
      </ModalHeader>
      <ModalCloseButton />
      <form onSubmit={submitHandler}>
        <ModalBody>
          <Grid
            templateColumns={settingsTable.group ? 'repeat(2, 1fr)' : null}
            gap="4"
          >
            {settingsTable.group && (
              <GridItem>
                <FormControl id="group">
                  <FormLabel>{settingsTable.group}</FormLabel>
                  <Input
                    type="text"
                    value={group}
                    onChange={(e) => setGroup(e.target.value)}
                  />
                </FormControl>
              </GridItem>
            )}
            <GridItem>
              <FormControl id="value">
                <FormLabel>{settingsTable.value}</FormLabel>
                <Input
                  type="text"
                  value={value}
                  onChange={(e) => setValue(e.target.value)}
                />
              </FormControl>
            </GridItem>
          </Grid>
        </ModalBody>
        <ModalFooter>
          {momentAttribute && (
            <Button colorScheme="red" variant="ghost" onClick={deleteHandler}>
              Delete
            </Button>
          )}
          <Flex ml="auto">
            <Button
              mr="3"
              variant="ghost"
              isLoading={isLoading}
              onClick={closeModalHandler}
            >
              Cancel
            </Button>
            <Button
              isLoading={false}
              loadingText="Saving"
              colorScheme="orange"
              type="submit"
            >
              {momentAttribute ? 'Update' : 'Save'}
            </Button>
          </Flex>
        </ModalFooter>
      </form>
    </>
  )
}

const SettingsTable = ({
  momentAttributes,
  openModalHandler,
  settingsModal,
  settingsTable,
}) => {
  const headers = useMemo(() => {
    let data = [{ Header: settingsTable.value, accessor: 'value' }] || []
    if (settingsTable.group)
      data.unshift({ Header: settingsTable.group, accessor: 'group' })
    return data
  }, [settingsTable.group, settingsTable.value])

  const data = useMemo(() => momentAttributes || [], [momentAttributes])
  const columns = useMemo(
    () => [
      ...headers,
      {
        Header: 'Created',
        accessor: (d) => formatDate(d.createdAt, 'MM/DD/YY'),
      },
      {
        id: 'modify',
        accessor: (row) => row,
        Cell: ({ value: momentAttribute }) => (
          <Button
            variant="ghost"
            colorScheme="orange"
            onClick={() =>
              openModalHandler(
                <SettingsModal
                  momentAttribute={momentAttribute}
                  closeModalHandler={settingsModal.onClose}
                  settingsTable={settingsTable}
                />
              )
            }
          >
            Edit
          </Button>
        ),
        disableSortBy: true,
      },
    ],
    [headers, openModalHandler, settingsModal.onClose, settingsTable]
  )
  const [search, setSearch] = useState('')

  return (
    <Box>
      <Grid templateColumns="repeat(12, 1fr)" gap="6" mb="6">
        <GridItem colSpan="6">
          <FormControl id="email">
            <FormLabel as={VisuallyHidden}>Search</FormLabel>
            <Input
              type="search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Search..."
            />
          </FormControl>
        </GridItem>
        <GridItem colSpan="6" d="flex">
          <Button
            colorScheme="orange"
            ml="auto"
            onClick={() =>
              openModalHandler(
                <SettingsModal
                  momentAttribute={null}
                  closeModalHandler={settingsModal.onClose}
                  settingsTable={settingsTable}
                />
              )
            }
          >
            Add
          </Button>
        </GridItem>
      </Grid>
      <DataTable columns={columns} data={data} search={search} />
    </Box>
  )
}

export default UserStorySettings
