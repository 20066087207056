import React from 'react'
import {
  Grid,
  GridItem,
  Box,
  Button,
  Flex,
  Text,
  FormLabel,
  FormControl,
  Textarea,
  useToken,
  Stack,
  StackDivider,
  IconButton,
  Icon,
} from '@chakra-ui/react'

import CreatableSelect from 'react-select/creatable'
import { useSelector } from 'react-redux'
import { settingsType } from '../../lib/types'
import { UilTrashAlt } from '@iconscout/react-unicons'
import { useFieldArray, useFormContext, Controller } from 'react-hook-form'

const ResultsDetails = ({
  onAppendOrRemove,
  loadingMomentAttributes,
  setTabIndex,
  tabIndex,
  setUnsavedField,
}) => {
  const { register } = useFormContext()
  const { fields, append, remove } = useFieldArray({
    name: 'results',
  })

  const handleAppend = () => {
    onAppendOrRemove(1)
    append({ name: '', type: '', statement: '' })
  }

  const handleRemove = (idx) => {
    onAppendOrRemove(-1)
    remove(idx)
  }

  const handleBack = (prevTab) => {
    setTabIndex(prevTab)
  }

  const handleNext = (nextTab) => {
    setTabIndex(nextTab)
  }

  return (
    <Grid templateColumns={{ lg: 'repeat(12, 1fr)' }} gap="6">
      <GridItem order={{ lg: '2' }} colSpan={{ lg: '4' }}>
        <Box
          bg="amberGray.75"
          p="4"
          rounded="md"
          fontSize="sm"
          position="sticky"
          top="6"
        >
          <Text fontWeight="semibold">Pro Tip:</Text>
          <Text>
            Results that are measurable are more impactful than broad
            statements. For example, instead of saying “this solution brought in
            new business”, you might say “the implementation of this solution
            generated 20 new leads in the first month, resulting in 6 new
            clients. and $1 million in additional revenue.
          </Text>
        </Box>
      </GridItem>
      <GridItem order={{ lg: '1' }} colSpan={{ lg: '8' }}>
        <Box mt="2" mb="12">
          <Text fontSize="lg" fontWeight="medium">
            Positive or negative, your actions generated results. Let's document
            the outcomes of your efforts next.
            <br />
            <br />
            <b>Note:</b> One action may generate multiple results, or a series
            of actions may have led to one result; add as many results as
            required to accurately reflect the moment.
          </Text>
        </Box>
        <Stack
          spacing="6"
          divider={<StackDivider borderColor="amberGray.200" />}
        >
          {fields &&
            fields.map((result, idx) => (
              <Box key={result.id}>
                {fields.length > 1 && (
                  <Flex>
                    <IconButton
                      variant="ghost"
                      colorScheme="red"
                      ml="auto"
                      icon={<Icon h="6" w="6" as={UilTrashAlt} />}
                      onClick={() => handleRemove(idx)}
                    />
                  </Flex>
                )}
                <Grid
                  templateColumns={{
                    lg: 'repeat(2, 1fr)',
                  }}
                  gap="6"
                >
                  <GridItem colSpan="1">
                    <FormControl id={`accomplishmentVerb-${idx}`}>
                      <FormLabel>
                        Which verb best describes the result of your actions?
                      </FormLabel>
                      <CustomCreatableSelectName
                        idx={idx}
                        result={result}
                        loadingMomentAttributes={loadingMomentAttributes}
                        setUnsavedField={setUnsavedField}
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem colSpan="1">
                    <FormControl id={`metricType-${idx}`}>
                      <FormLabel>
                        Which metric best describes the outcome?
                      </FormLabel>
                      <CustomCreatableSelectType
                        idx={idx}
                        result={result}
                        loadingMomentAttributes={loadingMomentAttributes}
                        setUnsavedField={setUnsavedField}
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem colSpan={{ lg: '2' }}>
                    <FormControl id={`resultStatement-${idx}`}>
                      <FormLabel>
                        With your above selections in mind, describe in greater
                        detail the end result of your actions. You might wish to
                        include what the result would have been without the
                        benefit your actions.
                      </FormLabel>
                      <Textarea
                        rows="6"
                        {...register(`results.${idx}.statement`)}
                        defaultValue={result.statement}
                        onChange={(e) =>
                          e.target.value
                            ? setUnsavedField(true)
                            : setUnsavedField(false)
                        }
                      />
                    </FormControl>
                  </GridItem>
                </Grid>
              </Box>
            ))}
        </Stack>
        <Flex mt="4">
          <Button
            variant="outline"
            colorScheme="orange"
            onClick={() => handleBack(1)}
          >
            Back
          </Button>
          <Button mx="auto" colorScheme="orange" onClick={handleAppend}>
            Add Another Result
          </Button>
          <Button
            variant="outline"
            colorScheme="orange"
            onClick={() => handleNext(3)}
          >
            Next
          </Button>
        </Flex>
      </GridItem>
    </Grid>
  )
}

const CustomCreatableSelectName = ({
  result,
  loadingMomentAttributes,
  idx,
  setUnsavedField,
}) => {
  const accomplishmentAttributes =
    useSelector(
      (state) =>
        state.momentAttributes.data &&
        Object.values(state.momentAttributes.data).filter(
          (a) => a.type === settingsType.RESULTS.ACCOMPLISHMENTS.value
        )
    ) || []

  const { control } = useFormContext()
  const [amberGray50, amberGray100, amberGray200] = useToken('colors', [
    'amberGray.50',
    'amberGray.100',
    'amberGray.200',
  ])

  return (
    <Controller
      control={control}
      name={`results.${idx}.name`}
      defaultValue={result ? result.name : null}
      render={({ field: { value, onChange } }) => (
        <CreatableSelect
          components={{ DropdownIndicator: () => null }}
          formatCreateLabel={(input) => `Choose "${input}"`}
          styles={{
            control: (provided, state) => ({
              ...provided,
              backgroundColor: state.isDisabled ? amberGray100 : amberGray50,
              cursor: 'text',
            }),
            menu: (provided) => ({
              ...provided,
              borderColor: amberGray200,
              backgroundColor: amberGray50,
            }),
            menuList: (provided) => ({
              ...provided,
              borderColor: amberGray200,
            }),
            option: (provided) => ({
              ...provided,
              '&:hover': {
                backgroundColor: amberGray100,
              },
              color: 'black',
              backgroundColor: amberGray50,
            }),
            indicatorSeparator: () => ({
              display: 'none',
            }),
          }}
          isDisabled={loadingMomentAttributes}
          isClearable
          placeholder={
            loadingMomentAttributes ? 'Loading...' : 'Type or select option'
          }
          options={accomplishmentAttributes
            .sort((a, b) =>
              a.value > b.value ? 1 : b.value > a.value ? -1 : 0
            )
            .map((a) => ({
              value: a.value,
              label: a.value,
            }))}
          value={
            value && {
              value: value,
              label: value,
            }
          }
          onChange={(e) => {
            e && e.value ? setUnsavedField(true) : setUnsavedField(false)
            onChange(e && e.value)
          }}
        />
      )}
    />
  )
}

const CustomCreatableSelectType = ({
  result,
  loadingMomentAttributes,
  idx,
  setUnsavedField,
}) => {
  const metricAttributes =
    useSelector(
      (state) =>
        state.momentAttributes.data &&
        Object.values(state.momentAttributes.data).filter(
          (a) => a.type === settingsType.RESULTS.METRICS.value
        )
    ) || []

  const { control } = useFormContext()
  const [amberGray50, amberGray100, amberGray200] = useToken('colors', [
    'amberGray.50',
    'amberGray.100',
    'amberGray.200',
  ])

  return (
    <Controller
      control={control}
      name={`results.${idx}.type`}
      defaultValue={result ? result.type : null}
      render={({ field: { value, onChange } }) => (
        <CreatableSelect
          components={{ DropdownIndicator: () => null }}
          formatCreateLabel={(input) => `Choose "${input}"`}
          styles={{
            control: (provided, state) => ({
              ...provided,
              backgroundColor: state.isDisabled ? amberGray100 : amberGray50,
              cursor: 'text',
            }),
            menu: (provided) => ({
              ...provided,
              borderColor: amberGray200,
              backgroundColor: amberGray50,
            }),
            menuList: (provided) => ({
              ...provided,
              borderColor: amberGray200,
            }),
            option: (provided) => ({
              ...provided,
              '&:hover': {
                backgroundColor: amberGray100,
              },
              color: 'black',
              backgroundColor: amberGray50,
            }),
            indicatorSeparator: () => ({
              display: 'none',
            }),
          }}
          isDisabled={loadingMomentAttributes}
          isClearable
          placeholder={
            loadingMomentAttributes ? 'Loading...' : 'Type or select option'
          }
          options={metricAttributes
            .sort((a, b) =>
              a.value > b.value ? 1 : b.value > a.value ? -1 : 0
            )
            .map((a) => ({
              value: a.value,
              label: a.value,
            }))}
          value={
            value && {
              value: value,
              label: value,
            }
          }
          onChange={(e) => {
            e && e.value ? setUnsavedField(true) : setUnsavedField(false)
            onChange(e && e.value)
          }}
        />
      )}
    />
  )
}

export default ResultsDetails
