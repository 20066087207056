import React from 'react'
import { Provider } from 'react-redux'
import { persistor, store } from './redux/store'
import { PersistGate } from 'redux-persist/integration/react'
import { ChakraProvider, extendTheme } from '@chakra-ui/react'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import RootNavigation from './navigation/RootNavigation'
import customTheme from './customTheme'
import * as Sentry from '@sentry/react'

import '@fontsource/rubik' // Temp fix
import { BrowserRouter as Router } from 'react-router-dom'

// import '@fontsource/rubik/400.css'
// import '@fontsource/rubik/500.css'
// import '@fontsource/rubik/600.css'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY)
const theme = extendTheme(customTheme)

const App = () => (
  <ChakraProvider theme={theme}>
    <Elements stripe={stripePromise}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Router>
            <RootNavigation />
          </Router>
        </PersistGate>
      </Provider>
    </Elements>
  </ChakraProvider>
)

export default Sentry.withProfiler(App)
