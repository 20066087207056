import {
  setupRequest,
  fetchJSON,
  appendParamsToUrl,
  errorHandler,
} from '../lib/utils'
import { csStatusType } from '../lib/types'
import {
  loadCareerStories,
  loadCareerStory,
  postCareerStory,
  putCareerStory,
} from '../redux/careerStories'

function fetchCareerStories(params = null) {
  return async (dispatch, getState) => {
    let state = getState()
    try {
      const path = params
        ? '/career-stories?' + appendParamsToUrl(params)
        : '/career-stories'
      const { url, options } = setupRequest(path, 'GET', state)
      let json = await fetchJSON(url, options)
      if (json.description) throw Error(json.description)
      if (json.error) throw Error(json.error)
      if (!json.careerStories) throw Error('API_ERROR_FETCH_CAREER_STORIES')
      dispatch(loadCareerStories(json.careerStories))
    } catch (error) {
      errorHandler(error)
      throw error
    }
  }
}

function fetchCareerStory(id) {
  return async (dispatch, getState) => {
    let state = getState()
    try {
      const { url, options } = setupRequest(
        `/career-stories/${id}`,
        'GET',
        state
      )
      let json = await fetchJSON(url, options)
      if (json.description) throw Error(json.description)
      if (json.error) throw Error(json.error)
      if (!json.careerStory) throw Error('API_ERROR_FETCH_CAREER_STORY')
      dispatch(loadCareerStory(json.careerStory))
    } catch (error) {
      errorHandler(error)
      throw error
    }
  }
}

function createCareerStory(data) {
  return async (dispatch, getState) => {
    let state = getState()
    try {
      const { url, options } = setupRequest(`/career-stories`, 'POST', state)
      const careerStory = verificationData(data)
      options.body = JSON.stringify(careerStory)
      let json = await fetchJSON(url, options)
      if (json.description) throw Error(json.description)
      if (json.error) throw Error(json.error)
      if (!json.careerStory) throw Error('API_ERROR_CREATE_CAREER_STORY')
      dispatch(postCareerStory(json.careerStory))
      return json.careerStory
    } catch (error) {
      errorHandler(error)
      throw error
    }
  }
}

function updateCareerStory(id, data) {
  return async (dispatch, getState) => {
    let state = getState()
    try {
      const { url, options } = setupRequest(
        `/career-stories/${id}`,
        'PUT',
        state
      )
      const careerStory = verificationData(data)
      options.body = JSON.stringify(careerStory)
      let json = await fetchJSON(url, options)
      if (json.description) throw Error(json.description)
      if (json.error) throw Error(json.error)
      if (!json.careerStory) throw Error('API_ERROR_UPDATE_CAREER_STORY')
      dispatch(putCareerStory(json.careerStory))
      return json.careerStory
    } catch (error) {
      errorHandler(error)
      throw error
    }
  }
}

function verificationData(data) {
  const errors = []
  if (!data.careerMomentId) throw Error('NO_CAREER_MOMENT_ID_PROVIDED')
  if (!data.userId) throw Error('NO_USER_ID_PROVIDED')
  if (!data.title) errors.push('Please enter a Title')
  if (!data.skill) errors.push('Please enter a Skill')
  if (!data.content) errors.push('Please enter text in Story')
  if (!data.status) throw Error('NO_STATUS_PROVIDED')
  if (!Object.values(csStatusType).includes(data.status))
    throw Error('WRONG_STATUS_FORMAT')
  if (errors.length) throw Error(errors.join('\n'))
  return data
}

export {
  fetchCareerStories,
  fetchCareerStory,
  createCareerStory,
  updateCareerStory,
}
