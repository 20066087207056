import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import {
  Grid,
  GridItem,
  Box,
  Button,
  Heading,
  FormLabel,
  Input,
  FormControl,
} from '@chakra-ui/react'
import { useDispatch, useSelector } from 'react-redux'
import Container from '../components/common/Container'
import { userType } from '../lib/types'
import { updateAdmin } from '../api/admin/users'
import { updateUser } from '../api/user'
import isEqual from 'lodash/isEqual'
import DefaultLayout from '../layouts/default'

const UserAccountSettings = () => {
  const dispatch = useDispatch()
  const { userId } = useParams()

  const user = useSelector((state) =>
    userId ? state.users.admins && state.users.admins[userId] : state.user.data
  )
  const [loadingUpdate, setLoadingUpdate] = useState(false)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [industry, setIndustry] = useState('')

  useEffect(() => {
    if (!user) return
    setFirstName(user.firstName || '')
    setLastName(user.lastName || '')
    setEmail(user.email || '')
    setIndustry(user.industry || '')
  }, [user])

  const hasDataChanged = () =>
    isEqual(
      [firstName, lastName, email, industry],
      [
        user.firstName || '',
        user.lastName || '',
        user.email || '',
        user.industry || '',
      ]
    )

  const updateHandler = async () => {
    try {
      setLoadingUpdate(true)
      await dispatch(
        userId
          ? updateAdmin(user.id, { firstName, lastName, email, industry })
          : updateUser(user.id, { firstName, lastName, email, industry })
      )
      setLoadingUpdate(false)
      alert('Success')
    } catch (error) {
      setLoadingUpdate(false)
      alert(error)
    }
  }

  return (
    <DefaultLayout>
      <Container py="12">
        <Grid py="6" templateColumns={{ lg: 'repeat(12, 1fr)' }} gap="6">
          <GridItem colStart={{ lg: '4' }} colSpan={{ lg: '6' }}>
            <Box shadow="base" p="6" bg="white" rounded="md">
              <Box mb="4">
                <Heading as="h2" fontSize="3xl">
                  Account Settings
                </Heading>
              </Box>
              <Grid templateColumns={{ lg: 'repeat(2, 1fr)' }} gap="4">
                <GridItem colSpan="1">
                  <FormControl id="firstName">
                    <FormLabel>First Name</FormLabel>
                    <Input
                      type="text"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                  </FormControl>
                </GridItem>
                <GridItem colSpan="1">
                  <FormControl id="lastName">
                    <FormLabel>Last Name</FormLabel>
                    <Input
                      type="text"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </FormControl>
                </GridItem>
                <GridItem colSpan="1">
                  <FormControl id="email">
                    <FormLabel>Email</FormLabel>
                    <Input
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </FormControl>
                </GridItem>
                <GridItem colSpan="1">
                  {user.type === userType.ADMIN && (
                    <FormControl id="industry">
                      <FormLabel>Industry</FormLabel>
                      <Input
                        type="text"
                        value={industry}
                        onChange={(e) => setIndustry(e.target.value)}
                      />
                    </FormControl>
                  )}
                </GridItem>
                <GridItem colSpan={{ lg: '2' }} d="flex">
                  <Button
                    type="submit"
                    loadingText="Saving"
                    colorScheme="orange"
                    ml="auto"
                    onClick={updateHandler}
                    isLoading={loadingUpdate}
                    isDisabled={hasDataChanged()}
                  >
                    Update
                  </Button>
                </GridItem>
              </Grid>
            </Box>
          </GridItem>
        </Grid>
      </Container>
    </DefaultLayout>
  )
}

export default UserAccountSettings
