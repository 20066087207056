import {
  Box,
  Flex,
  Icon,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import { useTable, useSortBy, useGlobalFilter } from 'react-table'
import {
  UilDirection,
  UilAngleUp,
  UilAngleDown,
} from '@iconscout/react-unicons'
import { useEffect } from 'react'

const DataTable = ({ columns, data, search, size }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setGlobalFilter,
  } = useTable({ columns, data }, useGlobalFilter, useSortBy)

  useEffect(() => {
    setGlobalFilter(search || '')
  }, [search, setGlobalFilter, columns])

  return (
    <Table size={size} {...getTableProps()}>
      <Thead>
        {headerGroups.map((headerGroup) => (
          <Tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <Th
                whiteSpace="nowrap"
                isNumeric={column.isNumeric}
                {...column.getHeaderProps(column.getSortByToggleProps())}
              >
                <Flex
                  alignItems="center"
                  justifyContent={column.isNumeric ? 'flex-end' : null}
                >
                  <Box as="span">{column.render('Header')}</Box>
                  {!column.disableSortBy && (
                    <Box pl="2">
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <Icon
                            h="6"
                            w="6"
                            as={UilAngleDown}
                            aria-label="Sorted descending"
                          />
                        ) : (
                          <Icon
                            h="6"
                            w="6"
                            as={UilAngleUp}
                            aria-label="Sorted ascending"
                          />
                        )
                      ) : (
                        <Icon
                          h="6"
                          w="6"
                          as={UilDirection}
                          aria-label="Not sorted"
                        />
                      )}
                    </Box>
                  )}
                </Flex>
              </Th>
            ))}
          </Tr>
        ))}
      </Thead>
      <Tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row)
          return (
            <Tr {...row.getRowProps()}>
              {row.cells.map((cell) => (
                <Td {...cell.getCellProps()} isNumeric={cell.column.isNumeric}>
                  {cell.render('Cell')}
                </Td>
              ))}
            </Tr>
          )
        })}
      </Tbody>
    </Table>
  )
}

export default DataTable
