/* eslint-disable no-sequences */
// Actions
const LOAD = 'careerMaterials/LOAD'
const LOAD_UNIQUE = 'careerMaterials/LOAD_UNIQUE'
const CREATE = 'careerMaterials/CREATE'
const UPDATE = 'careerMaterials/UPDATE'
const REMOVE = 'careerMaterials/REMOVE'
const CLEAR_CACHE = 'careerMaterials/CLEAR_CACHE'

// Reducer
const initialState = {
  data: null,
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD: {
      return {
        ...state,
        data: action.careerMaterials.reduce((a, b) => ((a[b.id] = b), a), {}),
      }
    }
    case LOAD_UNIQUE:
    case CREATE: {
      return {
        ...state,
        data: {
          ...state.data,
          [action.careerMaterial.id]: action.careerMaterial,
        },
      }
    }
    case UPDATE: {
      return {
        ...state,
        data: {
          ...state.data,
          [action.careerMaterial.id]: {
            ...state.data[action.careerMaterial.id],
            ...action.careerMaterial,
          },
        },
      }
    }
    case REMOVE:
      return initialState
    case CLEAR_CACHE:
      return initialState
    default:
      return state
  }
}

// Action Creators
export function loadCareerMaterials(careerMaterials) {
  return { type: LOAD, careerMaterials }
}

export function loadCareerMaterial(careerMaterial) {
  return { type: LOAD_UNIQUE, careerMaterial }
}

export function postCareerMaterial(careerMaterial) {
  return { type: CREATE, careerMaterial }
}

export function putCareerMaterial(careerMaterial) {
  return { type: UPDATE, careerMaterial }
}

export function removeCareerMaterial() {
  return { type: REMOVE }
}
