import React, { useCallback, useEffect, useMemo, useState } from 'react'

import {
  Grid,
  GridItem,
  Box,
  Button,
  Flex,
  Heading,
  Text,
  Avatar,
  FormLabel,
  Input,
  FormControl,
  Modal,
  ModalOverlay,
  ModalContent,
  useDisclosure,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  useToken,
} from '@chakra-ui/react'
import {
  countMonths,
  formatDate,
  formatPrice,
  isFreeUser,
  sortFormatDate,
} from '../lib/utils'
import { updateUser } from '../api/user'
import { useDispatch, useSelector } from 'react-redux'
import Container from '../components/common/Container'
import { subscriptionType } from '../lib/types'
import SubscriptionRadioList from '../components/SubscriptionRadioList'
import { fetchPayments } from '../api/payments'
import DataTable from '../components/common/DataTable'
import SkeletonTable from '../components/common/SkeletonTable'
import { putSubscription, deleteSubscription } from '../api/stripe'
import isEqual from 'lodash/isEqual'
import SubscriptionModal from '../components/SubscriptionModal'
import DefaultLayout from '../layouts/default'

const UserAccountSettings = () => {
  const dispatch = useDispatch()
  const user = useSelector((state) => state.user.data)
  const payments = useSelector((state) =>
    state.payments.data ? Object.values(state.payments.data) : []
  )
  const [orange600] = useToken('colors', ['orange.600'])
  const [isPaymentsLoading, setIsPaymentsLoading] = useState(false)

  const getPayments = useCallback(async () => {
    try {
      setIsPaymentsLoading(true)
      await dispatch(fetchPayments({ userId: user.id }))
      setIsPaymentsLoading(false)
    } catch (error) {
      setIsPaymentsLoading(false)
      alert(error)
    }
  }, [dispatch, user])

  useEffect(() => {
    getPayments()
  }, [getPayments])

  return (
    <DefaultLayout>
      <Container py="12">
        <Box>
          <Heading as="h1">My Account</Heading>
        </Box>
        <Tabs colorScheme="orange" py="6">
          <TabList pt="2" borderBottomWidth="1px">
            {[{ label: 'Account Details' }, { label: 'Payment History' }].map(
              (tab, idx) => (
                <Tab
                  key={idx}
                  fontWeight="semibold"
                  color="amberGray.700"
                  _selected={{
                    color: 'black',
                    boxShadow: `inset 0 -3px ${orange600}`,
                  }}
                >
                  {tab.label}
                </Tab>
              )
            )}
          </TabList>

          <TabPanels>
            <TabPanel px="0" py="6">
              <SettingsDetails />
            </TabPanel>
            <TabPanel px="0" py="6">
              <Box overflowX="auto" mx={{ base: '-6' }}>
                <Box px={{ base: '6' }} minW="fit-content" pb="3">
                  <Box p="6" bg="white" rounded="md" shadow="base">
                    {!isPaymentsLoading && payments ? (
                      <PaymentsTable payments={payments} />
                    ) : (
                      <SkeletonTable />
                    )}
                  </Box>
                </Box>
              </Box>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Container>
    </DefaultLayout>
  )
}

const SettingsDetails = () => {
  const dispatch = useDispatch()
  const [isUpdating, setIsUpdating] = useState(false)
  const user = useSelector((state) => state.user.data)

  const modalState = useDisclosure()
  const [modalType, setModalType] = useState(null)

  const [form, setForm] = useState({
    firstName: user.firstName || '',
    lastName: user.lastName || '',
    email: user.email || '',
  })

  const openModalHandler = (type) => {
    setModalType(type)
    modalState.onOpen()
  }

  const getSubscriptionType = () => {
    return Object.values(subscriptionType).find(
      (st) => st.value === user.subscriptionTier
    )
  }

  const updateHandler = async () => {
    try {
      setIsUpdating(true)
      await dispatch(updateUser(user.id, form))
      setIsUpdating(false)
      alert('Success')
    } catch (error) {
      setIsUpdating(false)
      alert(error)
    }
  }

  return (
    <>
      <Grid templateColumns={{ lg: 'repeat(12, 1fr)' }} gap="6">
        <GridItem colSpan="6">
          <Box p="6" bg="white" rounded="md" shadow="base">
            <Box mb="4">
              <Heading as="h2" fontSize="3xl">
                Settings
              </Heading>
            </Box>
            <Grid gap="4">
              <GridItem>
                <FormControl id="firstName">
                  <FormLabel>First Name</FormLabel>
                  <Input
                    type="text"
                    value={form.firstName}
                    onChange={(e) =>
                      setForm({
                        ...form,
                        firstName: e.target.value,
                      })
                    }
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl id="lastName">
                  <FormLabel>Last Name</FormLabel>
                  <Input
                    type="text"
                    value={form.lastName}
                    onChange={(e) =>
                      setForm({
                        ...form,
                        lastName: e.target.value,
                      })
                    }
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl id="email">
                  <FormLabel>Email</FormLabel>
                  <Input
                    type="email"
                    value={form.email}
                    onChange={(e) =>
                      setForm({
                        ...form,
                        email: e.target.value,
                      })
                    }
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <Button
                  loadingText="Saving"
                  colorScheme="orange"
                  type="submit"
                  onClick={updateHandler}
                  isLoading={isUpdating}
                  isDisabled={isEqual(
                    {
                      firstName: user.firstName,
                      lastName: user.lastName,
                      email: user.email,
                    },
                    form
                  )}
                >
                  Update
                </Button>
              </GridItem>
            </Grid>
          </Box>
        </GridItem>
        <GridItem colSpan="6">
          <Box p="6" bg="white" rounded="md" shadow="base">
            <Box mb="4">
              <Flex align="center">
                <Heading as="h2" fontSize="3xl">
                  Subscription
                </Heading>
                {user && isFreeUser(user) ? (
                  <Button
                    ml="auto"
                    colorScheme="orange"
                    variant="ghost"
                    onClick={() =>
                      openModalHandler(
                        <SubscriptionModal
                          closeModalHandler={modalState.onClose}
                        />
                      )
                    }
                  >
                    Subscribe
                  </Button>
                ) : (
                  <Button
                    ml="auto"
                    colorScheme="orange"
                    variant="ghost"
                    onClick={() =>
                      openModalHandler(
                        <SubscriptionSettings
                          closeModalHandler={modalState.onClose}
                        />
                      )
                    }
                  >
                    Modify
                  </Button>
                )}
              </Flex>
            </Box>
            <Flex flexGrow="1" align="center">
              <Avatar
                name={`${user.firstName} ${user.lastName}`}
                mr="4"
                bg="teal.500"
              />
              <Box>
                <Text fontWeight="semibold">
                  {isFreeUser(user) ? 'No' : getSubscriptionType().title}{' '}
                  Subscription
                </Text>
                <Text color="warmGray.600">
                  User for {countMonths(user.createdAt)} months
                </Text>
              </Box>
            </Flex>
          </Box>
        </GridItem>
      </Grid>
      <Modal size="xl" isOpen={modalState.isOpen} onClose={modalState.onClose}>
        <ModalOverlay />
        <ModalContent>{modalType}</ModalContent>
      </Modal>
    </>
  )
}

const PaymentsTable = ({ payments }) => {
  const tableData = useMemo(
    () =>
      payments.sort(
        (a, b) => sortFormatDate(b.createdAt) - sortFormatDate(a.createdAt)
      ),
    [payments]
  )
  const columns = useMemo(
    () => [
      {
        Header: 'Product',
        accessor: 'name',
      },
      {
        Header: 'Price',
        accessor: 'price',
        Cell: ({ value: price }) => formatPrice(price),
      },
      {
        Header: 'Purchase Date',
        accessor: 'createdAt',
        isNumeric: true,
        Cell: ({ value: createdAt }) => formatDate(createdAt),
      },
    ],
    []
  )

  return (
    <Box>
      <DataTable columns={columns} data={tableData} />
      {(!tableData || !tableData.length) && (
        <Box mt="4">
          <Text textAlign="center" fontSize="lg" fontStyle="italic">
            No Payment History
          </Text>
        </Box>
      )}
    </Box>
  )
}

const ConfirmCancelModal = ({ closeModalHandler, doubleCloseModal }) => {
  const user = useSelector((state) => state.user.data)
  const dispatch = useDispatch()
  const [isBusy, setIsBusy] = useState(false)

  const handleCancelSubscription = async () => {
    try {
      setIsBusy(true)
      await dispatch(deleteSubscription(user.id))
      closeModalHandler()
      doubleCloseModal()
    } catch (error) {
      setIsBusy(false)
      alert(error)
    }
  }

  return (
    <>
      <ModalHeader>Cancel Subscription</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <Text fontWeight="semibold" mb="4">
          Are you sure you want to cancel your subscription?
        </Text>
        <Text>
          You will lose access to premium services including your dedicated
          Career Coach and your Career Stories.
        </Text>
      </ModalBody>
      <ModalFooter>
        <Button
          colorScheme="orange"
          variant="ghost"
          mr="3"
          onClick={closeModalHandler}
        >
          Never Mind
        </Button>
        <Button
          isLoading={isBusy}
          loadingText="Canceling..."
          colorScheme="red"
          onClick={handleCancelSubscription}
        >
          Yes, Cancel
        </Button>
      </ModalFooter>
    </>
  )
}

const SubscriptionSettings = ({ closeModalHandler }) => {
  const user = useSelector((state) => state.user.data)
  const dispatch = useDispatch()
  const [isBusy, setIsBusy] = useState(false)
  const [subscription, setSubscription] = useState(
    user.subscriptionTier.toString() || ''
  )

  const modalState = useDisclosure()
  const [modalType, setModalType] = useState(null)

  const openModalHandler = (type) => {
    setModalType(type)
    modalState.onOpen()
  }

  const handleUpdateSubscription = async () => {
    try {
      setIsBusy(true)
      await dispatch(
        putSubscription(user.id, { subscriptionTier: parseInt(subscription) })
      )
      closeModalHandler()
    } catch (error) {
      setIsBusy(false)
      alert(error)
    }
  }

  const subscriptionOptions = [
    {
      value: subscriptionType.MONTHLY.value,
      title: subscriptionType.MONTHLY.title,
      price: subscriptionType.MONTHLY.price,
      description:
        'Looking for more than the basic features? Get assigned a Career Coach to help convert your Moments into Stories and get access to purchase premium add-on services with a monthly subscription. Ideal for active job seekers or those looking to tell a compelling Career Story.',
    },
    {
      value: subscriptionType.ANNUAL.value,
      title: subscriptionType.ANNUAL.title,
      price: subscriptionType.ANNUAL.price,
      description:
        'Looking for more than the basic features and want to save money? Get all the benefits of the monthly subscription with the savings of an annual subscription. Ideal for active job seekers with many years of experience or for those looking for ongoing access to a Career Coach.',
    },
  ]

  return (
    <>
      <ModalHeader>Modify Subscription</ModalHeader>
      <ModalCloseButton />
      <ModalBody pb="8">
        <FormControl id="subscription">
          <FormLabel>Select a Subscription</FormLabel>
          <SubscriptionRadioList
            value={subscription}
            handleChange={setSubscription}
            options={subscriptionOptions}
          />
        </FormControl>
        <Box textAlign="center" mt="8">
          {/* TODO: Implement Stripe Input */}
          <Button
            colorScheme="orange"
            isLoading={isBusy}
            loadingText="Updating..."
            isDisabled={parseInt(subscription) === user.subscriptionTier}
            onClick={handleUpdateSubscription}
          >
            Update Subscription
          </Button>
        </Box>
        {user.subscriptionId && ( // "user.subscriptionId" used here because "trial" users could technically still have an active stripe subscription
          <Box textAlign="center" mt="8">
            <Button
              colorScheme="red"
              variant="ghost"
              onClick={() =>
                openModalHandler(
                  <ConfirmCancelModal
                    doubleCloseModal={closeModalHandler}
                    closeModalHandler={modalState.onClose}
                  />
                )
              }
            >
              Cancel Subscription
            </Button>
          </Box>
        )}
      </ModalBody>
      <Modal isOpen={modalState.isOpen} onClose={modalState.onClose}>
        <ModalOverlay />
        <ModalContent>{modalType}</ModalContent>
      </Modal>
    </>
  )
}

export default UserAccountSettings
