import React, { useState } from 'react'
import {
  Box,
  Button,
  Flex,
  Text,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  FormLabel,
  FormControl,
  Stack,
  Collapse,
  RadioGroup,
  Radio,
  Textarea,
  ButtonGroup,
  Tag,
} from '@chakra-ui/react'

import { useDisclosure } from '@chakra-ui/hooks'
import { useDispatch, useSelector } from 'react-redux'
import { updateCareerStory } from '../../api/careerStories'
import { createComment } from '../../api/comments'
import { commentObjectType, csStatusType, userType } from '../../lib/types'
import CommentThread from '../common/CommentThread'
import { formatDate } from '../../lib/utils'

const CareerStoryModal = ({ careerStoryId, closeModalHandler }) => {
  const dispatch = useDispatch()
  const user = useSelector((state) => state.user.data)
  const revisionCollapse = useDisclosure()
  const [reason, setReason] = useState(null)
  const [reasonDetails, setReasonDetails] = useState('')
  const [isLoadingComment, setIsLoadingComment] = useState(false)
  const [isLoadingStory, setIsLoadingStory] = useState(false)

  const reasonOptions = [
    'Work information is inaccurate',
    'This doesn’t feel appropriate for my resume',
    'Something Else',
  ]

  const handleApproveStory = async () => {
    try {
      revisionCollapse.onClose()
      setIsLoadingStory(true)
      await dispatch(
        updateCareerStory(careerStory.id, {
          ...careerStory,
          status: csStatusType.APPROVED,
        })
      )
      setIsLoadingStory(false)
      alert('Success')
      closeModalHandler()
    } catch (error) {
      setIsLoadingStory(false)
      alert(error)
    }
  }

  const careerStory =
    useSelector(
      (state) =>
        state.careerStories &&
        state.careerStories.data &&
        Object.values(state.careerStories.data).find(
          (cs) => cs.id === careerStoryId
        )
    ) || 0

  const submitCommentHandler = async (e) => {
    e.preventDefault()
    try {
      setIsLoadingComment(true)
      await dispatch(
        createComment({
          content: `${reason} : ${reasonDetails}`,
          type: 'revision',
          objectType: commentObjectType.CAREER_STORY,
          objectId: careerStory.id,
        })
      )
      setIsLoadingComment(false)
      setReasonDetails('')
      alert('Success')
      revisionCollapse.onClose()
    } catch (error) {
      setIsLoadingComment(false)
      alert(error)
    }
  }

  return (
    <>
      <ModalHeader>
        <Flex align="center">
          <Text as="span">Career Story</Text>
          {careerStory.status === csStatusType.APPROVED && (
            <Tag ml="4" colorScheme="green">
              Approved
            </Tag>
          )}
        </Flex>
      </ModalHeader>
      <ModalCloseButton />
      <ModalBody py="4">
        <Box>
          <Flex textAlign="right" justify="flex-end" mb="1">
            <Text as="span" fontSize="sm">
              <Text as="span" fontWeight="semibold">
                Last Updated:{' '}
              </Text>
              {formatDate(careerStory.updatedAt, 'MMM. D, h:mma')}
            </Text>
          </Flex>
          <Textarea
            value={careerStory.content}
            resize="none"
            isReadOnly={true}
            bg="amberGray.100"
            borderColor="amberGray.300"
            mb="2"
          />
          {careerStory.status !== csStatusType.APPROVED &&
            user.type === userType.USER && (
              <Flex justify="flex-end">
                <ButtonGroup spacing="3">
                  <Button
                    disabled={isLoadingStory || revisionCollapse.isOpen}
                    variant="ghost"
                    colorScheme="blue"
                    onClick={revisionCollapse.onOpen}
                  >
                    Needs Revision
                  </Button>
                  <Button
                    disabled={isLoadingStory || revisionCollapse.isOpen}
                    colorScheme="green"
                    onClick={handleApproveStory}
                    isLoading={isLoadingStory}
                  >
                    Approve Story
                  </Button>
                </ButtonGroup>
              </Flex>
            )}
        </Box>
        {careerStory.comments && careerStory.comments.length > 0 && (
          <Box>
            <Box mb="2">
              <Text fontWeight="semibold" fontSize="xl">
                Feedback
              </Text>
            </Box>
            <Box p="2" rounded="md" borderWidth="1px">
              <CommentThread comments={careerStory.comments} />
            </Box>
          </Box>
        )}
        <Collapse in={revisionCollapse.isOpen} animateOpacity>
          <Box mt="6" pt="6" px="2" borderTopWidth="1px">
            <form onSubmit={submitCommentHandler}>
              <FormControl as="fieldset" mb="4">
                <FormLabel as="legend">Reason for Revision</FormLabel>
                <RadioGroup value={reason} onChange={setReason}>
                  <Stack direction="column">
                    {reasonOptions.map((option, idx) => (
                      <Radio
                        isRequired
                        key={idx}
                        id={`reason-${idx}`}
                        value={option}
                      >
                        {option}
                      </Radio>
                    ))}
                  </Stack>
                </RadioGroup>
              </FormControl>
              <FormControl mb="2">
                <FormLabel>Please provide Details</FormLabel>
                <Textarea
                  isRequired
                  value={reasonDetails}
                  onChange={(e) => setReasonDetails(e.target.value)}
                />
              </FormControl>
              <Flex>
                <ButtonGroup spacing="3" ml="auto">
                  <Button
                    variant="ghost"
                    colorScheme="blue"
                    onClick={revisionCollapse.onClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    colorScheme="blue"
                    isLoading={isLoadingComment}
                    type="submit"
                  >
                    Submit
                  </Button>
                </ButtonGroup>
              </Flex>
            </form>
          </Box>
        </Collapse>
      </ModalBody>
    </>
  )
}

export default CareerStoryModal
