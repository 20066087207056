import { jlStatusType } from '../lib/types'
import {
  setupRequest,
  fetchJSON,
  errorHandler,
  validURL,
  appendParamsToUrl,
} from '../lib/utils'
import {
  loadJobsListings,
  loadJobsListing,
  postJobListing,
  putJobsListing,
} from '../redux/jobsListings'

function fetchJobsListings(params = null) {
  return async (dispatch, getState) => {
    let state = getState()
    try {
      const path = params
        ? '/jobs-listings?' + appendParamsToUrl(params)
        : '/jobs-listings'
      const { url, options } = setupRequest(path, 'GET', state)
      let json = await fetchJSON(url, options)
      if (json.description) throw Error(json.description)
      if (json.error) throw Error(json.error)
      if (!json.jobsListings) throw Error('API_ERROR_FETCH_JOBS_LISTINGS')
      dispatch(loadJobsListings(json.jobsListings))
    } catch (error) {
      errorHandler(error)
      throw error
    }
  }
}

function fetchJobsListing(id) {
  return async (dispatch, getState) => {
    let state = getState()
    try {
      const { url, options } = setupRequest(
        `/jobs-listings/${id}`,
        'GET',
        state
      )
      let json = await fetchJSON(url, options)
      if (json.description) throw Error(json.description)
      if (json.error) throw Error(json.error)
      if (!json.jobsListing) throw Error('API_ERROR_FETCH_JOBS_LISTING')
      dispatch(loadJobsListing(json.jobsListing))
    } catch (error) {
      errorHandler(error)
      throw error
    }
  }
}

function createJobsListing(data) {
  return async (dispatch, getState) => {
    let state = getState()
    try {
      data.status = jlStatusType.ACTIVATED
      validateJobsListing(data, true)
      const { url, options } = setupRequest(`/jobs-listings`, 'POST', state)
      options.body = JSON.stringify(data)
      let json = await fetchJSON(url, options)
      if (json.description) throw Error(json.description)
      if (json.error) throw Error(json.error)
      if (!json.jobsListing) throw Error('API_ERROR_CREATE_JOBS_LISTING')
      dispatch(postJobListing(json.jobsListing))
    } catch (error) {
      errorHandler(error)
      throw error
    }
  }
}

function updateJobsListing(id, data) {
  return async (dispatch, getState) => {
    let state = getState()
    try {
      validateJobsListing({ ...data, id })
      const { url, options } = setupRequest(
        `/jobs-listings/${id}`,
        'PUT',
        state
      )
      options.body = JSON.stringify(data)
      let json = await fetchJSON(url, options)
      if (json.description) throw Error(json.description)
      if (json.error) throw Error(json.error)
      if (!json.jobsListing) throw Error('API_ERROR_UPDATE_JOBS_LISTING')
      dispatch(putJobsListing(json.jobsListing))
    } catch (error) {
      errorHandler(error)
      throw error
    }
  }
}

function validateJobsListing(
  { id, userId, title, companyName, link, status },
  created = false
) {
  if (!id && !created) throw Error('NO_ID_PROVIDED')
  if (!userId) throw Error('NO_USER_ID_PROVIDED')
  if (!title) throw Error('NO_TITLE_PROVIDED')
  if (!companyName) throw Error('NO_COMPANY_NAME_PROVIDED')
  if (!link) throw Error('NO_LINK_PROVIDED')
  if (!validURL(link)) throw Error('LINK_PROVIDED_NO_VALID')
  if (!status) throw Error('NO_STATUS_PROVIDED')
  if (!Object.values(jlStatusType).includes(status))
    throw Error('WRONG_STATUS_FORMAT')
  if (created && status !== jlStatusType.ACTIVATED)
    throw Error('STATUS_SHOULD_BE_ACTIVATED')
}

export {
  fetchJobsListings,
  fetchJobsListing,
  createJobsListing,
  updateJobsListing,
}
