import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Button,
  Box,
  Grid,
  GridItem,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  VStack,
  Text,
  useCheckboxGroup,
  Square,
  Skeleton,
  SkeletonText,
} from '@chakra-ui/react'
import Container from '../components/common/Container'
import { useDispatch, useSelector } from 'react-redux'
import { putRegistration } from '../redux/registration'
import { onboardingQuestions, subscriptionType } from '../lib/types'
import { fetchStoreItems } from '../api/storeItems'
import { formatPrice } from '../lib/utils'
import CompoundCheckbox from '../components/common/CompoundCheckbox'
import { default as SubscriptionOptions } from '../components/SubscriptionRadioList'
import { completeRegistration } from '../api/auth'

export default function OnboardingStepTwo() {
  const registration = useSelector((state) => state.registration.data)
  const user = useSelector((state) => state.user.data)
  const dispatch = useDispatch()
  const history = useHistory()
  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleSubmit = async (e) => {
    try {
      e.preventDefault()
      if (parseInt(registration.subscription) === subscriptionType.FREE.value) {
        setIsSubmitting(true)
        await dispatch(
          completeRegistration(user.id, {
            registrationAnswer1: registration.primaryReason
              ? onboardingQuestions.REASONS[registration.primaryReason]
              : '',
            registrationAnswer2: registration.secondaryReason
              ? onboardingQuestions.REASONS[registration.secondaryReason]
              : '',
            subscriptionTier: parseInt(registration.subscription),
          })
        )
        history.push('/dashboard?skip=true')
      } else {
        history.push('/onboarding/confirm')
      }
    } catch (error) {
      setIsSubmitting(false)
      alert(error)
    }
  }

  const subscriptions = [
    {
      value: subscriptionType.FREE.value,
      title: subscriptionType.FREE.title,
      price: subscriptionType.FREE.price,
      description:
        'Get started with a basic subscription and start documenting your Career Moments today. Ideal for individuals just looking for a place to track their successes without the help or services of a Career Coach.',
    },
    {
      value: subscriptionType.MONTHLY.value,
      title: subscriptionType.MONTHLY.title,
      price: subscriptionType.MONTHLY.price,
      description:
        'Looking for more than the basic features? Get assigned a Career Coach to help convert your Moments into Stories and get access to purchase premium add-on services with a monthly subscription. Ideal for active job seekers or those looking to tell a compelling Career Story.',
    },
    {
      value: subscriptionType.ANNUAL.value,
      title: subscriptionType.ANNUAL.title,
      price: subscriptionType.ANNUAL.price,
      description:
        'Looking for more than the basic features and want to save money? Get all the benefits of the monthly subscription with the savings of an annual subscription. Ideal for active job seekers with many years of experience or for those looking for ongoing access to a Career Coach.',
    },
  ]

  const recommended = useMemo(() => {
    const recommendations = [
      { tier: '2', products: [] },
      { tier: '3', products: ['10', '4', '7'] },
      { tier: '3', products: ['1'] },
      { tier: '3', products: ['10', '8'] },
      { tier: '3', products: ['1'] },
      { tier: '3', products: ['12'] },
      { tier: '3', products: ['1', '10', '4', '7'] },
      { tier: '2', products: ['7', '8'] },
    ]
    return recommendations[registration.primaryReason]
  }, [registration.primaryReason])

  const handleChange = useCallback(
    async (value) => {
      await dispatch(
        putRegistration({
          subscription: value,
        })
      )
    },
    [dispatch]
  )

  useEffect(() => {
    handleChange(recommended.tier)
  }, [handleChange, recommended.tier])

  return (
    <Container py="20">
      <Grid templateColumns={{ lg: 'repeat(12, 1fr)' }}>
        <GridItem colStart={{ lg: '4' }} colSpan={{ lg: '6' }}>
          <Box boxShadow="base" p="6" bg="white" w="100%" rounded="md">
            <Box mb="4">
              <Heading as="h1" fontSize="3xl" mb="8">
                Recommended Options
              </Heading>
            </Box>
            <form onSubmit={handleSubmit}>
              <Grid gap="8">
                <GridItem>
                  <FormControl id="subscription">
                    <FormLabel>Subscription Level</FormLabel>
                    <SubscriptionOptions
                      defaultValue={recommended.tier}
                      options={subscriptions}
                      handleChange={handleChange}
                    />
                  </FormControl>
                </GridItem>
                {recommended.products.length > 0 && (
                  <GridItem>
                    <FormControl id="addons">
                      <FormLabel>Suggested Add-Ons</FormLabel>
                      <AddonOptions
                        recommendedProducts={recommended.products}
                      />
                    </FormControl>
                  </GridItem>
                )}
                <GridItem>
                  <Flex align="center" mt="4">
                    <Button
                      colorScheme="orange"
                      isLoading={isSubmitting}
                      loadingText="Completing Registration..."
                      isFullWidth
                      type="submit"
                    >
                      {registration.subscription === subscriptionType.FREE.value
                        ? 'Complete Registration'
                        : 'Continue'}
                    </Button>
                  </Flex>
                </GridItem>
              </Grid>
            </form>
          </Box>
        </GridItem>
      </Grid>
    </Container>
  )
}

const AddonOptions = ({ recommendedProducts }) => {
  const dispatch = useDispatch()
  const registration = useSelector((state) => state.registration.data)
  const storeItems = useSelector((state) => state.storeItems.data)
  const [isLoading, setIsLoading] = useState(false)

  const handleChange = useCallback(
    async (value) => {
      await dispatch(
        putRegistration({
          addons: value,
        })
      )
    },
    [dispatch]
  )
  useEffect(() => {
    handleChange(recommendedProducts)
  }, [handleChange, recommendedProducts])

  const { getCheckboxProps } = useCheckboxGroup({
    name: 'addons',
    onChange: handleChange,
    defaultValue: recommendedProducts,
  })

  const getStoreItems = useCallback(async () => {
    try {
      setIsLoading(true)
      await dispatch(fetchStoreItems())
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      alert(error)
    }
  }, [dispatch])

  useEffect(() => {
    getStoreItems()
  }, [getStoreItems])

  const isSelected = (checkbox) => {
    return checkbox.isChecked && !checkbox.isDisabled
  }

  const listGroupStyles = {
    p: '4',
    borderWidth: '1px',
    mt: '-1px',
    _first: {
      borderTopRadius: 'md',
    },
    _last: {
      borderBottomRadius: 'md',
    },
  }

  return (
    <VStack alignItems="start" spacing="-1px">
      {!isLoading && storeItems && Object.keys(storeItems).length > 0 ? (
        Object.values(storeItems)
          .filter((si) => recommendedProducts.includes(si.id.toString()))
          .map((si, idx) => {
            const checkbox = getCheckboxProps({
              value: si.id.toString(),
              isDisabled:
                parseInt(registration.subscription) ===
                subscriptionType.FREE.value,
            })
            return (
              <CompoundCheckbox
                {...checkbox}
                key={idx}
                sx={{
                  ...listGroupStyles,
                  _checked: {
                    zIndex: '1',
                    bg: 'amberGray.100',
                    borderColor: 'orange.600',
                  },
                  _disabled: {
                    bg: 'amberGray.50',
                    borderColor: 'amberGray.200',
                    color: 'warmGray.500',
                    cursor: 'not-allowed',
                  },
                }}
              >
                <Flex mb="1" justify="space-between">
                  <Flex align="center">
                    <Square
                      boxSize="4"
                      rounded="4px"
                      borderWidth="2px"
                      borderColor={isSelected(checkbox) && 'orange.600'}
                      bg={isSelected(checkbox) && 'orange.600'}
                      color="white"
                    >
                      {isSelected(checkbox) && (
                        <svg
                          viewBox="0 0 12 10"
                          style={{
                            fill: 'none',
                            strokeWidth: 2,
                            stroke: 'currentcolor',
                            strokeDasharray: 16,
                            opacity: 1,
                            strokeDashoffset: 0,
                          }}
                        >
                          <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                        </svg>
                      )}
                    </Square>
                    <Text
                      as="span"
                      ml="2"
                      fontWeight="bold"
                      fontSize="lg"
                      color={isSelected(checkbox) && 'orange.600'}
                    >
                      {si.name}
                    </Text>
                  </Flex>
                  <Text>{formatPrice(si.price)}</Text>
                </Flex>
                <Text>{si.description}</Text>
              </CompoundCheckbox>
            )
          })
      ) : (
        <>
          {[...Array(3)].map((_, idx) => (
            <Box w="100%" key={idx} sx={{ ...listGroupStyles }}>
              <Skeleton w="72" height="5" />
              <SkeletonText mt="4" noOfLines={2} spacing="4" />
            </Box>
          ))}
        </>
      )}
    </VStack>
  )
}
