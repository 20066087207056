const userType = Object.freeze({
  USER: 'USER',
  ADMIN: 'ADMIN',
  SUPER: 'SUPER',
})

const tokenType = Object.freeze({
  PASSWORD: 'PASSWORD',
  INVITATION: 'INVITATION',
})

const jlStatusType = Object.freeze({
  ACTIVATED: 'activated',
  ARCHIVED: 'archived',
  DELETED: 'deleted',
})
const mStatusType = Object.freeze({
  ACTIVATED: 'activated',
  ARCHIVED: 'archived',
  DELETED: 'deleted',
})

const commentObjectType = Object.freeze({
  CAREER_STORY: 'CAREER_STORY',
})

const csStatusType = Object.freeze({
  DRAFT: 'draft',
  CREATED: 'created',
  APPROVED: 'approved',
})

const csComputedStatusType = Object.freeze({
  APPROVED: 0,
  PENDING: 1,
  NEEDS_REVISION: 2,
  DRAFT: 3,
})

const cmStatusType = Object.freeze({
  DRAFT: 'DRAFT',
  PUBLISHED: 'PUBLISHED',
})

const cmComputedStatusType = Object.freeze({
  ALL_APPROVED: 0,
  PENDING: 1,
  NO_STORIES: 2,
  DRAFT: 3,
})

const settingsType = Object.freeze({
  CONTEXTS: { title: 'Moment Challenge', value: 'Context' },
  ACTIONS: { title: 'Moment Actions', value: 'Action' },
  RESULTS: {
    title: 'Moment Results',
    value: 'Result',
    ACCOMPLISHMENTS: { title: 'Accomplishments', value: 'Accomplishment' },
    METRICS: { title: 'Metrics', value: 'Metric' },
  },
  STORY: {
    title: 'Story Attributes',
    value: 'Story Attributes',
    SKILL: { title: 'Skill', value: 'Skill' },
    TYPE: { title: 'Type', value: 'Type' },
  },
})

const subscriptionType = Object.freeze({
  FREE: {
    title: 'Basic',
    price: 0,
    value: 1,
  },
  MONTHLY: {
    title: 'Monthly',
    price: 2200,
    value: 2,
    stripeId: process.env.REACT_APP_STRIPE_SUBSCRIPTION_MONTHLY,
  },
  ANNUAL: {
    title: 'Annual',
    price: 22000,
    value: 3,
    stripeId: process.env.REACT_APP_STRIPE_SUBSCRIPTION_ANNUAL,
  },
})

const onboardingQuestions = Object.freeze({
  YEARS: ['< 3 years', '4-7 years', '8-14 years', '15+ years'],
  REASONS: [
    'I’m just starting out and looking for guidance',
    'I’m an active job-seeker',
    'I’m a passive job-seeker',
    'I’m seeking an internal promotion',
    'I’m looking for a place to track my accomplishments',
    'I want to be better prepared for a performance review',
    'I’m looking to make a major career change',
    'I’m an upcoming or recent graduate',
  ],
})

export {
  userType,
  tokenType,
  jlStatusType,
  mStatusType,
  commentObjectType,
  cmStatusType,
  cmComputedStatusType,
  csStatusType,
  csComputedStatusType,
  settingsType,
  subscriptionType,
  onboardingQuestions,
}
