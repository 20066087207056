import { createStore, applyMiddleware, compose, combineReducers } from 'redux'
import thunk from 'redux-thunk'
import * as Sentry from '@sentry/react'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
import user from './user'
import token from './token'
import careerMoments from './careerMoments'
import jobsListings from './jobsListings'
import careerStories from './careerStories'
import careerMaterials from './careerMaterials'
import users from './admin/users'
import momentAttributes from './admin/momentAttributes'
import registration from './registration'
import storeItems from './storeItems'
import payments from './payments'

const middleware = [thunk]
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const reducers = combineReducers({
  user,
  token,
  careerMoments,
  jobsListings,
  careerStories,
  users,
  momentAttributes,
  careerMaterials,
  registration,
  storeItems,
  payments,
})

const persistConfig = { key: 'root', storage, stateReconciler: autoMergeLevel2 }
const persistedReducer = persistReducer(persistConfig, reducers)

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  configureScopeWithState: (scope, state) => {
    if (state.user.data) scope.setUser(state.user.data)
  },
})
const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(...middleware), sentryReduxEnhancer)
)
const persistor = persistStore(store)
export { store, persistor }
